import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useRef, useState, useEffect } from "react";
import FilterStore from "./FilterStore";
import { getState, AustraliaId, googleMapCountry } from "../../api";
import axios from "axios";
import MyLocationIcon from "@mui/icons-material/MyLocation";
// Import the image or make sure it's correctly defined
import googlemaps from "../../assets/images/google-maps-svg.png";
import AutocompleteWrapper from "./AutocompleteWrapper";
import "../../assets/css/custom.css";
import { toast } from "react-toastify";
import mongoSanitize from 'mongo-sanitize';
const top100Films = [];
for (let i = 20; i <= 200; i += 20) {
  top100Films.push({ label: i.toString() });
}

export default function TopFilter(props) {
  const {
    setField,
    state,
    city,
    maxDistance,
    predictions1,
    resetFields,
    placeId,
    searchFlag,
    clpredictions,
    cl,
  } = FilterStore();

  const [clear, setClear] = useState(false);

  const handleCityChange = async (event) => {
    const input = event.target.value;
    console.log("check input", input);

    if (input !== "") {
      setField("cl", false);

      try {
        // Create an AutocompleteService
        const autocompleteService =
          new window.google.maps.places.AutocompleteService();
        const request = {
          input: input,
          types: ["address"],
          componentRestrictions: { country: googleMapCountry }, // Restrict to Australia
        };

        // Get place predictions for the input
        autocompleteService.getPlacePredictions(
          request,
          async (predictions, status) => {
            if (
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              predictions &&
              predictions.length > 0
            ) {
              const pd = [
                {
                  description: "Current Location",
                  place_id: "Current",
                },
                ...predictions,
              ];
              setField("predictions1", pd);
            }
          }
        );
      } catch (error) {
        console.error("Error retrieving place details:", error);
      }
    }
  };

  const handlePlaceSelect = async (place) => {
    try {
      console.log("check place", place);
      if (place?.place_id === "Current") {
        setField("cl", true);
        getLocation();
      } else {
        // Ensure that the Google Maps API has been loaded before attempting to use it
        if (
          !window.google ||
          !window.google.maps ||
          !window.google.maps.places
        ) {
          console.error("Google Maps API not available");
          return null;
        }

        const placesService = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );

        const request = {
          placeId: place.place_id,
          fields: ["geometry"],
        };

        setField("placeId", place.place_id);

        const placeDetails = await new Promise((resolve, reject) => {
          placesService.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              resolve(place);
            } else {
              reject(status);
            }
          });
        });

        if (
          placeDetails &&
          placeDetails.geometry &&
          placeDetails.geometry.location
        ) {
          // Ensure that the location is a LatLng object before extracting latitude and longitude
          if (
            placeDetails.geometry.location instanceof window.google.maps.LatLng
          ) {
            const lat = placeDetails.geometry.location.lat();
            const lng = placeDetails.geometry.location.lng();
            setField("city", {
              latitude: lat,
              longitude: lng,
              description: place?.description,
              cityFormat: place?.structured_formatting?.main_text,
            });
            return { lat, lng };
          } else {
            console.error("Error: Invalid location format");
            return null;
          }
        } else {
          console.error(
            "Error: Unable to retrieve location details for place ID",
            placeId
          );
          return null;
        }
      }
    } catch (error) {
      console.error("Error fetching place details:", error);
      return null;
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("positionVk", position);
          fetchLocationDetails(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          toast.error(error.message);
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser");
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const fetchLocationDetails = (lat, lng) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    fetch(mongoSanitize(apiUrl))
      .then((response) => response.json())
      .then((data) => {
        console.log("dataMyVal", data);
        if (data.results && data.results.length > 0) {
          const firstResult = data.results[0];
          const address = firstResult.formatted_address;
          const addressParts = address.split(",");
          const id = firstResult.place_id;
          console.log("firstResultMyVal", firstResult);
          setField("placeId", id);
          setField("clpredictions", [{ description: address, place_id: id }]);
          setField("city", {
            latitude: lat,
            longitude: lng,
            description: address,
            // cityFormat: "",
            cityFormat: addressParts[addressParts.length - 3].trim()
              ? addressParts[addressParts.length - 3].trim()
              : "",
          });
        } else {
          console.log("Location data not found.");
        }
      })
      .catch((error) => {
        console.log(`Error fetching location: ${error.message}`);
      });
  };

  const handleKMChange = (event, newValue) => {
    if (newValue === null) {
      setField("maxDistance", "");
    }
    setField("maxDistance", newValue?.label);
  };

  const clearFilters = () => {
    resetFields();
    setField("searchFlag", false);

    // Reset all filter fields to their initial state
    setField("city", "");
    setField("maxDistance", "");
    setField("state", null);
    setField("predictions1", []);
    setField("isCleared", true);
    setClear(true);
    props?.getAllFilterDataClear();
    // document.getElementById("outlined-required").value = "";
    // document.getElementById("combo-box-demo").value = "";
    const requiredInput = document.getElementById("outlined-required");
    if (requiredInput) {
      requiredInput.value = "";
    } else {
      console.error("Element with ID 'outlined-required' not found.");
    }

    const comboBox = document.getElementById("combo-box-demo");
    if (comboBox) {
      comboBox.value = "";
    } else {
      console.error("Element with ID 'combo-box-demo' not found.");
    }
  };

  const handleSearch = () => {
    setField("searchFlag", true);
    props?.getAllFilterData();
  };
  console.log("check clear", clear);

  return (
    <Grid
      container
      spacing={2}
      // className="add-appointment-input-box"
      style={{ paddingLeft: "0px" }}
      sx={{ mt: 2 }}
    >
      <Grid item xs={12} sm={4} md={5} className="pt-0" sx={{ mt: -0.35 }}>
        <label className="client-appt-check">
          Search for Area/City/State/Postal Code
        </label>

        <AutocompleteWrapper
          cl={cl}
          clpredictions={clpredictions}
          predictions1={predictions1}
          placeId={placeId}
          handlePlaceSelect={handlePlaceSelect}
          handleCityChange={handleCityChange}
          clear={clear}
          setClear={setClear}
        />
      </Grid>

      <Grid item xs={12} sm={2} md={2} sx={{ mt: -0.35 }} className="web-km">
        <label>KM</label>
        <Autocomplete
          className="web-margin"
          name="maxDistance"
          disablePortal
          id="maxDistance-input"
          options={top100Films}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.label
          }
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "KM",
              }}
            />
          )}
          size="small"
          onChange={handleKMChange}
          value={
            maxDistance !== undefined &&
            maxDistance !== null &&
            maxDistance !== "undefined"
              ? maxDistance
              : ""
          }
        />
      </Grid>

      <Grid item xs={4} sm={4} md={4} sx={{ mt: -2.25, marginLeft: "-5px" }}>
        <Box className="agency-btn-section add-expenses-btn">
          <Box className="d-flex timesheetfilter-button align-items-center">
            <button className="timesheet-filter-show" onClick={handleSearch}>
              Search
            </button>
            <button className="timesheet-filter-clear" onClick={clearFilters}>
              Clear
            </button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
