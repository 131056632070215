import React, { useState, useEffect } from "react";
import styles from "./ComingSoon.module.css";

function CommingSoon() {
  const launchDate = new Date("2024-03-07T00:00:00").getTime();

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = launchDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);

      if (distance < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles["coming-soon-container"]}>
      <div className={styles["coming-soon-content"]}>
        <h1 className={styles.heading}>Hubshift</h1>
        <p className={styles["sub-heading"]}>
          We're launching something amazing!
        </p>
        <p className={styles["coming-soon-line"]}>Coming Soon</p>{" "}
        {/* Added line */}
        <div className={styles.countdown}>
          <div className={styles["countdown-item"]}>
            <span id="days">{days}</span>
            <p>Days</p>
          </div>
          <div className={styles["countdown-item"]}>
            <span id="hours">{hours}</span>
            <p>Hours</p>
          </div>
          <div className={styles["countdown-item"]}>
            <span id="minutes">{minutes}</span>
            <p>Minutes</p>
          </div>
          <div className={styles["countdown-item"]}>
            <span id="seconds">{seconds}</span>
            <p>Seconds</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommingSoon;
