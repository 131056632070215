import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Radio, Tooltip } from "@mui/material";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import {
    Button,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import AttachmentIcon from "@mui/icons-material/Attachment";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/Appointment.css";
import "../Timesheet/timesheet.css";
import Dropdown from "react-bootstrap/Dropdown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MapIcon from "@mui/icons-material/Map";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import upload from "../../../assets/images/upload.svg";
import camera from "../../../assets/images/camera.svg";
import Form from "@mui/material/FormGroup";
import closeIcon from '../../../assets/images/cancel.svg'
import Popover from "@mui/material/Popover";
import GetAppIcon from "@mui/icons-material/GetApp";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import importicon from "../../../assets/images/exporticon.svg";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";
import Arrowfilter from '../../../assets/images/arrow-filter.png';
import Checkbox from '@mui/material/Checkbox';
// modal import
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormGroup from '@mui/material/FormGroup';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import editIcon from '../../../assets/images/edit-basepay.png'
import ReactPaginate from "react-paginate";
import DateField from "./DateField";
// import DatePickerComponent from '../CalenderFilter/DatePicker';
import TimesheetExpenses from "../Timesheet/Timesheet-Expenses";
import alertImg from '../../../assets/images/alert-icon.png'
import Chip from '@mui/material/Chip';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import pdfIcon from '../../../assets/images/pdf.png'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const category = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },]

export default function PrnChart() {
    const [toggle, setToggle] = useState(false);

    const [toggleCell, setToggleCell] = useState(false);

    const [issue, setIssue] = useState(false);

    const [isArrow, setIsArrow] = useState(false);

    const [value, setValue] = React.useState(0);
    const [list, setList] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null)
    const openPop = Boolean(anchorEl);
    const id = openPop ? "simple-popover" : undefined;
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openBlank, setOpenBlank] = React.useState(false);

    const handleBlankOpen = () => {
        setOpenBlank(true);
    };

    const handleBlankClose = () => {
        setOpenBlank(false);
    };

    const [showhide, setShowhide] = React.useState("");

    const handleshowhide = (event) => {
        const getuser = event.target.value;
        setShowhide(getuser);
    };

    // attach file modal

    const [openAttach, setOpenAttach] = React.useState(false);
    const [isShown, setIsShown] = useState(false);

    const handleClickOpenAttach = () => {
        setOpenAttach(true);
    };

    const handleCloseAttach = () => {
        setOpenAttach(false);
    };

    // client pop-up tab

    const [isActive2, setIsActive] = useState(false);

    const handleClick2 = (event) => {
        setIsActive((current) => !current);
    };

    const breadcrumbs = [
        <Link
            underline="hover"
            key="1"
            color="inherit"
            href="/"
            onClick={handleClick}
        >
            <Typography>Timesheets</Typography>
        </Link>,
        // <Link
        //     underline="hover"
        //     key="2"
        //     color="inherit"
        //     href="/material-ui/getting-started/installation/"
        //     onClick={handleClick}
        // >
        //     <Typography>Evaluation</Typography>
        // </Link>,
    ];

    const showClick1 = (event) => {
        setIsShown((current) => !current);
    };

    // Table functionality

    function handleClick(event) {
        event.preventDefault();
        console.info("You clicked a breadcrumb.");
    }

    function createData(
        name,
        calories,
        fat,
        carbs,
        category,
        onboarding,
        risk,
        schedule,
        meeting,
        status,
        monitoring,
        Prescribed,
        protein
    ) {
        return {
            name,
            calories,
            fat,
            carbs,
            category,
            onboarding,
            risk,
            schedule,
            meeting,
            status,
            monitoring,
            Prescribed,
            protein,
        };
    }

    const workType = (
        <Box className="workType-text">
            <span>
                01 06-2023
            </span>
        </Box>
    );

    const appointTime = (
        <Box className="table-time">
            <span>
                01 01 2023
            </span>
        </Box>
    );

    const Expenses = <TimesheetExpenses />;

    const Finishedtime = (
        <input
            class="no-timer-icon"
            type="time"
            id="appt"
            name="appt"
            required
            value="00:00"
        />
    );

    const Frequency = <div>Once a day - <br />
        10:00</div>;

    const Concentration = <div>Lorem Ipsum</div>;

    const ExpensesAdd = (
        <Link className="expenses-add" to="/add-expenses">
            {" "}
            <AddCircleIcon /> Add Expenses
        </Link>
    );

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };

    const totalUnit = (
        // <Typography
        //   href="#javascript:;"
        //   className="total-unit d-flex align-items-center"
        // >
        //   <AccessTimeIcon fontSize="small" /> 7 hours
        // </Typography>
        <Stack
            // ml={1}
            direction="row"
            spacing={0}
            className="d-flex align-items-center justify-content-center"
        >
            <AccessTimeIcon fontSize="small" />
            <Typography className="total-unit">7 hours</Typography>
        </Stack>
    );

    // table state

    const [isEditingIndex, setIsEditingIndex] = useState(-1);
    const [rows, setRows] = useState([
        createData(
            appointTime,
            workType,
            "Valium",
            "Prn",
            "Anxiety",
            "Top Drawer in Bedroom ",
            "1",
            "Capsule",
            Frequency,
            Concentration,
            "Yes",
            "Dr. Martin John",
            Expenses
        ),
        createData(
            appointTime,
            workType,
            "Amoxicillin",
            "-",
            "-",
            "-",
            "1",
            "Tablet",
            Frequency,
            Concentration,
            "No",
            "Dr. Anna Thomas",
            ExpensesAdd
        ),
        createData(
            appointTime,
            workType,
            "Calciferol",
            "-",
            "-",
            "-",
            "1",
            "Capsule",
            Frequency,
            Concentration,
            "Yes",
            "Dr. Anna Thomas",
            ExpensesAdd
        ),
    ]);

    const handleTimeClick = (index, field) => {
        const updatedRows = [...rows];
        updatedRows.forEach((row, i) => {
            row.isEditing = index === i;
            row.clickedField = field;
        });
        setRows(updatedRows);
    };



    const handleInputChange = (e, index) => {
        const { value } = e.target;
        const updatedRows = [...rows];
        const clickedField = updatedRows[index].clickedField;
        updatedRows[index][clickedField] = value;
        setRows(updatedRows);
    };

    const handleInputBlur = (index) => {
        const updatedRows = [...rows];
        updatedRows[index].isEditing = false;
        updatedRows[index].clickedField = null;
        setRows(updatedRows);
    };

    // Table functionality

    return (
        <Box className="main" id="timesheet">



            <Box className="w-100" id="add-medical-history">
                <Box
                    className="dashboard-form-typo dashboard-form-typo-up"
                    sx={{ borderBottom: "none" }}
                >
                    <Grid container className="d-flex header-topsection">
                        <Grid item xs={6} sm={6} md={6}>
                            <Box id="breadcrumb">
                                <ArrowBackIosIcon />
                                <Box className="breadcrumb-inr">
                                    <h4 className="d-flex align-items-center "> Medical History</h4>
                                    <Box className="breadcrumbs">
                                        <Link className="breadcrumbs-link"> Medication Management</Link>
                                        <span className="breadcrumbs-icon">{">"}</span>
                                        <Link className="breadcrumbs-link ">
                                            Medical History
                                        </Link>
                                        <span className="breadcrumbs-icon">{">"}</span>
                                        <Link className="breadcrumbs-link breadcrumbs-link-active">
                                            Prn Chart
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={6} className="padding-right-20" sm={6} md={6}>
                            <Box className="addTask-btn">
                                <Box className="d-flex align-items-center justify-content-end">


                                    <Button
                                        className="importexportbtn"
                                        variant="outlined"
                                        aria-describedby={id}
                                        onClick={handleClick}
                                        startIcon={
                                            <img
                                                src={importicon}
                                                className="importexporticon-image"
                                                alt="importicon"
                                            />
                                        }
                                    >
                                        Export
                                    </Button>

                                    {/* Export Pophover */}
                                    <Popover
                                        elevation={0}
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                    >
                                        {/* popover content */}
                                        <div>
                                            <Card>
                                                <Box className="exportdropdown-wrap">
                                                    <Box className="cardcontent_box dropdownimport">
                                                        <CardContent className="import_CardContent">
                                                            <div className="exportdata-btn">
                                                                <svg
                                                                    width="18"
                                                                    height="17"
                                                                    viewBox="0 0 18 17"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M1 11.5996V15.5996H17V11.5996"
                                                                        stroke="white"
                                                                        stroke-width="1.45"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M5.80078 8.56055L9.00078 10.9605"
                                                                        stroke="white"
                                                                        stroke-width="1.45"
                                                                        stroke-linecap="round"
                                                                    />
                                                                    <path
                                                                        d="M12.2812 8.40039L9.08125 11.041"
                                                                        stroke="white"
                                                                        stroke-width="1.45"
                                                                        stroke-linecap="round"
                                                                    />
                                                                    <line
                                                                        x1="8.91367"
                                                                        y1="10.8727"
                                                                        x2="8.91367"
                                                                        y2="0.725842"
                                                                        stroke="white"
                                                                        stroke-width="1.45"
                                                                        stroke-linecap="round"
                                                                    />
                                                                </svg>

                                                                <Typography
                                                                    component="div"
                                                                    className="importfiletext"
                                                                >
                                                                    Export Data
                                                                    <span>Download Data</span>
                                                                </Typography>
                                                            </div>
                                                        </CardContent>
                                                    </Box>

                                                    <Box>
                                                        <CardActions className="cardactionsty2 dropdownimportbutton">
                                                            <Link
                                                                data={list}
                                                                filename="Hundred-points.csv"
                                                            // headers={headers}
                                                            >
                                                                <Button
                                                                    className="filebtn"
                                                                    variant="outlined"
                                                                    component="label"
                                                                >
                                                                    <FileUploadIcon align="center" />
                                                                    Export
                                                                </Button>
                                                            </Link>
                                                        </CardActions>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </div>
                                    </Popover>

                                    <Link to="/add-medical-history" className="medical-add-button approve-button">
                                        <AddIcon />  Add
                                    </Link>

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className="divider">

                    </Box>

                    <Box className="agency-searchbar padding-left-20 padding-right-20 searchbox-margin">
                        <Grid
                            container

                            className="align-items-center justify-content-between"
                        >
                            <Grid item xs={6} md={5} sm={5} style={{ paddingLeft: "0px" }}>
                                <TextField
                                    className="search-appointment"
                                    type="search"
                                    size="small"
                                    placeholder="Search Timesheet"
                                    //name="email"
                                    fullWidth
                                    // value={searchTerm}
                                    // onChange={(e) => searchItems(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </Box>

                    <Box className="divider">

                    </Box>


                </Box>







                <Box
                    className="timesheet-table padding-left-20 padding-right-20"
                // sx={{ width: '100%', overflow: 'hidden' }}
                >
                    <TableContainer
                        component={Paper}
                        id="document-table"
                        className="padding-res-10"
                    >
                        <Table sx={{ minWidth: 700 }} aria-label="caption table">
                            <TableHead id="client-table-head">
                                <TableRow>
                                    <TableCell
                                        className="tableborder table-head-cell"
                                    // align="left"
                                    >

                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Start Date
                                    </TableCell>

                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Stop / <br /> Review Date

                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Medicine Name
                                        <img
                                            className="fliter-img"
                                            // onClick={showClick1}
                                            src={Arrowfilter}
                                        />

                                    </TableCell>

                                    <TableCell
                                        className="tableborder table-head-cell "
                                        align="left"
                                    >
                                        Type
                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Circumstance
                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Stored
                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell th-width"
                                        align="left"
                                    >
                                        Dose Form
                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell th-width"
                                        align="left"
                                    >
                                        Dosage
                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell "
                                        align="left"
                                    >
                                        Dose <br /> Frequency

                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Unit of Measurement <br /> & Concentration
                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Monitoring <br /> Required
                                    </TableCell>

                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Route of <br /> Administration

                                    </TableCell>

                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Prescribed By
                                    </TableCell>

                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody id="client-table-body">
                                {rows.map((row, index) => (
                                    <>
                                        <TableRow key={index}>

                                            <TableCell align="center">
                                                <span onClick={() => { setToggleCell(!toggleCell); setIsArrow(!isArrow) }} className="timesheet-cell-arrow" >
                                                    <ArrowDropDownIcon
                                                        className={isArrow ? "rotate-arrow" : ""}
                                                    />
                                                </span>
                                            </TableCell>

                                            <TableCell
                                                component="th"
                                                scope="row"
                                                className="text-start tableborder"
                                            >
                                                <a href="#" className="table-link">
                                                    {row.name}
                                                </a>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="tableborder work-type-td"
                                            >
                                                {" "}
                                                <a href="#" className="table-link main-dark-color">
                                                    {row.calories}
                                                </a>
                                            </TableCell>
                                            <TableCell align="left" className="tableborder">
                                                {" "}
                                                <a href="#" className="table-link">
                                                    {row.fat}
                                                </a>
                                            </TableCell>
                                            <TableCell align="left" className="tableborder">
                                                {" "}
                                                <a href="#" className="table-link table-cell-100 table-link">
                                                    {row.carbs}
                                                </a>
                                            </TableCell>
                                            <TableCell align="left" className="tableborder">
                                                {" "}
                                                <a href="#" className="table-link table-category-cell">
                                                    {row.category}

                                                </a>
                                            </TableCell>

                                            <TableCell align="left" className="tableborder">
                                                {" "}
                                                <a href="#" className="table-link">
                                                    {row.onboarding}
                                                </a>
                                            </TableCell>

                                            <TableCell align="left" className="tableborder">

                                                <a
                                                    className="table-link table-cell-100">
                                                    {row.schedule}
                                                </a>

                                            </TableCell>

                                            <TableCell align="left" className="tableborder">

                                                <a
                                                    className="table-link table-cell-100">
                                                    {row.risk}
                                                </a>

                                            </TableCell>

                                            <TableCell
                                                align="left"
                                                id="meetingCell"
                                                className="tableborder "
                                            >
                                                <div className="table-cell-100">
                                                    {row.meeting}
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="tableborder table-editcell "
                                            >
                                                {row.status}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="tableborder table-editcell "
                                            >
                                                {row.monitoring}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="tableborder table-editcell "
                                            >
                                                {row.status}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="tableborder table-editcell "
                                            >
                                                {row.Prescribed}
                                            </TableCell>

                                            <TableCell
                                                align="left"
                                                className="tableborder table-editcell"
                                            >
                                                <Box mr={1} className="table-edit-del">
                                                    <Box>
                                                        <a
                                                            href="javascript:;"
                                                            id="edit"
                                                            label="Domain"
                                                            className="table-edit justify-content-center  btn"
                                                            margin="normal"
                                                            onClick={handleClickOpenAttach}
                                                        >
                                                            <img src={editIcon} /> Edit
                                                        </a>
                                                    </Box>
                                                </Box>

                                                <Dropdown drop="start" autoClose="outside">
                                                    <Dropdown.Toggle id="dropdown-autoclose-outside" className="action-toggle-button">
                                                        <Tooltip
                                                            title="Show More"
                                                            classes={{ tooltip: "list-tooltip" }}
                                                        >
                                                            <MoreVertIcon
                                                                className="dot-icon"
                                                                onClick={toggleClass}
                                                            />
                                                        </Tooltip>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="dropdown-menu client-drop-menu">
                                                        <Dropdown.Item
                                                            className="table-edit-del "
                                                            href="#/action-1"
                                                        >
                                                            {" "}
                                                            <Link
                                                                to={"/edittimesheet"}
                                                                id="domain1"
                                                                label="Domain"
                                                                className="table-edit   justify-content-start client-table-btn btn"
                                                                margin="normal"
                                                            >
                                                                <BorderColorIcon /> Edit
                                                            </Link>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            className="table-edit-del"
                                                            href="#/action-2"
                                                        >
                                                            <a
                                                                href="#upload"
                                                                id="domain2"
                                                                label="Domain"
                                                                className="table-del justify-content-start client-table-btn  btn"
                                                                margin="normal"
                                                            >
                                                                <DeleteOutlineIcon /> Delete
                                                            </a>
                                                        </Dropdown.Item>


                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </TableCell>
                                        </TableRow>
                                        {toggleCell && (
                                            <TableRow>

                                                <TableCell colSpan={10} className="p-0 medical-main-sub-td">
                                                    <Box className="w-100 medical-sub-datefield">
                                                        <DateField />
                                                    </Box>
                                                    <Table sx={{ minWidth: "100%" }} aria-label="caption table">
                                                        <TableHead>
                                                            <TableCell className=" medicalSub-head-cell" align="center" >Time</TableCell>
                                                            <TableCell className=" medicalSub-head-cell" align="center" >Mon</TableCell>
                                                            <TableCell className=" medicalSub-head-cell" align="center" >Tue</TableCell>
                                                            <TableCell className=" medicalSub-head-cell" align="center" >Wed</TableCell>
                                                            <TableCell className=" medicalSub-head-cell" align="center">Thu</TableCell>
                                                            <TableCell className=" medicalSub-head-cell" align="center">Fri</TableCell>
                                                            <TableCell className=" medicalSub-head-cell" align="center">Sat</TableCell>
                                                            <TableCell className=" medicalSub-head-cell" align="center">Sun</TableCell>

                                                        </TableHead>

                                                        <TableBody>
                                                            <TableCell className="medicalSub-head-cell medical-sub-timefield" align="center">
                                                                <Box>
                                                                    10:00
                                                                </Box>
                                                                <Box>
                                                                    22:00
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" className="medicalSub-head-cell">
                                                                <Box>
                                                                    <Checkbox onClick={handleClickOpen} {...label} />
                                                                </Box>
                                                                <Box>
                                                                    <Checkbox onClick={handleClickOpen} {...label} />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" className="medicalSub-head-cell">
                                                                <Box>
                                                                    <Checkbox onClick={handleBlankOpen} {...label} />
                                                                </Box>
                                                                <Box>
                                                                    <Checkbox onClick={handleBlankOpen} {...label} />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" className="medicalSub-head-cell">
                                                                <Box>
                                                                    <Checkbox {...label} />
                                                                </Box>
                                                                <Box>
                                                                    <Checkbox {...label} />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" className="medicalSub-head-cell">
                                                                <Box>
                                                                    <Checkbox {...label} />
                                                                </Box>
                                                                <Box>
                                                                    <Checkbox {...label} />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" className="medicalSub-head-cell">
                                                                <Box>
                                                                    <Checkbox {...label} />
                                                                </Box>
                                                                <Box>
                                                                    <Checkbox {...label} />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" className="medicalSub-head-cell">
                                                                <Box>
                                                                    <Checkbox {...label} />
                                                                </Box>
                                                                <Box>
                                                                    <Checkbox {...label} />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" className="medicalSub-head-cell">
                                                                <Box>
                                                                    <Checkbox {...label} />
                                                                </Box>
                                                                <Box>
                                                                    <Checkbox {...label} />
                                                                </Box>
                                                            </TableCell>
                                                        </TableBody>

                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box ml={4}>
                        <div className="record-page">
                            <Typography component="div" className="totalcount">
                                Total Records: 90
                            </Typography>
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                containerClassName={"pagination justify-content-right"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item previous-page-link"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    </Box>
                </Box>


                <Box sx={{ mb: 5 }} className="agency-btn-section add-expenses-btn">
                    <Link href="#cancel">Cancel</Link>
                    <Link href="#save" to="/add-appointment" >
                        Save
                    </Link>
                </Box>
            </Box>


            {/* attach file modal   */}

            <Dialog
                open={openAttach}
                onClose={handleCloseAttach}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="attach-file-modal"
            >
                <DialogContent className="modal-text">
                    <DialogContentText id="alert-dialog-description">
                        <Box className="d-flex justify-content-center">
                            <Box className="add-expenses">
                                <Box className="add-expenses-head d-flex align-items-center justify-content-between">
                                    <Typography> Add Expenses</Typography>
                                    <Typography>
                                        Client: <span>Jake Knap</span>
                                    </Typography>
                                </Box>
                                <Box className="add-expenses-body">
                                    <Box className="attach-document">
                                        <h3>Attach Document</h3>
                                        <Box className="attach-document-body d-flex align-items-center">
                                            <Box className="d-flex align-items-center ">
                                                <input
                                                    type="radio"
                                                    name="uploadfile"
                                                    id="attachupload"
                                                ></input>
                                                <img src={upload}></img>
                                                <label for="attachupload">Upload</label>
                                            </Box>
                                            <Box className="d-flex align-items-center" sx={{ pl: 2 }}>
                                                <input
                                                    type="radio"
                                                    name="uploadfile"
                                                    id="attachcapture"
                                                ></input>
                                                <img src={camera}></img>
                                                <label for="attachcapture">Capture</label>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Form className="d-flex align-items-center attach-document-browse w-100 justify-content-between">
                                                <TextField
                                                    id="outlined-basic"
                                                    size="small"
                                                    className="attach-file-input"
                                                    type="file"
                                                ></TextField>
                                                <Button>Upload</Button>
                                            </Form>
                                        </Box>
                                    </Box>

                                    <Box sx={{ mb: 5 }} className="agency-btn-section add-expenses-btn">
                                        <Link href="javascript:;" onClick={handleCloseAttach}>
                                            Cancel
                                        </Link>
                                        <Link
                                            href="javascript:;"
                                            to="/timesheet"
                                            onClick={handleCloseAttach}
                                        >
                                            Save
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="attach-file-modal"
            >
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        className="modal-content"
                    >
                        <Box className="map-modal bg-light">
                            <Box className="popup-alert-icon">
                                <img src={alertImg} />
                            </Box>
                        </Box>

                        <h5 className="medical-popup-heading text-center">
                            Are you sure you've given Valium due <br /> to following circumstances?
                        </h5>

                        <Box className="popup-type d-flex align-items-center justify-content-center">
                            <Typography>
                                Circumstance
                            </Typography>

                            <span> - Anxiety </span>
                        </Box>

                        <label>Observed Circumstances<span className="danger">*</span> </label>

                        <Box sx={{ mt: 1, mb: 2 }}>
                            <textarea className="textarea-md form-input w-100" name="w3review" rows="4" cols="50">
                                Enter Reactions to Medications
                            </textarea>
                        </Box>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={6}>
                                <label>Date <span className="danger">*</span> </label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            // views={['day', 'month', 'year']}
                                            className="form-input date-input-filed"
                                            // format="DD-MM-YYYY"
                                            inputFormat="DD-MM-YYYY"
                                            size="small"
                                            defaultValue={"18-04-2023"}
                                            // name="from"

                                            onChange={handleChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    size="small"
                                                    sx={{ mb: 1 }}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "DD-MM-YY",
                                                    }}

                                                // error={
                                                //     formik.touched.from && Boolean(formik.errors.from)
                                                // }
                                                // helperText={formik.touched.from && formik.errors.from}
                                                />
                                            )}
                                            minDate={new Date()}
                                        />
                                    </Stack>
                                </LocalizationProvider>

                            </Grid>

                            <Grid item xs={12} md={6} sm={6} >
                                <label> Time <span className="danger">*</span> </label>
                                <TextField
                                    sx={{ mb: 2 }}
                                    className='form-input timefield-popup w-100'
                                    type="time"
                                    size="small"
                                />
                            </Grid>
                        </Grid>

                        <Box className="agency-btn-section add-expenses-btn">
                            <Link href="#cancel" onClick={handleClose} >Cancel</Link>
                            <Link href="#save" onClick={handleClose} to="/add-appointment">
                                Yes
                            </Link>
                        </Box>

                    </DialogContentText>
                </DialogContent>
            </Dialog>


            <Dialog
                open={openBlank}
                onClose={handleBlankClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="attach-file-modal"
            >
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        className="modal-content"
                    >
                        <Box className="map-modal bg-light">
                            <Box className="popup-alert-icon">
                                <img src={alertImg} />
                            </Box>
                        </Box>

                        <h5 className="medical-popup-heading text-center">
                            Are you sure you have given this <br /> medicine - Calciferol at 10:00?
                        </h5>


                        <Box className="agency-btn-section add-expenses-btn">
                            <Link href="#cancel" onClick={handleBlankClose}>Cancel</Link>
                            <Link href="#save" onClick={handleBlankClose} to="/add-appointment">
                                Yes
                            </Link>
                        </Box>

                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </Box>
    );
}
