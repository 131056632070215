import React, { useState } from "react";
import Box from "@mui/material/Box";
import Appbar from "../../Dashboard/Layout/Appbar";
import Sidebar from "../../Dashboard/Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Tooltip, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../../assets/css/dashboard.css";
import "../../assets/css/Appointment.css";

import Dropdown from "react-bootstrap/Dropdown";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import View from "../../assets/images/view.svg";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

function createData(
  name,
  calories,
  fat,
  carbs,
  category,
  onboarding,
  risk,
  schedule,
  status,
  protein
) {
  return {
    name,
    calories,
    fat,
    carbs,
    category,
    onboarding,
    risk,
    schedule,
    status,
    protein,
  };
}

const rows = [
  createData(
    1,
    "Andy Lawrence",
    "andyl@gmail.com",
    "01234567890",
    "Support Coordinator",
    "22-jan-2022",
    "Virtual",
    "60 minutes",
    "GMT + 10"
  ),
  createData(
    2,
    "Sara Gomez",
    "sarag@gmail.com",
    "1234567890",
    "Support Worker ",
    "22-jan-2022",
    "Virtual",
    "60 minutes",
    "GMT + 10"
  ),
  createData(
    3,
    "Harry Nathan",
    "harryn@gmail.com",
    "01234567890",
    "Support Worker",
    "22-jan-2022",
    "Virtual",
    "60 minutes",
    "GMT + 10"
  ),
  createData(
    4,
    "Larry Daniel",
    "larryd@gmail.com",
    "1234567890",
    "Support Coordinator",
    "22-jan-2022",
    "Virtual",
    "60 minutes",
    "GMT + 10"
  ),
];

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// client management pop-up tab

function ClientTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ClientTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a12yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ListView() {
  const [show, setShow] = React.useState(true);
  const [value, setValue] = React.useState(0);

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // client pop-up tab

  const [clientValue, setClientValue] = React.useState(0);

  const handleChangeClient = (event, newValue) => {
    setClientValue(newValue);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography>Client Management</Typography>
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography>Evaluation</Typography>
    </Link>,
  ];

  return (
    <Box className="dashboard-form" id="schedule-apointment">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">
          <Appbar />
        </Box>
      </Box>
      <Box className=" d-flex dashboard-main-box">
        <Sidebar />
        <Box className="main">
          {/* <Box className='dashboard-form-typo dashboard-form-typo-up'>
                        <h3>Scheduled Interviews</h3>
                       
                    </Box> */}

          <Grid container className="d-flex header-topsection">
            <Grid item xs={6} md={6} sm={6}>
              <Box id="breadcrumb">
                <ArrowBackIosIcon />
                <Box className="breadcrumb-inr">
                  <h4 className="d-flex align-items-center ">
                    Scheduled Interviews
                  </h4>
                  <Box className="breadcrumbs">

                    <Link className="breadcrumbs-link breadcrumbs-link-active">
                      Client Management
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={6} sm={6}>
              <Box className="view-list-search">
                <div>
                  <TextField
                    size="small"
                    placeholder="Search by Date, Clients, SAM etc., "
                    id="outlined-start-adornment"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {" "}
                          <SearchIcon />{" "}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>

          <Box className="divider"></Box>

          {/* Tab Start here  */}

          <Box id="tabs" className="tab-box">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} className="d-flex align-items-center">
                {/* <Box  className='tab-container'>  */}
                <Tabs
                  centered
                  className="tab-container "
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {/* {<><p className="tab-text"> List View </p></>} */}
                  <Tab
                    className="Tabstyle-test"
                    icon={<FormatListBulletedIcon />}
                    iconPosition="start"
                    label="List View"
                    {...a11yProps(0)}
                  />

                  <Tab
                    mt={2}
                    className="Tabstyle-test"
                    icon={<InsertInvitationIcon />}
                    iconPosition="start"
                    label="Calendar View"
                    {...a11yProps(1)}
                  />
                </Tabs>
                {/* </Box> */}
              </Grid>

              <Box>
                <TabPanel value={value} index={0}>
                  <TableContainer
                    component={Paper}
                    id="document-table"
                    sx={{ mt: 0 }}
                    className="agency-table document-table client-management-table"
                  >
                    <Table aria-label="simple table">
                      <TableHead id="client-table-head">
                        <TableRow>
                          <TableCell className="tableborder table-head-cell">
                            S.R.
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Agency Name{" "}
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Email
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Phone
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell "
                            align="left"
                          >
                            User
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell date-time-cell"
                            sx={{ width: "100px" }}
                            align="left"
                          >
                            Date & Time
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Meeting <br /> Location
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell th-width"
                            align="left"
                          >
                            Duration{" "}
                          </TableCell>

                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Timezone
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody id="client-table-body">
                        {rows.map((row, index) => (
                          <TableRow key={row.name}>
                            <TableCell
                              component="th"
                              scope="row"
                              className="text-start tableborder"
                            >
                              <a href="#" className="table-link">
                                {row.name}
                              </a>
                            </TableCell>
                            <TableCell align="left" className="tableborder ">
                              {" "}
                              <a
                                href="#"
                                className="table-link main-dark-color"
                              >
                                {row.calories}
                              </a>
                            </TableCell>
                            <TableCell align="left" className="tableborder">
                              {" "}
                              <a href="#" className="table-link">
                                {row.fat}
                              </a>
                            </TableCell>
                            <TableCell align="left" className="tableborder">
                              {" "}
                              <a href="#" className="table-link">
                                {row.carbs}
                              </a>
                            </TableCell>
                            <TableCell align="left" className="tableborder">
                              {" "}
                              <a
                                href="#"
                                className="table-link table-category-cell"
                              >
                                {row.category}
                              </a>
                            </TableCell>
                            <TableCell align="left" className="tableborder">
                              {" "}
                              <a href="#" className="table-link">
                                {row.onboarding}
                              </a>
                            </TableCell>
                            <TableCell
                              align="left"
                              id="client-color-td"
                              className="tableborder"
                            >
                              {" "}
                              <a href="#" className="table-link">
                                {row.risk}
                              </a>
                            </TableCell>
                            <TableCell
                              align="left"
                              className="tableborder schedule-cell sechedule-td"
                            >
                              {" "}
                              {row.schedule}
                            </TableCell>

                            <TableCell align="left" className="tableborder">
                              {" "}
                              <a href="#" className="table-link">
                                {row.status}
                              </a>
                            </TableCell>

                            <TableCell
                              align="left"
                              className="tableborder table-editcell"
                            >
                              <Box className="table-edit-del">
                                <Box>
                                  <a
                                    href="#upload"
                                    id="edit"
                                    label="Domain"
                                    className="table-edit success-btn justify-content-center  btn"
                                    margin="normal"
                                  >
                                    <DoneIcon /> Approve
                                  </a>
                                </Box>
                              </Box>

                              <Dropdown drop="start" autoClose="outside">
                                <Dropdown.Toggle id="dropdown-autoclose-outside" className="action-toggle-button">
                                  <Tooltip
                                    title="Show More"
                                    classes={{ tooltip: "list-tooltip" }}
                                  >
                                    <MoreVertIcon
                                      className="dot-icon"
                                      onClick={toggleClass}
                                    />
                                  </Tooltip>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu client-drop-menu">
                                  <Dropdown.Item
                                    className="table-edit-del"
                                    href="#/action-1"
                                  >
                                    {" "}
                                    <a
                                      href="#upload"
                                      id="domain1"
                                      label="Domain"
                                      className="table-edit  justify-content-start client-table-btn btn"
                                      margin="normal"
                                    >
                                      <Avatar
                                        src={View}
                                        className="view-icon"
                                      ></Avatar>{" "}
                                      View
                                    </a>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="table-edit-del "
                                    href="#/action-1"
                                  >
                                    {" "}
                                    <a
                                      href="#upload"
                                      id="domain1"
                                      label="Domain"
                                      className="table-edit approve-btn  justify-content-start client-table-btn btn"
                                      margin="normal"
                                    >
                                      <DoneIcon /> Approve
                                    </a>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="table-edit-del"
                                    href="#/action-2"
                                  >
                                    <a
                                      href="#upload"
                                      id="domain2"
                                      label="Domain"
                                      className="table-del justify-content-start client-table-btn  btn"
                                      margin="normal"
                                    >
                                      <CloseIcon /> Reject
                                    </a>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  Calendar View
                  {/* <Appttable/> */}
                </TabPanel>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
