import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import {
    Button,
    FormControl,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import AttachmentIcon from "@mui/icons-material/Attachment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/Appointment.css";
import "../Timesheet/timesheet.css";
import Dropdown from "react-bootstrap/Dropdown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import upload from "../../../assets/images/upload.svg";
import camera from "../../../assets/images/camera.svg";
import Form from "@mui/material/FormGroup";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import editIcon from '../../../assets/images/edit-basepay.png'
import Popover from "@mui/material/Popover";
import SearchIcon from "@mui/icons-material/Search";
import tableChart from '../../../assets/images/Medication Chart.png';
import ViewIcon from '../../../assets/images/view.png'
// modal import
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import ReactPaginate from "react-paginate";
import GetAppIcon from "@mui/icons-material/GetApp";
import FileUploadIcon from '@mui/icons-material/FileUpload';

import importicon from "../../../assets/images/exporticon.svg";
import AddIcon from '@mui/icons-material/Add';

export default function MedicalHistory() {
    const [toggle, setToggle] = useState(false);

    const [value, setValue] = React.useState(0);

    const [list, setList] = useState([]);
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

    const [anchorEl, setAnchorEl] = React.useState(null)
    const openPop = Boolean(anchorEl);
    const id = openPop ? "simple-popover" : undefined;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [showhide, setShowhide] = React.useState("");

    const handleshowhide = (event) => {
        const getuser = event.target.value;
        setShowhide(getuser);
    };

    // attach file modal

    const [openAttach, setOpenAttach] = React.useState(false);
    const [isShown, setIsShown] = useState(false);

    const handleClickOpenAttach = () => {
        setOpenAttach(true);
    };

    const handleCloseAttach = () => {
        setOpenAttach(false);
    };

    // client pop-up tab

    const [isActive2, setIsActive] = useState(false);

    const handleClick2 = (event) => {
        setIsActive((current) => !current);
    };




    // Table functionality

    function handleClick(event) {
        event.preventDefault();
        console.info("You clicked a breadcrumb.");
    }

    function createData(
        name,
        calories,
        fat,
        carbs,
        category,
        onboarding,
        risk,
        schedule,
        meeting,
        status,
        protein
    ) {
        return {
            name,
            calories,
            fat,
            carbs,
            category,
            onboarding,
            risk,
            schedule,
            meeting,
            status,
            protein,
        };
    }

    const workType = (
        <Box className="workType-text">

            <span>
                123
            </span>
        </Box>
    );

    const appointTime = (
        <Box className="table-time">
            <span>
                1
            </span>
        </Box>
    );


    const allegies = (
        <ul>
            <li className="d-flex align-items-center"> <span className="dots-style"></span> Dairy products</li>
            <li className="d-flex align-items-center"> <span className="dots-style"></span> Wheatgrass</li>

        </ul>
    )

    const RestrictivePractices = (
        <ul>
            <li className="d-flex align-items-center"> <span className="dots-style"></span> Lock Door</li>
            <li className="d-flex align-items-center"> <span className="dots-style"></span> Keep knives out of sight</li>

        </ul>
    )

    const chart = (
        <div className="table-chart-image">
            <img src={tableChart} />
        </div>
    )
    // table state


    const [rows, setRows] = useState([
        createData(
            appointTime,
            workType,
            "Medical",
            allegies,
            RestrictivePractices,
            "City Hospital",
            chart,
            chart,


        )


    ]);





    // Table functionality

    return (
        <Box className="w-100 main" >



            <Box className="w-100" id="medical-history">
                <Box
                    className="dashboard-form-typo "
                    sx={{ borderBottom: "none" }}
                >
                    <Grid container className="d-flex header-topsection">
                        <Grid item xs={6} sm={6} md={6}>
                            <Box id="breadcrumb">
                                <ArrowBackIosIcon />
                                <Box className="breadcrumb-inr">
                                    <h4 className="d-flex align-items-center "> Medical History</h4>
                                    <Box className="breadcrumbs">
                                        <Link className="breadcrumbs-link"> Medication Management</Link>
                                        <span className="breadcrumbs-icon">{">"}</span>
                                        <Link className="breadcrumbs-link breadcrumbs-link-active">
                                            Medical History
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={6} className="padding-right-20" sm={6} md={6}>
                            <Box className="addTask-btn">
                                <Box className="d-flex align-items-center justify-content-end">


                                    <Button
                                        className="importexportbtn"
                                        variant="outlined"
                                        aria-describedby={id}
                                        onClick={handleClick}
                                        startIcon={
                                            <img
                                                src={importicon}
                                                className="importexporticon-image"
                                                alt="importicon"
                                            />
                                        }
                                    >
                                        Export
                                    </Button>

                                    {/* Export Pophover */}
                                    <Popover
                                        elevation={0}
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                    >
                                        {/* popover content */}
                                        <div>
                                            <Card>
                                                <Box className="exportdropdown-wrap">
                                                    <Box className="cardcontent_box dropdownimport">
                                                        <CardContent className="import_CardContent">
                                                            <div className="exportdata-btn">
                                                                <svg
                                                                    width="18"
                                                                    height="17"
                                                                    viewBox="0 0 18 17"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M1 11.5996V15.5996H17V11.5996"
                                                                        stroke="white"
                                                                        stroke-width="1.45"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M5.80078 8.56055L9.00078 10.9605"
                                                                        stroke="white"
                                                                        stroke-width="1.45"
                                                                        stroke-linecap="round"
                                                                    />
                                                                    <path
                                                                        d="M12.2812 8.40039L9.08125 11.041"
                                                                        stroke="white"
                                                                        stroke-width="1.45"
                                                                        stroke-linecap="round"
                                                                    />
                                                                    <line
                                                                        x1="8.91367"
                                                                        y1="10.8727"
                                                                        x2="8.91367"
                                                                        y2="0.725842"
                                                                        stroke="white"
                                                                        stroke-width="1.45"
                                                                        stroke-linecap="round"
                                                                    />
                                                                </svg>

                                                                <Typography
                                                                    component="div"
                                                                    className="importfiletext"
                                                                >
                                                                    Export Data
                                                                    <span>Download Data</span>
                                                                </Typography>
                                                            </div>
                                                        </CardContent>
                                                    </Box>

                                                    <Box>
                                                        <CardActions className="cardactionsty2 dropdownimportbutton">
                                                            <Link
                                                                data={list}
                                                                filename="Hundred-points.csv"
                                                            // headers={headers}
                                                            >
                                                                <Button
                                                                    className="filebtn"
                                                                    variant="outlined"
                                                                    component="label"
                                                                >
                                                                    <FileUploadIcon align="center" />
                                                                    Export
                                                                </Button>
                                                            </Link>
                                                        </CardActions>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </div>
                                    </Popover>

                                    <Link to="/add-medical-history" className="medical-add-button approve-button">
                                        <AddIcon />  Add
                                    </Link>

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className="divider">

                    </Box>

                    <Box className="agency-searchbar padding-left-20 padding-right-20 searchbox-margin">
                        <Grid
                            container

                            className="align-items-center justify-content-between"
                        >
                            <Grid item xs={6} md={5} sm={5} style={{ paddingLeft: "0px" }}>
                                <TextField
                                    className="search-appointment"
                                    type="search"
                                    size="small"
                                    placeholder="Search Timesheet"
                                    //name="email"
                                    fullWidth
                                    // value={searchTerm}
                                    // onChange={(e) => searchItems(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                </Box>

                <Box
                    className="timesheet-table medical-history padding-left-20 padding-right-20"
                // sx={{ width: '100%', overflow: 'hidden' }}
                >
                    <TableContainer
                        component={Paper}
                        id="document-table"
                        className="padding-res-10"
                    >
                        <Table sx={{ minWidth: "100%" }} id="medical-table" aria-label="caption table">
                            <TableHead id="client-table-head">
                                <TableRow>
                                    <TableCell
                                        className="tableborder table-head-cell"
                                    // align="left"
                                    >
                                        Sr. No.
                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Medicare Number
                                    </TableCell>

                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Issue Type

                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Allergies

                                    </TableCell>

                                    <TableCell
                                        className="tableborder table-head-cell "
                                        align="left"
                                    >
                                        Restrictive Practices
                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Preferred Hospital
                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="left"
                                    >
                                        Medication Chart
                                    </TableCell>
                                    <TableCell
                                        className="tableborder table-head-cell th-width"
                                        align="left"
                                    >
                                        PRN Chart
                                    </TableCell>


                                    <TableCell
                                        className="tableborder table-head-cell"
                                        align="center"
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody id="client-table-body">
                                {rows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className="text-start tableborder"
                                        >
                                            <a href="#" className="table-link">
                                                {row.name}
                                            </a>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className="tableborder work-type-td"
                                        >
                                            {" "}
                                            <a href="#" className="table-link main-dark-color">
                                                {row.calories}
                                            </a>
                                        </TableCell>
                                        <TableCell align="left" className="tableborder">
                                            {" "}
                                            <a href="#" className="table-link">
                                                {row.fat}
                                            </a>
                                        </TableCell>
                                        <TableCell align="left" className="tableborder">
                                            {" "}
                                            <a href="#" className="table-link table-link">
                                                {row.carbs}
                                            </a>
                                        </TableCell>
                                        <TableCell align="left" className="tableborder">
                                            {" "}
                                            <a href="#" className="table-link ">
                                                {row.category}

                                            </a>
                                        </TableCell>

                                        <TableCell align="left" className="tableborder">
                                            {" "}
                                            <a href="#" className="table-link">
                                                {row.onboarding}
                                            </a>
                                        </TableCell>

                                        <TableCell align="center" className="tableborder">

                                            <Link to='/medication-chart' className="table-link" >
                                                {row.schedule}

                                            </Link>

                                        </TableCell>

                                        <TableCell align="center" className="tableborder">

                                            <Link to="/prn-chart" className="table-link">
                                                {row.risk}
                                            </Link>

                                        </TableCell>





                                        <TableCell
                                            align="left"
                                            className="tableborder table-editcell"
                                        >
                                            <Box mr={1} className="table-edit-del">
                                                <Box>
                                                    <a
                                                        href="javascript:;"
                                                        id="edit"
                                                        label="Domain"
                                                        className="table-edit justify-content-center  btn"
                                                        margin="normal"
                                                        onClick={handleClickOpenAttach}
                                                    >
                                                        <img src={editIcon} /> Edit
                                                    </a>
                                                </Box>
                                            </Box>

                                            <Dropdown drop="start" autoClose="outside">
                                                <Dropdown.Toggle id="dropdown-autoclose-outside" className="action-toggle-button">
                                                    <Tooltip
                                                        title="Show More"
                                                        classes={{ tooltip: "list-tooltip" }}
                                                    >
                                                        <MoreVertIcon
                                                            className="dot-icon"
                                                            onClick={toggleClass}
                                                        />
                                                    </Tooltip>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="dropdown-menu client-drop-menu">
                                                    <Dropdown.Item
                                                        className="table-edit-del "
                                                        href="#/action-1"
                                                    >
                                                        {" "}
                                                        <Link
                                                            to={"/edittimesheet"}
                                                            id="domain1"
                                                            label="Domain"
                                                            className="table-edit   justify-content-start client-table-btn btn"
                                                            margin="normal"
                                                        >
                                                            <BorderColorIcon /> Edit
                                                        </Link>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item
                                                        className="table-edit-del"
                                                        href="#/action-2"
                                                    >
                                                        <a
                                                            href="#upload"
                                                            id="domain2"
                                                            label="Domain"
                                                            className="table-del justify-content-start client-table-btn  btn"
                                                            margin="normal"
                                                        >
                                                            <DeleteOutlineIcon /> Delete
                                                        </a>
                                                    </Dropdown.Item>


                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box ml={4}>
                        <div className="record-page">
                            <Typography component="div" className="totalcount">
                                Total Records: 90
                            </Typography>
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                containerClassName={"pagination justify-content-right"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item previous-page-link"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    </Box>
                </Box>

                <Box className="agency-btn-section add-expenses-btn">
                    <Link href="#cancel">Cancel</Link>
                    <Link href="#save" to="/add-appointment" >
                        Save
                    </Link>
                </Box>
            </Box>


            {/* attach file modal   */}



            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="time-sheet-modal"
            >
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        className="modal-content"
                    >
                        <Box className="map-modal bg-light">
                            <div class="mapouter">
                                <div class="gmap_canvas">
                                    <iframe
                                        width="100%"
                                        height="323px"
                                        className="map-iframe"
                                        id="gmap_canvas"
                                        src="https://maps.google.com/maps?q=Sydney&t=&z=10&ie=UTF8&iwloc=&output=embed"
                                        frameborder="0"
                                        scrolling="no"
                                        marginheight="0"
                                        marginwidth="0"
                                    ></iframe>
                                    <Box className="d-flex align-items-center justify-content-between map-modal-footbar">
                                        <Typography className="distance-txt" sx={{ pb: 0 }}>
                                            Distance: 45 KM
                                        </Typography>
                                        <Button onClick={handleClose}>Go Back</Button>
                                    </Box>
                                </div>
                            </div>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>
    );
}
