import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  DialogContentText,
  CardHeader,
  Grid,
  Typography,
  Button,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { addDemoRequest } from "../../../api";
import { toast } from "react-toastify";
import WebHeader from "../../../Website/Web-Header";
import { Stack } from "@mui/material";
import BookDemoCalender from "./BookDemo-Calender";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import "../../../Website/HomeUI/home-ui.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Container } from "@mui/material";
import format from "date-fns/format";
import checkImage from "../../../assets/images/demo-nf-icons.svg";
import TimerIcons from "../../../assets/images/Timer-icons.svg";
import calendarIcon from "../../../assets/images/calendar.png";
import Swal from "sweetalert2";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SignInSide() {
  const [selectedDateTime, setSelectedDateTime] = useState({
    selectedDate: null,
    selectedTime: "",
    nextTimeSlot: "",
  });
  const handleDateTimeSelect = (selectedDate, selectedTime, nextTimeSlot) => {
    setSelectedDateTime({
      date: selectedDate,
      time: selectedTime,
      endDate: selectedDate,
      duration: nextTimeSlot,
    });
  };
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    userType: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    availability: "",
  };
  const phoneRegExp = /^04\d{8}$/;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format.")
      .required("Email is required."),
    phone: Yup.string()
    .matches(
      phoneRegExp,
      "Enter valid Phone number. It must start with '04' and 10 digits"
    ),
    firstName: Yup.string()
      .trim()
      .min(2, "Minimum 2 characters required.")
      .max(50, "Maximum 50 characters required.")
      .test("required", "First Name is required.", (value) => {
        return value && value.trim() !== "";
      }),
    lastName: Yup.string()
      .trim()
      .min(2, "Minimum 2 characters required.")
      .max(50, "Maximum 50 characters required.")
      .test("required", "Last Name is required.", (value) => {
        return value && value.trim() !== "";
      }),

    userType: Yup.string().required("User Type is required."),
  });

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value?.split(",") : value
    );
  };

  const [value, setValue] = React.useState();

  let formattedDate = "";
  if (selectedDateTime.date instanceof Date && !isNaN(selectedDateTime.date)) {
    formattedDate = format(selectedDateTime.date, "dd/MM/yyyy");
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    window.location.reload();
  };

  const deleteCheck = (selectedDateTime, formattedDate) => {
    Swal.fire({
      title: "Demo Confirmed!",
      text: "Shortly, a meeting link will be sent to your email address.",
      // icon: "warning",
      // iconHtml: '<img src="https://picsum.photos/100/100">',
      iconHtml: `<img src="${checkImage}">`,
      showCancelButton: false,
      showConfirmButton: false,
      buttonsStyling: false,
      customClass: {
        // icon: "rejecticon-class",
        icon: "demo-alert-no-border",
      },

      html: `
      
              <div class="d-flex align-items-center justify-content-center">
                    <span>Shortly, a meeting link will be sent to your <br/> email address.</span>
                </div>


        <div class="d-flex align-items-center justify-content-center">


          <div class="bookSlot-select-wrapper1" style="display: flex; align-items: center; justify-content: center; margin-top: 20px;">

              <div style="display: flex; align-items: center justify-content: center;">

                

                <img src="${calendarIcon}" class="icon" style="height: 20px; width: 20px; margin-top: 4px;"/>

                &nbsp;

                    <span>${formattedDate}</span>
                </div>

                <div style="margin: 0 10px;">-</div>

                <div style="display: flex; align-items: center justify-content: center;">

                <img src="${TimerIcons}" class="icon" style="height: 20px; width: 20px; margin-top: 4px;"/>

                &nbsp;

                    <span style="text-align: center;">${selectedDateTime.time} - ${selectedDateTime.duration}</span>
                </div>

            </div>

          </div>
            
        `,
    });
  };

  const handleButtonClick = () => {
    // Implement your button functionality here
    console.log("Button clicked");
    deleteCheck();
  };

  console.log("selectedDateTime.date", selectedDateTime.date);
  return (
    <Box id="personal-info" className="bookDemo-bg" width="100%" height="100%">
      <Box>
        <WebHeader />
      </Box>

      <Box id="demo-request">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formikHelpers) => {
            console.log("Form submitted with values:", values);
            if (!selectedDateTime.date || !selectedDateTime.time) {
              // Display error messages or prevent form submission
              toast.error("Please select both date and time.");
              return;
            }
            setDisableButton(true);
            values.availability = selectedDateTime;
            values.duration = selectedDateTime.duration;
            values.email = values.email.toLocaleLowerCase();
            axios
              .post(addDemoRequest, values)
              .then((res) => {
                if (res.data.success === false) {
                  toast.error(res.data.message);
                  setDisableButton(false);
                }
                if (res.data.success === true) {
                  // toast.success(res.data.message);
                  handleClickOpen();
                  // deleteCheck(selectedDateTime, formattedDate);
                  setDisableButton(false);
                  // setTimeout(() => {
                  //   window.location.reload();
                  // }, 1000);
                }
              })
              .catch((error) => {
                toast.error("Something went wrong");
              });
          }}
        >
          {({ errors, isValid, touched, dirty, values, handleChange }) => (
            <Form>
              <Box
                className="book-demo-webContainer"
                // className="webContainer"
              >
                <Box className="demo-request-wrapper demo-back" >
                  <Box className="w-100 text-center" >
                    <h2
                      mb={3}
                      className="sign-title bookdemo-title text-center Text-white"
                    >
                      Book A Demo
                      {/* <p className="text-mute">Please enter your details</p> */}
                    </h2>
                    <Typography mb={3} className="Text-white">
                      Schedule a Demo, Set Up Your Account, and Activate Your
                      Free Trial
                    </Typography>
                  </Box>

                  <Grid container className="demo-container " >
                    <Grid item xs={12} sm={12} md={6}>
                      <Box className="demo-step-section">
                        {/* <span>step 1</span> */}
                        <Typography>Basic Details</Typography>
                      </Box>
                      <Box className="w-100 h-100 ">
                        <Box className="form-main-box">
                          <Box>
                            <Box className="form-field">
                              <RadioGroup
                                row
                                aria-label="userType"
                                name="userType"
                                value={values.userType}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  className="demo-text"
                                  value="Service Provider"
                                  control={<Radio />}
                                  label="I am a Service Provider"
                                />
                                <FormControlLabel
                                  className="demo-text"
                                  value="Individual Support Coordinator"
                                  control={<Radio />}
                                  label="I am a Support Coordinator"
                                />
                                &nbsp; &nbsp; &nbsp;
                                <FormControlLabel
                                  className="demo-text"
                                  value="Client"
                                  control={<Radio />}
                                  label="I am a Client"
                                />
                              </RadioGroup>
                              {touched.userType && errors.userType && (
                                <Typography variant="caption" color="error">
                                  {errors.userType}
                                </Typography>
                              )}
                            </Box>

                            <Box className="form-field">
                              <Stack
                                direction="row"
                                spacing={1}
                                className="form-field"
                              >
                                <Box>
                                  <label>
                                    First Name
                                    <span className="red-color">*</span>
                                  </label>
                                  <Field
                                    name="firstName"
                                    type="text"
                                    as={TextField}
                                    margin="normal"
                                    size="small"
                                    className="form-input"
                                    placeholder="Enter First Name"
                                    error={
                                      Boolean(errors.firstName) &&
                                      Boolean(touched.firstName)
                                    }
                                    helperText={
                                      Boolean(touched.firstName) &&
                                      errors.firstName
                                    }
                                    maxLength={60}
                                  />
                                </Box>
                                <Box>
                                  <label>
                                    Last Name
                                    <span className="red-color">*</span>
                                  </label>
                                  <Field
                                    name="lastName"
                                    type="text"
                                    as={TextField}
                                    margin="normal"
                                    size="small"
                                    className="form-input"
                                    placeholder="Enter Last Name"
                                    error={
                                      Boolean(errors.lastName) &&
                                      Boolean(touched.lastName)
                                    }
                                    helperText={
                                      Boolean(touched.lastName) &&
                                      errors.lastName
                                    }
                                    maxLength={60}
                                  />
                                </Box>
                              </Stack>
                            </Box>
                            <Box className="form-field">
                              <label>
                                Email<span className="red-color">*</span>{" "}
                              </label>

                              <Field
                                size="small"
                                name="email"
                                type="email"
                                as={TextField}
                                className="form-input"
                                margin="normal"
                                fullWidth
                                id="outlined-required"
                                placeholder="Enter Email"
                                value={
                                  values?.email.toLocaleLowerCase()
                                }
                                error={
                                  Boolean(errors.email) &&
                                  Boolean(touched.email)
                                }
                                helperText={
                                  Boolean(touched.email) && errors.email
                                }
                              />
                            </Box>
                            <Box className="form-field">
                              <label>Phone</label>

                              <Field
                                size="small"
                                name="phone"
                                type="text"
                                as={TextField}
                                className="form-input"
                                margin="normal"
                                fullWidth
                                id="outlined-required"
                                placeholder="Enter Phone"
                                error={
                                  Boolean(errors.phone) &&
                                  Boolean(touched.phone)
                                }
                                helperText={
                                  Boolean(touched.phone) && errors.phone
                                }
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <Box className="demo-step-section">
                        {/* <span>step 2</span> */}
                        <Typography>Select Date & Time</Typography>
                      </Box>

                      <Box className="bookCalender-margin">
                        <BookDemoCalender
                          onDateTimeSelect={handleDateTimeSelect}
                        />

                        {selectedDateTime.time ? (
                          <Box className="Book-select-slot " sx={{ mt: 2 }}>
                            <Box className="d-flex align-items-center justify-content-center">
                              <Typography sx={{ mr: 2, marginLeft: "16px" }}>
                                Selected Slot:
                              </Typography>

                              <Box className="bookSlot-select-wrapper d-flex align-items-center justify-content-between">
                                <Box className="d-flex align-items-center">
                                  <CalendarMonthIcon
                                    className="watch-icon"
                                    sx={{ marginRight: 1 }}
                                  />
                                  <Typography sx={{ display: "inline-block" }}>
                                    {formattedDate}
                                  </Typography>
                                </Box>

                                <Box
                                  className="bookSlot-divider"
                                  sx={{ marginLeft: 2, marginRight: 2 }}
                                ></Box>

                                <Box className="d-flex align-items-center">
                                  <ScheduleIcon
                                    className="watch-icon"
                                    sx={{ marginRight: 1 }}
                                  />
                                  <Typography sx={{ display: "inline-block" }}>
                                    {selectedDateTime.time} -{" "}
                                    {selectedDateTime.duration}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ) : null}
                      </Box>

                      <Box className="w-100 text-right">
                        <Button
                          className="schedule-btn w-auto bookDemo-submit"
                          type="submit"
                          fullWidth
                          sx={{ mb: 2, mt: 5 }}
                          disabled={disableButton}
                        >
                          {disableButton === true
                            ? "Processing..."
                            : "Schedule"}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <>
                  <Grid>
                    <Box>
                      <Dialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        maxWidth="xs"
                      >
                        <Box>
                          <DialogTitle>
                            <Box className="d-flex align-items-center justify-content-end">
                              <CloseIcon
                                aria-label="close"
                                style={{ color: "gray" }}
                                onClick={handleClose}
                              />
                            </Box>
                            <Box className="thankyou-box">
                              <CardHeader
                                action={
                                  <IconButton aria-label="settings">
                                    <CheckCircleIcon className="check-circle-icon fdasfdfsa" />
                                  </IconButton>
                                }
                              />
                            </Box>
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              <Box
                                className="Book-select-slot"
                                sx={{ mt: 2, textAlign: "center" }}
                              >
                                <Typography mb={2} className="popup-head1">
                                  Demo Confirmed!
                                </Typography>
                                <Typography mb={3} className="popup-head2">
                                  Shortly, a meeting link will be sent to your
                                  email address.
                                </Typography>
                                <Box className="d-flex align-items-center justify-content-center">
                                  <Box className="bookSlot-select-wrapper d-flex align-items-center justify-content-between">
                                    <Box className="d-flex align-items-center">
                                      <CalendarMonthIcon
                                        className="watch-icon"
                                        sx={{ marginRight: 2 }}
                                      />
                                      <Typography
                                        sx={{ display: "inline-block" }}
                                      >
                                        {formattedDate}
                                      </Typography>
                                    </Box>
                                    <Box
                                      className="bookSlot-divider"
                                      sx={{ marginLeft: 2, marginRight: 2 }}
                                    ></Box>
                                    <Box className="d-flex align-items-center">
                                      <ScheduleIcon
                                        className="watch-icon"
                                        sx={{ marginRight: 1 }}
                                      />
                                      <Typography
                                        sx={{ display: "inline-block" }}
                                      >
                                        {selectedDateTime.time} -{" "}
                                        {selectedDateTime.duration}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </DialogContentText>
                          </DialogContent>
                        </Box>
                      </Dialog>
                    </Box>
                  </Grid>
                </>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
