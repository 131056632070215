import * as React from "react";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { IndividualUserSignup, createdBy } from "../../api";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import BannerSlider from "../../components/ui/BannerSlider";

import WebHeader from "../../Website/Web-Header";
import Link from "@mui/material/Link";

export default function SignInSide(props) {
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();

  let validationSchema;

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    healthcarercheckbox: true,
    healthcarerabnnumber: "",
  };

  const phoneRegExp = /^04\d{8}$/;

  validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(60, "Maximum 60 symbols")
      .required("First Name is required"),
    lastName: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(60, "Maximum 60 symbols")
      .required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .matches(
        phoneRegExp,
        "Enter valid Mobile number. It must start with '04' and 10 digits"
      )
      .required("Mobile number is required"),

    healthcarerabnnumber: Yup.string()
      .required("ABN Number is required")
      .matches(
        /^\d{11}$/,
        "ABN Number must be 11 digits. Only numbers are allowed."
      )
      .matches(/^[0-9]+$/, "Invalid ABN Number. Only numbers are allowed."),
  });

  return (
    <Box id="personal-info" width="100%" height="100%">
      <Box>
        <WebHeader />
      </Box>
      <Grid container sx={{ paddingBottom: "50px" }}>
        <Grid item xs={12} sm={12} md={6.5}>
          <Box>
            <BannerSlider />
          </Box>
        </Grid>

        <Grid item xs={12} md={5.5} sx={{ marginBottom: "70px" }}>
          <Box className="w-100 h-100 d-flex align-items-center justify-content-center HC-sign-form">
            <Box className="form-main-box" sx={{ paddingBottom: "51px" }}>
              <Box className="client-appt-check">
                <h2 mb={2} className="sign-title HC-sign-head safds">
                  Sign up as Health Carer
                  <p className="text-mute">
                    {" "}
                    Please enter your details (For ABN Holders Only).
                  </p>
                </h2>
              </Box>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  values.email = values.email.toLocaleLowerCase();
                  const postData = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    createdBy: createdBy,
                    updatedBy: createdBy,
                    email: values.email,
                    mobileNumber: values.mobileNumber,
                    userType: "healthCarer",
                    isIndividual: values.healthcarercheckbox,
                    abnNumber: values.healthcarerabnnumber
                      ? values.healthcarerabnnumber
                      : "",
                  };
                  setDisableButton(true);
                  axios
                    .post(IndividualUserSignup, postData)
                    .then((res) => {
                      if (res.data.success === false) {
                        setDisableButton(false);
                        toast.error(res.data.message);
                      }
                      if (res.data.success === true) {
                        setDisableButton(false);
                        toast.success(res.data.message);
                        setTimeout(() => {
                          navigate("/sign-in");
                        }, 3000);
                      }
                    })
                    .catch((error) => {
                      toast.error("Something went wrong");
                    });
                }}
              >
                {({
                  setFieldValue,
                  errors,
                  touched,
                  values,
                  handleBlur,
                  handleChange,
                  isSubmitting,
                  isValid,
                  dirty,
                }) => (
                  <>
                    <Form>
                      <div className="form-field">
                        <label>
                          Full Name<span className="red-color">*</span>
                        </label>
                        <Stack
                          direction="row"
                          spacing={1}
                          className="form-field"
                        >
                          <div>
                            <Field
                              name="firstName"
                              type="text"
                              as={TextField}
                              margin="normal"
                              size="small"
                              className="form-input HC-textfied-valmsg val-error"
                              placeholder="First Name"
                              error={
                                Boolean(errors.firstName) &&
                                Boolean(touched.firstName)
                              }
                              helperText={
                                Boolean(touched.firstName) && errors.firstName
                              }
                              maxLength={60}
                              inputProps={{ maxLength: 60 }}
                            />
                          </div>
                          <div>
                            <Field
                              name="lastName"
                              type="text"
                              as={TextField}
                              margin="normal"
                              size="small"
                              className="form-input HC-textfied-valmsg val-error"
                              placeholder="Last Name"
                              error={
                                Boolean(errors.lastName) &&
                                Boolean(touched.lastName)
                              }
                              helperText={
                                Boolean(touched.lastName) && errors.lastName
                              }
                              maxLength={60}
                              inputProps={{ maxLength: 60 }}
                            />
                          </div>
                        </Stack>
                      </div>
                      <div className="form-field">
                        <label>
                          Email <span className="red-color">*</span>{" "}
                        </label>
                        <Field
                          name="email"
                          type="email"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input HC-textfied-valmsg"
                          placeholder="Email"
                          error={
                            Boolean(errors.email) && Boolean(touched.email)
                          }
                          value={values?.email.toLocaleLowerCase()}
                          helperText={Boolean(touched.email) && errors.email}
                          maxLength={100}
                          inputProps={{ maxLength: 100 }}
                        />
                      </div>
                      <div className="form-field">
                        <label>
                          {" "}
                          Mobile Number <span className="red-color">
                            *
                          </span>{" "}
                        </label>
                        <Field
                          name="mobileNumber"
                          type="text"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input HC-textfied-valmsg"
                          placeholder="Mobile Number"
                          error={
                            Boolean(errors.mobileNumber) &&
                            Boolean(touched.mobileNumber)
                          }
                          helperText={
                            Boolean(touched.mobileNumber) && errors.mobileNumber
                          }
                          maxLength={16}
                          inputProps={{ maxLength: 16 }}
                        />
                      </div>

                      <p>&nbsp;</p>

                      <Grid container>
                        <label>
                          ABN Number<span className="red-color">*</span>
                        </label>
                        <Field
                          name="healthcarerabnnumber"
                          type="text"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input HC-textfied-valmsg"
                          placeholder="ABN Number"
                          error={
                            Boolean(errors.healthcarerabnnumber) &&
                            Boolean(touched.healthcarerabnnumber)
                          }
                          helperText={
                            Boolean(touched.healthcarerabnnumber) &&
                            errors.healthcarerabnnumber
                          }
                          maxLength={100}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>

                      <Button
                        className="signupbutton "
                        type="submit"
                        fullWidth
                        sx={{ mt: 5, mb: 2 }}
                        disabled={disableButton}
                      >
                        {disableButton === true ? "Processing..." : "Submit"}
                      </Button>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography color="#929495">
                          Already have an account?&nbsp;
                          <Link href="/sign-in" variant="body2">
                            Sign In
                          </Link>
                        </Typography>
                      </Box>
                    </Form>
                  </>
                )}
              </Formik>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
