import React, { useState } from "react";
import Box from "@mui/material/Box";
import Appbar from "../../../Dashboard/Layout/Appbar";
import Sidebar from "../../../Dashboard/Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import AttachmentIcon from "@mui/icons-material/Attachment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/Appointment.css";
import "./timesheet.css";
import Dropdown from "react-bootstrap/Dropdown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MapIcon from "@mui/icons-material/Map";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import event from "../../../assets/images/event.svg";
import event2 from "../../../assets/images/event2.svg";
import event3 from "../../../assets/images/event3.svg";
import leaveavtar from "../../../assets/images/profileimg.png";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import upload from "../../../assets/images/upload.svg";
import camera from "../../../assets/images/camera.svg";
import Form from "@mui/material/FormGroup";
import filterImg from "../../../assets/images/filter.png";
import searchImg from "../../../assets/images/search.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InputGroup from "react-bootstrap/InputGroup";
import AddIcon from "@mui/icons-material/Add";

import SearchIcon from "@mui/icons-material/Search";

// modal import
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import ReactPaginate from "react-paginate";

// import DatePickerComponent from '../CalenderFilter/DatePicker';
import TimesheetExpenses from "./Timesheet-Expenses";
import DatePickerComponent from "./CalenderFilter/DatePicker";
import MonthField from "./CalenderFilter/MonthField";
import DayField from "./CalenderFilter/DayField";
import WeekField from "./CalenderFilter/WeekField";

export default function Timesheet() {
  const [toggle, setToggle] = useState(false);

  const [value, setValue] = React.useState(0);

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [showhide, setShowhide] = React.useState("");

  const handleshowhide = (event) => {
    const getuser = event.target.value;
    setShowhide(getuser);
  };

  // attach file modal

  const [openAttach, setOpenAttach] = React.useState(false);
  const [isShown, setIsShown] = useState(false);

  const handleClickOpenAttach = () => {
    setOpenAttach(true);
  };

  const handleCloseAttach = () => {
    setOpenAttach(false);
  };

  // client pop-up tab

  const [isActive2, setIsActive] = useState(false);

  const handleClick2 = (event) => {
    setIsActive((current) => !current);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography>Timesheets</Typography>
    </Link>,
    // <Link
    //     underline="hover"
    //     key="2"
    //     color="inherit"
    //     href="/material-ui/getting-started/installation/"
    //     onClick={handleClick}
    // >
    //     <Typography>Evaluation</Typography>
    // </Link>,
  ];

  const showClick1 = (event) => {
    setIsShown((current) => !current);
  };

  // Table functionality

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  function createData(
    name,
    calories,
    fat,
    carbs,
    category,
    onboarding,
    risk,
    schedule,
    meeting,
    status,
    protein
  ) {
    return {
      name,
      calories,
      fat,
      carbs,
      category,
      onboarding,
      risk,
      schedule,
      meeting,
      status,
      protein,
    };
  }

  const workType = (
    <Box className="workType-text">
      <Typography>Homecare</Typography>
      <span>
        {" "}
        Assistance in Supported <br /> Independent Living
      </span>
    </Box>
  );

  const appointTime = (
    <Box className="table-time">
      <span>
        Mon 04-02-2023 <br /> 09:00 - 17:00
      </span>
    </Box>
  );

  const Expenses = <TimesheetExpenses />;

  const Finishedtime = (
    <input
      class="no-timer-icon"
      type="time"
      id="appt"
      name="appt"
      required
      value="00:00"
    />
  );

  const Finishedtime1 = <div>17:00</div>;

  const breaktime = <div>17:00</div>;

  const ExpensesAdd = (
    <Link className="expenses-add" to="/add-expenses">
      {" "}
      <AddCircleIcon /> Add Expenses
    </Link>
  );

  const totalUnit = (
    // <Typography
    //   href="#javascript:;"
    //   className="total-unit d-flex align-items-center"
    // >
    //   <AccessTimeIcon fontSize="small" /> 7 hours
    // </Typography>
    <Stack
      // ml={1}
      direction="row"
      spacing={0}
      className="d-flex align-items-center justify-content-center"
    >
      <AccessTimeIcon fontSize="small" />
      <Typography className="total-unit">7 hours</Typography>
    </Stack>
  );

  // table state

  const [isEditingIndex, setIsEditingIndex] = useState(-1);
  const [rows, setRows] = useState([
    createData(
      appointTime,
      workType,
      "Jake Knap",
      "Sydney",
      "09:00",
      "17:00",
      Finishedtime1,
      breaktime,
      totalUnit,
      Expenses
    ),
    createData(
      appointTime,
      workType,
      "William Wordsworth",
      "Sydney",
      "16:00",
      "20:00",
      Finishedtime1,
      breaktime,
      totalUnit,
      ExpensesAdd
    ),
    createData(
      appointTime,
      workType,
      "Albert Camus",
      "Sydney",
      "12:00",
      "20:00",
      Finishedtime,
      Finishedtime,
      totalUnit,
      ExpensesAdd
    ),
  ]);

  const handleTimeClick = (index, field) => {
    const updatedRows = [...rows];
    updatedRows.forEach((row, i) => {
      row.isEditing = index === i;
      row.clickedField = field;
    });
    setRows(updatedRows);
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const updatedRows = [...rows];
    const clickedField = updatedRows[index].clickedField;
    updatedRows[index][clickedField] = value;
    setRows(updatedRows);
  };

  const handleInputBlur = (index) => {
    const updatedRows = [...rows];
    updatedRows[index].isEditing = false;
    updatedRows[index].clickedField = null;
    setRows(updatedRows);
  };

  // Table functionality

  return (
    <Box className="dashboard-form" id="timesheet">
      <Box className="main">
        <Box
          className="dashboard-form-typo dashboard-form-typo-up"
          sx={{ borderBottom: "none" }}
        >
          <Grid container className="d-flex header-topsection">
            <Grid item xs={6} sm={6} md={6}>
              <Box id="breadcrumb">
                <ArrowBackIosIcon />
                <Box className="breadcrumb-inr">
                  <h4 className="d-flex align-items-center "> Timesheets</h4>
                  <Box className="breadcrumbs">
                    {/* <Link className="breadcrumbs-link">Timesheets</Link> */}
                    {/* <span className="breadcrumbs-icon">{">"}</span> */}
                    <Link className="breadcrumbs-link breadcrumbs-link-active">
                      Timesheets
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box className="agency-searchbar searchbox-margin">
            <Grid
              container
              spacing={2}
              className="align-items-center justify-content-between"
            >
              <Grid item xs={6} md={5} sm={5} style={{ paddingLeft: "0px" }}>
                <TextField
                  className="search-appointment"
                  type="search"
                  size="small"
                  placeholder="Search Timesheet"
                  //name="email"
                  fullWidth
                  // value={searchTerm}
                  // onChange={(e) => searchItems(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                item
                xs={6}
                md={7}
                sm={7}
                className="d-flex appointment-calender-filed-box justify-content-center align-items-center"
              >
                <Box className="appointment-calender-filed-select">
                  <FormControl fullWidth className="slot-calender">
                    <Select
                      labelId="demo-simple-select-label"
                      id="date-day-slot"
                      value={showhide}
                      size="small"
                      onChange={(e) => handleshowhide(e)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={showhide} selected>
                        {" "}
                        Select Slot{" "}
                      </MenuItem>
                      <MenuItem value="1">Day</MenuItem>
                      <MenuItem value="2">Week</MenuItem>
                      <MenuItem value="3">Month</MenuItem>
                      <MenuItem value="4">Custom</MenuItem>
                    </Select>
                  </FormControl>

                  {showhide === "1" && (
                    <Box id="week-slide" className="slide-filed">
                      <DayField />
                    </Box>
                  )}

                  {showhide === "2" && (
                    <div id="month-slide" className="  slide-filed ">
                      <WeekField />
                    </div>
                  )}

                  {showhide === "3" && (
                    <Box id="Day-slide" className=" slide-filed ">
                      <MonthField />
                    </Box>
                  )}

                  {showhide === "4" && (
                    <Box
                      id="custom-slide"
                      className="appointment-calender-date-filed  "
                    >
                      <DatePickerComponent />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          className="timesheet-table"
        // sx={{ width: '100%', overflow: 'hidden' }}
        >
          <TableContainer
            component={Paper}
            id="document-table"
            className="padding-res-10"
          >
            <Table sx={{ minWidth: 700 }} aria-label="caption table">
              <TableHead id="client-table-head">
                <TableRow>
                  <TableCell
                    className="tableborder table-head-cell"
                  // align="left"
                  >
                    Appointment Date <br /> & Time
                  </TableCell>
                  <TableCell
                    className="tableborder table-head-cell"
                    align="left"
                  >
                    Work Type{" "}
                  </TableCell>

                  <TableCell
                    className="tableborder table-head-cell"
                    align="left"
                  >
                    Client
                    <img
                      className="fliter-img"
                      // onClick={showClick1}
                      src={filterImg}
                    />
                    {/* {isShown && (
                        <InputGroup className="my-3 d-flex align-items-center">
                          <Box className="position-relative w-100 d-flex align-items-center">
                            <img src={searchImg} className="search-img" />
                          
                            <Form.Control
                              onChange={handleServiceSearch}
                              placeholder="Search By Service"
                              value={sericeSearch}
                            />
                            <ArrowDropDownIcon className="drop-icon" />
                          </Box>
                        </InputGroup>
                      )} */}
                  </TableCell>
                  <TableCell
                    className="tableborder table-head-cell"
                    align="left"
                  >
                    Location
                    <img
                      className="fliter-img"
                      // onClick={showClick1}
                      src={filterImg}
                    />
                    {/* {isShown && (
                        <InputGroup className="my-3 d-flex align-items-center">
                          <Box className="position-relative w-100 d-flex align-items-center">
                            <img src={searchImg} className="search-img" />
                          
                            <Form.Control
                              onChange={handleServiceSearch}
                              placeholder="Search By Service"
                              value={sericeSearch}
                            />
                            <ArrowDropDownIcon className="drop-icon" />
                          </Box>
                        </InputGroup>
                      )} */}
                  </TableCell>

                  <TableCell
                    className="tableborder table-head-cell "
                    align="left"
                  >
                    Started
                  </TableCell>
                  <TableCell
                    className="tableborder table-head-cell"
                    align="left"
                  >
                    Finished
                  </TableCell>
                  <TableCell
                    className="tableborder table-head-cell"
                    align="left"
                  >
                    Break Start
                  </TableCell>
                  <TableCell
                    className="tableborder table-head-cell th-width"
                    align="left"
                  >
                    Break End{" "}
                  </TableCell>
                  <TableCell
                    className="tableborder table-head-cell th-width"
                    align="left"
                  >
                    Total Units
                  </TableCell>
                  <TableCell
                    className="tableborder table-head-cell Expenses-cell"
                    align="center"
                  >
                    Expenses
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="table-head-cell" align="left">
                            Title
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Amount ($)
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Document
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableCell>
                  <TableCell
                    className="tableborder table-head-cell"
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="client-table-body">
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      className="text-start tableborder"
                    >
                      <a href="#" className="table-link">
                        {row.name}
                      </a>
                    </TableCell>
                    <TableCell
                      align="left"
                      className="tableborder work-type-td"
                    >
                      {" "}
                      <a href="#" className="table-link main-dark-color">
                        {row.calories}
                      </a>
                    </TableCell>
                    <TableCell align="left" className="tableborder">
                      {" "}
                      <a href="#" className="table-link">
                        {row.fat}
                      </a>
                    </TableCell>
                    <TableCell align="left" className="tableborder">
                      {" "}
                      <a href="#" className="table-link table-link">
                        {row.carbs}
                      </a>
                    </TableCell>
                    <TableCell align="left" className="tableborder">
                      {" "}
                      <a href="#" className="table-link table-category-cell">
                        {row.category} <br />{" "}
                        <Typography className="time-cell">
                          Delay <span> 00:15 min</span>
                        </Typography>{" "}
                      </a>
                    </TableCell>

                    <TableCell align="left" className="tableborder">
                      {" "}
                      <a href="#" className="table-link">
                        {row.onboarding}
                      </a>
                    </TableCell>

                    <TableCell align="left" className="tableborder">
                      {row.isEditing && row.clickedField === "schedule" ? (
                        <input
                          class="no-timer-icon"
                          type="time"
                          id="appt"
                          name="appt"
                          min="09:00"
                          max="18:00"
                          required
                          value={row.schedule}
                          onChange={(e) => handleInputChange(e, index)}
                          onBlur={() => handleInputBlur(index)}
                        />
                      ) : (
                        <a
                          className="table-link"
                          onClick={() => handleTimeClick(index, "schedule")}
                        >
                          {row.schedule}
                        </a>
                      )}
                    </TableCell>

                    <TableCell align="left" className="tableborder">
                      {row.isEditing && row.clickedField === "risk" ? (
                        <input
                          class="no-timer-icon"
                          type="time"
                          id="appt"
                          name="appt"
                          min="09:00"
                          max="18:00"
                          required
                          value={row.risk}
                          onChange={(e) => handleInputChange(e, index)}
                          onBlur={() => handleInputBlur(index)}
                        />
                      ) : (
                        <a
                          className="table-link"
                          onClick={() => handleTimeClick(index, "risk")}
                        >
                          {row.risk}
                        </a>
                      )}
                    </TableCell>

                    <TableCell
                      align="left"
                      id="meetingCell"
                      className="tableborder"
                    >
                      {row.meeting}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="tableborder table-editcell Expenses-cell"
                    >
                      {row.status}
                    </TableCell>

                    <TableCell
                      align="left"
                      className="tableborder table-editcell"
                    >
                      <Box mr={1} className="table-edit-del">
                        <Box>
                          <a
                            href="javascript:;"
                            id="edit"
                            label="Domain"
                            className="table-edit  justify-content-center  btn"
                            margin="normal"
                            onClick={handleClickOpenAttach}
                          >
                            <AttachmentIcon /> Attach
                          </a>
                        </Box>
                      </Box>

                      <Dropdown drop="start" autoClose="outside">
                        <Dropdown.Toggle id="dropdown-autoclose-outside" className="action-toggle-button">
                          <Tooltip
                            title="Show More"
                            classes={{ tooltip: "list-tooltip" }}
                          >
                            <MoreVertIcon
                              className="dot-icon"
                              onClick={toggleClass}
                            />
                          </Tooltip>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu client-drop-menu">
                          <Dropdown.Item
                            className="table-edit-del "
                            href="#/action-1"
                          >
                            {" "}
                            <Link
                              to={"/edittimesheet"}
                              id="domain1"
                              label="Domain"
                              className="table-edit   justify-content-start client-table-btn btn"
                              margin="normal"
                            >
                              <BorderColorIcon /> Edit
                            </Link>
                          </Dropdown.Item>

                          <Dropdown.Item
                            className="table-edit-del"
                            href="#/action-2"
                          >
                            <a
                              href="#upload"
                              id="domain2"
                              label="Domain"
                              className="table-del justify-content-start client-table-btn  btn"
                              margin="normal"
                            >
                              <DeleteOutlineIcon /> Delete
                            </a>
                          </Dropdown.Item>

                          <Dropdown.Item className="table-edit-del map-btn">
                            {" "}
                            <a
                              href="javascript:;"
                              id="domain1"
                              label="Domain"
                              className="table-edit  justify-content-start client-table-btn btn"
                              margin="normal"
                              onClick={handleClickOpen}
                            >
                              <MapIcon /> Map
                            </a>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box ml={4}>
            <div className="record-page">
              <Typography component="div" className="totalcount">
                Total Records: 90
              </Typography>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                containerClassName={"pagination justify-content-right"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item previous-page-link"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </Box>
        </Box>

        <Box className="agency-btn-section add-expenses-btn">
          <Link href="#cancel">Cancel</Link>
          <Link href="#save" to="/timesheet">
            Save
          </Link>
        </Box>
      </Box>

      {/* attach file modal   */}

      <Dialog
        open={openAttach}
        onClose={handleCloseAttach}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="attach-file-modal"
      >
        <DialogContent className="modal-text">
          <DialogContentText id="alert-dialog-description">
            <Box className="d-flex justify-content-center">
              <Box className="add-expenses">
                <Box className="add-expenses-head d-flex align-items-center justify-content-between">
                  <Typography> Add Expenses</Typography>
                  <Typography>
                    Client: <span>Jake Knap</span>
                  </Typography>
                </Box>
                <Box className="add-expenses-body">
                  <Box className="attach-document">
                    <h3>Attach Document</h3>
                    <Box className="attach-document-body d-flex align-items-center">
                      <Box className="d-flex align-items-center ">
                        <input
                          type="radio"
                          name="uploadfile"
                          id="attachupload"
                        ></input>
                        <img src={upload}></img>
                        <label for="attachupload">Upload</label>
                      </Box>
                      <Box className="d-flex align-items-center" sx={{ pl: 2 }}>
                        <input
                          type="radio"
                          name="uploadfile"
                          id="attachcapture"
                        ></input>
                        <img src={camera}></img>
                        <label for="attachcapture">Capture</label>
                      </Box>
                    </Box>
                    <Box>
                      <Form className="d-flex align-items-center attach-document-browse w-100 justify-content-between">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          className="attach-file-input"
                          type="file"
                        ></TextField>
                        <Button>Upload</Button>
                      </Form>
                    </Box>
                  </Box>

                  <Box className="agency-btn-section add-expenses-btn">
                    <Link href="javascript:;" onClick={handleCloseAttach}>
                      Cancel
                    </Link>
                    <Link
                      href="javascript:;"
                      to="/timesheet"
                      onClick={handleCloseAttach}
                    >
                      Save
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="time-sheet-modal"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="modal-content"
          >
            <Box className="map-modal bg-light">
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe
                    width="100%"
                    height="323px"
                    className="map-iframe"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=Sydney&t=&z=10&ie=UTF8&iwloc=&output=embed"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                  <Box className="d-flex align-items-center justify-content-between map-modal-footbar">
                    <Typography className="distance-txt" sx={{ pb: 0 }}>
                      Distance: 45 KM
                    </Typography>
                    <Button onClick={handleClose}>Go Back</Button>
                  </Box>
                </div>
              </div>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
