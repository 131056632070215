import React from "react";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import "./home-ui.css";

const Discoverslider = () => {
  return (
    <Box>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={4}>

          <Box className="discover-item  srvc_card wow fadeInUp">
            <Link to="/blogs">
              <Box className="srvc_animtext">
                <Box className="animtext"><span title="Blogs">Blogs</span></Box>
              </Box>
              <Box className="srvc_title">
                <span>Blogs</span>
                <i className="arrow">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.665 0L5.49029 1.175L10.1391 5.83333H0V7.5H10.1391L5.49029 12.1583L6.665 13.3333L13.33 6.66667L6.665 0Z" />
                  </svg>
                </i>
              </Box>
            </Link>
          </Box>
          {/* <Box className="discover-item position-relative">
            <Box className="animateText-wrapper">
            <span className="animateText" title="blogs"> blogs</span>
            </Box>
            <Box className="d-flex align-items-center justify-content-between discover-button-wrapper">
              <Typography>Blogs</Typography>
              <Link to="#">
                <EastIcon />
              </Link>
            </Box>
          </Box> */}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box className="discover-item  srvc_card wow fadeInUp">
            <Link to="/guides">
              <Box className="srvc_animtext">
                <Box className="animtext"><span title="Guides">Guides</span></Box>
              </Box>
              <Box className="srvc_title">
                <span>Guides</span>
                <i className="arrow">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.665 0L5.49029 1.175L10.1391 5.83333H0V7.5H10.1391L5.49029 12.1583L6.665 13.3333L13.33 6.66667L6.665 0Z" />
                  </svg>
                </i>
              </Box>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box className="discover-item  srvc_card wow fadeInUp">
            <Link to="/help-center">
              <Box className="srvc_animtext">
                <Box className="animtext"><span title="Help Center">Help Center</span></Box>
              </Box>
              <Box className="srvc_title">
                <span>Help Center</span>
                <i className="arrow">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.665 0L5.49029 1.175L10.1391 5.83333H0V7.5H10.1391L5.49029 12.1583L6.665 13.3333L13.33 6.66667L6.665 0Z" />
                  </svg>
                </i>
              </Box>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Discoverslider;
