// Participants

import React from "react";
import ReactPlayer from 'react-player'
import ScImg from '../../../src/assets/images/SC.png'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const Homevideo = () => {

    const handleDuration = (duration) => {
        console.log('Video duration:', duration);
    };

    return (
        <div className="videoPlayer">
            <ReactPlayer
                url="https://youtu.be/R8h-gCyXQmU?si=AvqiO-FzvV0v7acQ"
                width="100%"
                height="650px"
                playing
                // onDuration="0.1"
                onDuration={handleDuration}  // Passing a function instead of a string
                playIcon={<button className="video-player-button"><PlayArrowIcon /></button>}
                light={ScImg}
            />
        </div>
    );
};

export default Homevideo;
