import React, { useState } from "react";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import "../../assets/css/dashboard.css";
import "../../assets/css/Appointment.css";
import Grid from "@mui/material/Grid";
// import axios from "axios";
import axios from "../../api/axios";
import RequestStatus from "./RequestStatus";
import TopFilter from "./TopFilter";
import CalenderData from "./CalenderData";
import ShiftTypeFilter from "../Explore-Market/View-Details/ShiftTypeFilter";
import WeeklyTable from "../availability/WeeklyTable";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useEffect } from "react";
import { FieldsListLoading } from "../../pages/components/loading/FieldsListLoading";
import {
  getAllLocation,
  getAllCalendarAvailability,
  updateRequestDetails,
  getDetailsBySendTo,
  addSendRequest,
  getsendRequestDetails,
  getAllRecievedRequestBySendByHC,
  limit,
  resendNotification,
} from "../../api";
import FilterStore from "../availability/store/FilterStore";
import SendIcon from "@mui/icons-material/Send";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Link } from "react-router-dom";
import appointmentRequestPending from "../../assets/images/appointmentRequestPending.svg";
import Avatar from "@mui/material/Avatar";
import { Button } from "@mui/material";

function createData(name, sun, mon, tue, wed, thu, fri, sat) {
  return { name, sun, mon, tue, wed, thu, fri, sat };
}
let userData = JSON.parse(localStorage.getItem("userData"));
let userId = userData?._id;

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function AvailabilityCalender(props) {
  const sendRequestId = props?.props?.sendRequestId;

  //this code is added beacuse appointment calendar and market place calendar same component use by krishna so unwatnted filters also showing in marketplace
  let isRenderFromMarketPlace = false;
  if (props?.props?.isRenderFromMarketPlace) {
    isRenderFromMarketPlace = true;
  }

  const [events, setEventData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [sentRequestDetails, setSentRequestDetails] = useState([]);
  const [access, setAccess] = useState(false);
  const [requestSent, setRequestSent] = useState([]);
  const [disabled, setDisable] = useState(false);
  const {
    resetFields,
    setField,
    selectedCity,
    ApType2,
    month,
    startDate1,
    endDate1,
    slotType,
    searchTerm,
    selectedShift,
    requestStatus,
    cityList,
    showhide,
  } = FilterStore();

  const ref = React.createRef();

  let exploreMarketData = JSON.parse(localStorage.getItem("exploreMarket"));
  let hcData = JSON.parse(localStorage.getItem("exploreMarket"));

  //this code is commented beacuse the HC we are viewing that hc not getting from this hcData
  //let hcId = hcData?.hcId;

  let hcId = props?.props?.hcId;
  console.log("propspropsprops", props);
  let role = "healthCarer";

  //console.log("selectedShift", selectedShift);

  useEffect(() => {
    if (
      startDate1 !== null &&
      startDate1 !== undefined &&
      endDate1 !== null &&
      endDate1 !== undefined
    ) {
      HcAvailabilityList();
    }
  }, [
    requestStatus,
    endDate1,
    startDate1,
    selectedStatus,
    ApType2,
    selectedCity,
    searchTerm,
    selectedShift,
  ]);

  useEffect(() => {
    const { startDate, endDate } = getDateRange(slotType, month);
    if (startDate !== null && endDate !== null) {
      setField("startDate1", startDate);
      setField("endDate1", endDate);
    }
  }, [slotType, month]);

  const HcAvailabilityList = () => {
    setIsLoading(true);
    axios
      .get(
        `${getAllCalendarAvailability}?start=${startDate1}&requestStatus=${requestStatus}&role=${role}&userId=${hcId}&end=${endDate1}&shiftTypeId=${selectedShift}&searchTerm=${searchTerm}&city=${selectedCity}`
      )
      .then((res) => {
        setEventData(res?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error in axios request:", error);
        setIsLoading(false);
      });
  };

  const getLocation = () => {
    axios
      .get(getAllLocation)
      .then(async (res) => {
        const cityData = [];
        res.data.data.length > 0 &&
          res.data.data.map((val) => {
            if (
              val?.location !== undefined &&
              val?.location !== null &&
              val?.location !== ""
            )
              cityData.push(val?.location);
          });

        const uniqueCitiesSet = new Set(cityData);

        // Convert the Set back to an array
        const uniqueCitiesArray = [...uniqueCitiesSet];

        const idLabelFormatArray = uniqueCitiesArray.map((city, index) => ({
          id: city,
          label: city,
        }));

        setField("cityList", idLabelFormatArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getLocation();
    setField("slotType", "Month");
  }, []);

  console.log("check month of AvailabilityCalendar", month);
  console.log("check startDate1", startDate1);
  console.log("check endDate1", endDate1);

  //get date range
  // const getDateRange = (rangeType, monthName) => {
  //   const currentDate = new Date();
  //   let startDate, endDate;

  //   switch (rangeType) {
  //     // case "Today":
  //     //   startDate = new Date(
  //     //     currentDate.getFullYear(),
  //     //     currentDate.getMonth(),
  //     //     currentDate.getDate()
  //     //   );
  //     //   endDate = new Date(
  //     //     currentDate.getFullYear(),
  //     //     currentDate.getMonth(),
  //     //     currentDate.getDate()
  //     //   );
  //     //   break;
  //     // case "Week":
  //     //   startDate = new Date(
  //     //     currentDate.getFullYear(),
  //     //     currentDate.getMonth(),
  //     //     currentDate.getDate() - currentDate.getDay() + 1
  //     //   );
  //     //   endDate = new Date(
  //     //     currentDate.getFullYear(),
  //     //     currentDate.getMonth(),
  //     //     currentDate.getDate() - currentDate.getDay() + 7
  //     //   );
  //     //   break;

  //     // case "Fortnightly":
  //     //   startDate = new Date(
  //     //     currentDate.getFullYear(),
  //     //     currentDate.getMonth(),
  //     //     currentDate.getDate() - currentDate.getDay() + 1
  //     //   );
  //     //   endDate = new Date(
  //     //     currentDate.getFullYear(),
  //     //     currentDate.getMonth(),
  //     //     currentDate.getDate() - currentDate.getDay() + 14
  //     //   );
  //     //   break;

  //     case "Month":
  //       //const monthIndex = getMonthIndexFromName(monthName);
  //       const monthIndex = new Date().getMonth(); // Get current month index

  //       startDate = new Date(currentDate.getFullYear(), monthIndex, 1);
  //       endDate = new Date(currentDate.getFullYear(), monthIndex + 1, 0);
  //       console.log("chef startDate", startDate);
  //       console.log("chef endDate", endDate);

  //       break;

  //     default:
  //       startDate = null;
  //       endDate = null;
  //   }

  //   return { startDate, endDate };
  // };

  useEffect(() => {
    const { startDate, endDate } = getDateRange("Month", month);
    console.log("monthmonthmonthmonth", month);
    console.log("sdfefdfdferferftergregt", startDate, endDate, month);
    if (startDate !== null && endDate !== null) {
      setField("startDate1", startDate);
      setField("endDate1", endDate);
    }
  }, [slotType, month]);

  useEffect(() => {
    const currentDate = new Date();
    const monthName = currentDate.toLocaleString("en-US", { month: "long" });
    const monthIndex = getMonthIndexFromName(monthName);
    const startDate = new Date(currentDate.getFullYear(), monthIndex, 1);
    const endDate = new Date(currentDate.getFullYear(), monthIndex + 1, 0);
    setField("startDate1", startDate);
    setField("endDate1", endDate);
  }, []);

  //get date range
  const getDateRange = (rangeType, monthName) => {
    const currentDate = new Date();
    let startDate, endDate;
    switch (rangeType) {
      case "Today":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        break;
      case "Week":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay() + 1
        );
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay() + 7
        );
        break;

      case "Fortnightly":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay() + 1
        );
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay() + 14
        );
        break;

      case "Month":
        const monthIndex = getMonthIndexFromName(monthName);
        startDate = new Date(currentDate.getFullYear(), monthIndex, 1);
        endDate = new Date(currentDate.getFullYear(), monthIndex + 1, 0);
        break;

      default:
        startDate = null;
        endDate = null;
    }

    return { startDate, endDate };
  };

  useEffect(() => {
    if (
      userData !== null &&
      userData !== undefined &&
      hcId !== undefined &&
      hcId !== null
    ) {
      getSendRequestDetailsFunction();
    }
  }, []);

  const getSendRequestDetailsFunction = async () => {
    try {
      if (
        userData !== null &&
        userData !== undefined &&
        hcId !== undefined &&
        hcId !== null
      ) {
        const res = await axios.get(
          `${getAllRecievedRequestBySendByHC}/${hcId}?searchTerm=${searchTerm}&page=1&items_per_page=${limit}`
        );
        let dataval = res?.data?.data?.docs;
        setSentRequestDetails(dataval);
        // documentAccessStatus
        setAccess(dataval?.documentRequest);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userData !== undefined && userData !== null) {
      getSendRequestDetails();
    }
  }, []);

  const getSendRequestDetails = async () => {
    try {
      if (userData !== null && userData !== undefined) {
        const res = await axios.get(`${getsendRequestDetails}/${userData._id}`);
        const sendToList = res?.data?.data.map((item) => item.sendTo);
        setRequestSent(sendToList);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getMonthIndexFromName = (monthName) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames.findIndex(
      (name) => name.toLowerCase() === monthName.toLowerCase()
    );
  };

  const goToNextDay = async () => {
    let calendarApi = ref.current.getApi();
    calendarApi.next();
    setField(
      "startDate1",
      calendarApi.currentData.dateProfile.currentRange.start
    );
    setField("endDate1", calendarApi.currentData.dateProfile.currentRange.end);
  };

  const goToPreviousDay = async () => {
    let calendarApi = ref.current.getApi();
    calendarApi.prev();
    setField(
      "startDate1",
      calendarApi.currentData.dateProfile.currentRange.start
    );
    setField("endDate1", calendarApi.currentData.dateProfile.currentRange.end);
  };

  const goToDay = async () => {
    let calendarApi = ref.current.getApi();
    calendarApi.today();
    setField(
      "startDate1",
      calendarApi.currentData.dateProfile.currentRange.start
    );
    setField("endDate1", calendarApi.currentData.dateProfile.currentRange.end);
  };

  useEffect(() => {
    if (startDate1 && ref.current) {
      const calendarApi = ref.current.getApi();
      calendarApi.gotoDate(new Date(startDate1)); // Update to the new start date
    }
  }, [startDate1]);

  const searchItems = (searchValue) => {
    setField("searchTerm", searchValue);
  };

  const getSendRequestDetailFunction = async () => {
    try {
      if (
        userData !== null &&
        userData !== undefined &&
        hcId !== undefined &&
        hcId !== null
      ) {
        const res = await axios.get(
          `${getDetailsBySendTo}/${hcId}/${userData._id}`
        );
        let dataval = res?.data?.data;
        setSentRequestDetails(dataval);
        // documentAccessStatus
        setAccess(dataval?.documentRequest);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    const {
      startTime,
      endTime,
      date,
      _id,
      slotId,
      requestStatus,
      shiftName,
      sendBy,
    } = event.extendedProps;

    const handleSendIconClick = (disabled) => {
      console.log("disableddisabled", disabled);
      if (disabled == false) {
        setDisable(true);
        // Create an object with the data you want to send
        const sendData = {
          availabilityData: {
            startTime: startTime,
            endTime: endTime,
            date: date,
            shiftType: shiftName,
            slotId: slotId,
            sendBy: userData?._id,
            sendTo: hcId,
          },
        };

        if (sendRequestId !== undefined && sendRequestId !== null) {
          axios
            .put(`${updateRequestDetails}/${sendRequestId}`, sendData)
            .then((res) => {
              if (res.data.success === true) {
                toast.success(res.data.message);
                HcAvailabilityList();
                getSendRequestDetailsFunction();
                setDisable(false);
              } else {
                setDisable(false);
                toast.error(res.data.message);
              }
            })
            .catch((error) => {
              setDisable(false);
              toast.error("Something went wrong");
            });
        } else {
          let payload = {
            sendBy: userData?._id,
            sendTo: hcId,
            createdBy: userData?._id,
            updatedBy: userData?._id,
            availabilityData: sendData.availabilityData,
          };

          axios
            .post(`${addSendRequest}`, payload)
            .then((res) => {
              if (res?.data?.success) {
                toast.success(res.data.message);
                setDisable(false);
              } else {
                toast.error(res.data.message);
                setDisable(false);
              }
              getSendRequestDetailsFunction();
              HcAvailabilityList();
            })
            .catch((error) => {
              toast.error("Something went wrong");
            });
        }
      }
    };

    const refereshButton = () => {
      axios
        .get(
          `${resendNotification}?id=${hcId}&startTime=${startTime}&endTime=${endTime}&date=${date}&shiftName=${shiftName}`
        )
        .then((res) => {
          toast.success(res.data.message);
          setDisable(false);
          HcAvailabilityList();
          getSendRequestDetailsFunction();
        })
        .catch((error) => {
          toast.error("Something went wrong");
          setDisable(false);
        });
    };

    let calendarCellClassName;
    if (requestStatus === "Pending") {
      calendarCellClassName = "view-details-status-pending-cl";
    } else if (requestStatus === "Appointed") {
      calendarCellClassName = "view-details-status-appointed-cl";
    } else if (requestStatus === "Cancelled") {
      calendarCellClassName = "view-details-status-cancelled-cl";
    } else if (requestStatus === "Accepted") {
      calendarCellClassName = "view-details-status-accepted-cl";
    } else if (requestStatus === "Rejected") {
      calendarCellClassName = "view-details-status-rejected-cl";
    } else {
      calendarCellClassName = "view-details-calender-nofield-cl";
    }

    const isVisible = (date, startTime) => {
      const currentDate = new Date();
      const targetDate = new Date(date);

      if (currentDate.toDateString() === targetDate.toDateString()) {
        const [targetHour, targetMinute] = startTime.split(":").map(Number);
        const targetTime = new Date(
          targetDate.setHours(targetHour, targetMinute)
        );
        return currentDate <= targetTime;
      }

      return currentDate <= targetDate;
    };

    return (
      <>
        <Box
          className={`view-details-calender-main position-relative view-details-calender-field-cl ${calendarCellClassName}`}
        >
          <Box>
            <CalenderData eventInfo={eventInfo} />
          </Box>

          {/* <Box className={`clientView-details-calender-iconField`}> */}

          <Box className="client-avil-sent-icon">
            {requestStatus === "Appointed" ? (
              <div style={{ marginLeft: "-44px" }} className="view-booked-text">
                Booked
              </div>
            ) : requestStatus == "Accepted" ? (
              <div className="" onClick={refereshButton}>
                {sendBy && sendBy == userData?._id ? (
                  <>
                    <RefreshIcon />
                  </>
                ) : (
                  <></>
                )}{" "}
              </div>
            ) : requestStatus !== "Pending" ? (
              isVisible(date, startTime) ? (
                userData?.role == "client" ? (
                  <div
                    className=""
                    onClick={() => {
                      handleSendIconClick(disabled);
                    }}
                  >
                    <SendIcon fontSize="small" />
                  </div>
                ) : (
                  <Link to="/existing-user" className="explore-view-link">
                    <SendIcon fontSize="small" />
                  </Link>
                )
              ) : null // Hide the SendIcon if the condition is not met
            ) : (
              <div className="" onClick={refereshButton}>
                {sendBy && sendBy == userData?._id ? (
                  <>
                    {/* <RefreshIcon /> */}
                    <Avatar
                      src={appointmentRequestPending}
                      className="view-icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "5px",
                      }}
                    ></Avatar>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}

            {/* sunny code is commented */}
            {/* {sentRequestDetails?.every((detail) => {
              console.log("detail?.availabilityData", detail?.availabilityData);
              const availabilityDate = detail?.availabilityData?.date;

              if (!availabilityDate) {
                // Handle the case where availabilityDate is not available
                return true;
              }

              const formattedDate = new Date(availabilityDate)
                .toISOString()
                .slice(0, 10);

              {
                console.log(
                  detail.availabilityData,
                  "detail.availabilityData.requestStatus"
                );
              }

              return (
                startTime !== detail.availabilityData.startTime ||
                endTime !== detail.availabilityData.endTime ||
                shiftName !== detail.availabilityData.shiftType ||
                date !== formattedDate // Use the formatted date for comparison
              );
            }) ? (
              <div className="" onClick={handleSendIconClick}>
                <SendIcon fontSize="small" />
              </div>
            ) : (
              <div className="view-booked-text">Booked</div>
            )} */}
          </Box>
        </Box>
      </>
    );
  };
  const getCurrentMonth = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[new Date().getMonth()];
  };

  const getCurrentMonthIndex = () => {
    return new Date().getMonth(); // Returns the current month index (0 for January, 1 for February, etc.)
  };
  console.log("check121 slotType", slotType);
  console.log("check121 showhide", showhide);
  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{ mb: 2 }}
        className="client-view-detail-wrapper"
      >
        <Grid item xs={6} md={6} sm={6}>
          <RequestStatus />
        </Grid>
      </Grid>

      <Grid container>
        <Grid xs={12} sm={12} md={12}>
          <Grid
            container
            className="justify-content-between align-items-center"
          >
            <Grid xs={6} sm={6} md={6} className="justify-content-start">
              {/* <ShiftTypeFilter /> */}
            </Grid>
            <Grid
              xs={6}
              sm={6}
              md={6}
              className="justify-content-end d-flex mb-4"
            >
              <TopFilter isRenderFromMarketPlace={isRenderFromMarketPlace} />
              <Button
                variant="contained"
                className="res-iconbtn ml-2"
                onClick={() => {
                  setField("startDate1", null);
                  setField("endDate1", null);
                  setField("slotType", "Month");
                  setField("showhide", "Month");
                  setField("month", getCurrentMonth());
                  setField("activeMonthIndex", getCurrentMonthIndex());
                  setField("isReloadMonth", true);
                }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>

          {slotType === "Week" || slotType === "Fortnightly" ? (
            <>
              <WeeklyTable />
            </>
          ) : (
            <div id="calender">
              <div className="demo-app-main">
                {isRenderFromMarketPlace ? (
                  <>
                    {" "}
                    <FullCalendar
                      plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                      ]}
                      // headerToolbar={{
                      //   left: "prev,next today",
                      //   center: "title",
                      //   right: "dayGridMonth,timeGridWeek,timeGridDay",
                      // }}
                      ref={ref}
                      initialView="dayGridMonth"
                      weekends={true}
                      // initialDate={
                      //   startDate1 === null ||
                      //   startDate1 === "" ||
                      //   startDate1 === undefined
                      //     ? new Date()
                      //     : new Date(startDate1)
                      // }
                      initialDate={new Date()} // Always set to the current date
                      events={events}
                      dayMaxEvents={1}
                      customButtons={{
                        prev: { click: () => goToPreviousDay() },
                        next: { click: () => goToNextDay() },
                        today: { text: "today", click: () => goToDay() },
                      }}
                      eventContent={renderEventContent} // custom render function
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <FullCalendar
                      plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                      ]}
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                      }}
                      ref={ref}
                      initialView="dayGridMonth"
                      weekends={true}
                      initialDate={
                        startDate1 === null ||
                        startDate1 === "" ||
                        startDate1 === undefined
                          ? new Date()
                          : new Date(startDate1)
                      }
                      events={events}
                      dayMaxEvents={1}
                      customButtons={{
                        prev: { click: () => goToPreviousDay() },
                        next: { click: () => goToNextDay() },
                        today: { text: "today", click: () => goToDay() },
                      }}
                      eventContent={renderEventContent} // custom render function
                    />
                  </>
                )}
              </div>

              {isLoading && <FieldsListLoading />}
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
}
