import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import VideoPlayer from "./Home-tab-video";
import ParticipantsPlayer from "./Participants";
import SCPlayer from "./Sc";
import homeTabVideo from "../../assets/images/home-tab-videoimg.png";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box id="homeTab" sx={{ width: "100%" }} className="position-relative">
      <Grid container>
        <Grid item xs={12} sm={10} md={10} className="m-auto">
          <Box className="home-tab-wrapper">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                className="home-tabs-item"
                label="For Health Carers"
                {...a11yProps(0)}
              />
              <Tab
                className="home-tabs-item"
                label="For Participants"
                {...a11yProps(1)}
              />
              <Tab
                className="home-tabs-item"
                label="For Support Coordinators"
                {...a11yProps(2)}
              />
              <Tab
                className="home-tabs-item"
                label="For Providers"
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
        </Grid>
      </Grid>
      <Box className="space-pt-80 tabVideo-tabpennel">
        <CustomTabPanel value={value} index={0}>
          <Box className="home-tab-videoWrapper">
            <Box className="home-tab-video">
              <VideoPlayer />
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className="home-tab-videoWrapper">
            <Box className="home-tab-video">
              <ParticipantsPlayer />
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box className="home-tab-videoWrapper">
            <Box className="home-tab-video">
              <SCPlayer />
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Box className="home-tab-videoWrapper">
            <Box className="home-tab-video">
              <VideoPlayer />
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
