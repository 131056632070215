import React from "react";
import { useState } from "react";
import { Typography } from "@mui/material";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
// import { INITIAL_EVENTS, createEventId } from './event-utils'
import { Box } from "@mui/material";
import appointPopoverImg from "../../assets/images/client-profile.jpg";
import HomeIcon from "../../assets/images/home-icon.svg";
import callIcon from "../../assets/images/table-call.png";
import locationIcon from "../../assets/images/location-icon.svg";
import popImg1 from "../../assets/images/team2.png";
import popImg2 from "../../assets/images/team3.png";
import EyeIcon from "../../assets/images/eye-icon.svg";
import Dropdown from "react-bootstrap/Dropdown";
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import popArrow from "../../assets/images/popover-arrow.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CallIcon from "../../assets/images/table-call.png";

export default function CalenderData() {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
      <div className="d-flex align-items-start calender-view-box justify-content-between">
        <Dropdown drop="start" autoClose="outside">
          <Dropdown.Toggle
            id="dropdown-autoclose-outside"
            className="calender-view"
          >
            <div
              onClick={toggleClass}
              className="calender-client-cell calender-view-success "
            >
              <div>
                <Typography>
                  <svg
                    width="16"
                    className="calender-view-success-icon calender-view-icon"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6764 13.9218V8.07336C14.6764 7.70766 14.4678 7.24384 14.166 7.03718L8.47156 3.11393C8.16982 2.90723 7.68016 2.90894 7.37954 3.1173L2.04631 6.81336C1.74595 7.02172 1.49609 7.48695 1.49609 7.85265V13.9217C1.49609 14.2874 1.80484 14.6511 2.17054 14.6511H14.0878C14.4534 14.6512 14.6764 14.2874 14.6764 13.9218ZM8.17152 13.0771L8.12213 13.1057C5.04805 11.8648 3.86351 8.99851 5.21744 7.75731C6.57107 6.5164 8.12213 8.20125 8.12213 8.20125H8.13337C8.13337 8.20125 9.68446 6.5164 11.0384 7.75731C12.392 8.99851 11.2456 11.8362 8.17152 13.0771Z"
                      fill=""
                    />
                    <path
                      d="M15.7104 5.61759L8.47165 0.671357C8.16962 0.464959 7.68307 0.468889 7.38441 0.680037L0.279942 5.70088C-0.0184519 5.91177 -0.0896851 6.32512 0.121493 6.62348L0.138883 6.64817C0.350061 6.94683 0.76312 7.0178 1.06178 6.80662L7.36086 2.35477C7.65952 2.14359 8.14692 2.13884 8.44977 2.34353L14.9493 6.74126C15.2522 6.94625 15.6639 6.86689 15.8686 6.56404L15.8857 6.53879C16.091 6.23649 16.0122 5.8237 15.7104 5.61759ZM12.4535 2.84243C12.749 3.05751 12.9936 2.92182 12.9936 2.55612V0.900746C12.9936 0.535342 12.6879 0.349121 12.3222 0.349121H11.2187C10.8531 0.349121 10.5567 0.565607 10.5567 0.900746C10.5567 1.23589 10.7964 1.65455 11.092 1.86936L12.4535 2.84243Z"
                      fill=""
                    />
                  </svg>
                  Klee Paul ***
                </Typography>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span className="d-flex align-items-center">
                  10:00 - 11:00 PM{" "}
                </span>
                <img src={CallIcon} className="calender-icon" />
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu   client-drop-menu">
            <Dropdown.Item className="table-edit-del w-100">
              <div className="calender-popover">
                <div className="w-100 calender-main">
                  <div className="d-flex align-content-center">
                    <img
                      className="appoint-popover-img"
                      src={appointPopoverImg}
                    />

                    <div className="appoint-popover-profile-text">
                      <Typography>Jake Knap</Typography>
                      <span>46 yr, Male</span>
                    </div>
                  </div>
                  <div className="d-flex align-content-center popover-date-time">
                    <Box className="table-icon">
                      <img src={HomeIcon} />
                      <Box className="table-icon-popover sub-popover d-flex align-items-center justify-content-center">
                        <img src={HomeIcon} className="sub-popover-img" />{" "}
                        <Typography>Homecare</Typography>
                        <ArrowDropDownIcon className="team-popover-arrow" />
                      </Box>
                    </Box>

                    <Box className="table-icon">
                      <img src={callIcon} />
                      <Box className="table-icon-popover sub-popover d-flex align-items-center justify-content-center">
                        <img src={callIcon} className="sub-popover-img" />{" "}
                        <Typography>On Call</Typography>
                        <ArrowDropDownIcon className="team-popover-arrow" />
                      </Box>
                    </Box>

                    <Typography>15-03-2022, </Typography>
                    <Typography>09:00 - 18:00</Typography>
                  </div>

                  <div className="popover-location d-flex align-items-center">
                    <img src={locationIcon} />
                    <Typography>Sydney</Typography>
                  </div>

                  <div className="popover-content d-flex align-items-center">
                    <Typography> Service:</Typography>
                    <span>Capacity Building</span>
                  </div>

                  <div className="popover-content d-flex align-content-center">
                    <Typography> Item:</Typography>
                    <div>
                      <span>
                        Assistance in support <br /> Independent living
                      </span>
                      <span className="popover-content-light">
                        {" "}
                        Weekdays - Daytime
                      </span>
                    </div>
                  </div>

                  <div className="popover-content d-flex align-content-center">
                    <Typography>Team:</Typography>
                    <div className="popover-team-img d-flex align-items-center">
                      <Box className="table-team popover-team-img-box d-flex align-items-center">
                        <img src={popImg1} className="teamImg1" />{" "}
                        <img src={popImg2} className="teamImg2" />
                        <Box className="team-popover team-popover1">
                          <Box className="team-popover-box  d-flex align-items-center justify-content-between">
                            <Box className="team-popover-img">
                              <img src={popImg1}></img>
                            </Box>
                            <Box className="team-popover-text">
                              <Typography>Dan Michel </Typography>
                              <span>Health Carer</span>
                            </Box>

                            <ArrowDropDownIcon className="team-popover-arrow" />
                          </Box>
                        </Box>
                        <Box className="team-popover team-popover2">
                          <Box className="team-popover-box  d-flex align-items-center justify-content-between">
                            <Box className="team-popover-img">
                              <img src={popImg2}></img>
                            </Box>
                            <Box className="team-popover-text">
                              <Typography>Dan Michel </Typography>
                              <span>Health Carer</span>
                            </Box>

                            <ArrowDropDownIcon className="team-popover-arrow" />
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </div>

                  <div className="popover-buttons">
                    <a href="javascript:;" className="started-popover-button">
                      Started
                    </a>
                    <a href="javascript:;" className="details-popover-button">
                      {" "}
                      <img src={EyeIcon} /> View Details
                    </a>
                  </div>

                  <img src={popArrow} className="popover-arrow" />
                </div>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}
