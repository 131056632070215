import React from "react";
import { useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import locationIcon from "../../../assets/images/location-icon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import popArrow from "../../../assets/images/popover-arrow.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import moment from "moment";
import noImage from "../../../assets/images/noImage.png";
import { Link, useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "../../../assets/css/dashboard.css";
import "../../calendar-view/appointment.css";
import localstorageData from "../../../helpers/localstorageData";
import calenderIcon from "../../../assets/images/calenderIcon.svg";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import FilterStore from "../../availability/store/FilterStore";

export default function CalenderData(props) {
  const user = localstorageData();
  const { resetFields, setField, showMenuItem } = FilterStore();

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const title = props?.eventInfo?.event?.title;

  const formattedDate = moment(
    props?.eventInfo?.event?.extendedProps?.date
  ).format("DD-MM-YYYY");

  function getAge(dateString) {
    if (dateString === null || dateString === undefined) {
      return null;
    }
    let today = new Date();
    let birthDate = new Date(dateString);

    if (birthDate == "Invalid Date") {
      return null;
    }
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age >= 0 ? age : null;
  }

  // const [showMenuItem, setShowMenuItem] = useState({});
  const navigate = useNavigate();

  const handleToggleMenuItem = (e, id) => {
    for (const key in showMenuItem) {
      if (key !== id) {
        showMenuItem[key] = false;
      }
    }
    setField("showMenuItem", {
      ...showMenuItem,
      [id]: !showMenuItem[id],
    });
  };

  const addAppointment = (e, event) => {
    e.preventDefault();
    const state = {
      healthCarerId: event.extendedProps.healthCarerId,
      date: event.extendedProps.date,
      userStatus: event.extendedProps.status,
      startTime: event.extendedProps.startTime,
      endTime: event.extendedProps.endTime,
      back: "Add New",
    };
    navigate("/super-admin/add-appointment", { state });
  };

  function isDateInPast(date) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00)

    const selectedDate = new Date(date);
    selectedDate.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00)

    return selectedDate < currentDate;
  }

  return (
    <>
      <div
        id="#health-carer-calender"
        className="d-flex align-items-start calender-view-box justify-content-between"
      >
        <Dropdown
          // className="list-appoinetment-wrapper"
          autoClose="outside"
          show={showMenuItem[props?.eventInfo?.event?.extendedProps?.slotId]}
        >
          <Dropdown.Toggle
            id="dropdown-autoclose-outside"
            className="calender-view"
            onClick={(e) =>
              handleToggleMenuItem(
                e,
                props?.eventInfo?.event?.extendedProps?.slotId
              )
            }
          >
            <div
              onClick={toggleClass}
              className={`calender-client-cell calendar-top-title`}
            >
              <div>
                <Typography className="d-none">
                  &nbsp;
                  {user?.role !== "healthCarer"
                    ? title.substring(0, 8)
                    : props?.eventInfo?.event?.extendedProps?.shiftName}
                </Typography>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span className="d-flex align-items-center">
                  {props?.eventInfo?.event?.extendedProps?.shiftName ===
                  "Sleepover"
                    ? props?.eventInfo?.event?.extendedProps?.shiftName
                    : props?.eventInfo?.event?.extendedProps?.startTime +
                      "-" +
                      props?.eventInfo?.event?.extendedProps?.endTime}
                </span>
                &nbsp;
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu client-drop-menu">
            <Dropdown.Item className="table-edit-del w-100">
              {showMenuItem[props?.eventInfo?.event?.extendedProps?.slotId] && (
                <div
                  className="calender-popover"
                  // health-carer-popover
                >
                  <div className="w-100 calender-main">
                    <div className="d-flex align-content-center">
                      <img
                        className="health-carer-popover-img"
                        src={
                          props?.eventInfo?.event?.extendedProps?.image !==
                            undefined &&
                          props?.eventInfo?.event?.extendedProps?.image !== null
                            ? props?.eventInfo?.event?.extendedProps?.image
                                ?.location
                            : noImage
                        }
                      />

                      <div className="appoint-popover-profile-text">
                        <Typography>
                          {props?.eventInfo?.event?.extendedProps?.fullName}
                        </Typography>
                        <span>Client</span>
                      </div>
                    </div>
                    <div className="popover-content d-flex align-content-center">
                      <Typography> Employee ID:</Typography>
                      <span>
                        {props?.eventInfo?.event?.extendedProps?.employeeId}
                      </span>
                    </div>

                    <div className="popover-location d-flex align-items-center">
                      <img src={locationIcon} />
                      <Typography>
                        {props?.eventInfo?.event?.extendedProps?.address}
                      </Typography>
                    </div>
                    <div className="popover-content d-flex align-content-center">
                      <Typography> Shift Name:</Typography>
                      <div>
                        <span>
                          {props?.eventInfo?.event?.extendedProps?.shiftName}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-content-center popover-date-time">
                      <img src={calenderIcon} />
                      <Typography>{formattedDate}, </Typography>
                      <Typography>
                        {props?.eventInfo?.event?.extendedProps?.startTime} -{" "}
                        {props?.eventInfo?.event?.extendedProps?.endTime}
                      </Typography>
                    </div>
                    {props?.eventInfo?.event?.extendedProps?.item ==
                      "Transport" && (
                      <div className="popover-content d-flex align-content-center">
                        <Typography> Total Distance:</Typography>
                        <div>
                          <span>
                            {
                              props?.eventInfo?.event?.extendedProps?.kilometer
                                ?.totalKm
                            }{" "}
                            KM
                          </span>
                        </div>
                      </div>
                    )}
                    {user?.role === "superAdmin" ? (
                      <Box className="client-btn health-carer-popover-button text-end">
                        <Link
                          className="add-btn-form"
                          onClick={(e) =>
                            addAppointment(e, props?.eventInfo?.event)
                          }
                        >
                          <AddIcon />
                          Add Appointment
                        </Link>
                      </Box>
                    ) : (
                      ""
                    )}
                    <img
                      src={popArrow}
                      className="health-carer-popover-arrow"
                    />
                    <img
                      src={popArrow}
                      //  className="popover-arrow"
                      className="hc-popover-arrow"
                    />
                  </div>
                </div>
              )}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}
