import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../../Website/Web-Header";
import WebFooter from "../../Website/Web-footer";
import { Link } from "react-router-dom";
import BookDemo from "../../Website/HomeUI/BookDemo";
import EmailIcon from "../../assets/images/email-icon.png";
import MapIcon from "../../assets/images/map-icon.png";
import CallIcon from "../../assets/images/call-icon.png";
import TextField from "@mui/material/TextField";
import contactImg from "../../assets/images/contact-map.png";
import { addContactForm, getWebsiteManagementListDetails } from "../../api";
import { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

export default function Contact() {
  const [disableButton, setDisableButton] = useState(false);
  const [listData, setListData] = useState([]);
  const phoneRegExp = /^04\d{8}$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .test("name", "Space is not accept", (value) => {
        return value && value.trim() !== "";
      })
      .required("Name is Required"),
    email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Invalid email format"
      )
      .required("Email is required"),
    phone: Yup.string()
    .matches(phoneRegExp, "Enter valid Mobile number. It must start with '04' and 10 digits")
    .required("Mobile number is required"),
    userType: Yup.string()
      .test("userType", "Space is not accept", (value) => {
        return value && value.trim() !== "";
      })
      .required("User Type is Required"),
    subject: Yup.string()
      .test("subject", "Space is not accept", (value) => {
        return value && value.trim() !== "";
      })
      .required("Subject is Required"),
    message: Yup.string()
      .test("message", "Space is not accept", (value) => {
        return value && value.trim() !== "";
      })
      .required("Message is Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      userType: "",
      subject: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      console.log("Submitting form with values:", values);
      axios
        .post(`${addContactForm}`, values)
        .then((res) => {
          if (res.data.success === false) {
            setDisableButton(false);
            toast.error(res.data.message);
          }

          if (res.data.success === true) {
            setDisableButton(true);
            toast.success(res.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    },
  });

  useEffect(() => {
    getaList();
  }, []);

  const getaList = async () => {
    axios
      .get(`${getWebsiteManagementListDetails}?page=1&items_per_page=10`)
      .then((res) => {
        setListData(res?.data?.data?.docs?.[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box id="Contact">
      <Box className="web-topBanner-bg  blog-main-banner">
        <WebHeader />

        <Box className="webContainer">
          <Box className="W-100 ">
            <Box className="home-content-main space-pb-200 space-lg">
              <Box className="text-center ">
                <h1>
                  We’d love to hear <br /> from you
                </h1>
                <Typography>
                  At Hubshift, we're committed to revolutionizing NDIS care
                  across Australia. Whether you're <br /> an NDIS provider,
                  client, support coordinator, health care, or someone looking
                  to learn
                  <br />
                  more about our innovative CRM platform, we're here to help. 
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="blurBg blurRed"> </Box>
        {/* <Box className="blurBg blurPurple"> </Box> */}
        <Box className="blurBg blurYellow"></Box>
      </Box>

      <Box className="webContainer">
        <Box className="contact-address-box">
          <Grid container spacing={2}>
            <Grid xs={12} sm={4} md={4}>
              <Box className="text-center">
                <Box className="address-icons">
                  <img src={EmailIcon} />
                </Box>
                <Box>
                  <h6>Email</h6>

                  <Typography>Our support team is here to help</Typography>

                  <Link to="mailto:Info@Hubshift.au?Subject=Hubshift%20Help%20Support">{listData?.email}</Link>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={4} md={4}>
              <Box className="text-center">
                <Box className="address-icons">
                  <img src={MapIcon} />
                </Box>
                <Box>
                  <h6>Office</h6>

                  <Typography>Come say hello to our office HQ</Typography>

                  <h6>{listData?.address}</h6>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={4} md={4}>
              <Box className="text-center">
                <Box className="address-icons">
                  <img src={CallIcon} />
                </Box>
                <Box>
                  <h6>Phone</h6>

                  <Typography>Mon-Fri from 8 am to 5 pm</Typography>

                  <Link to="tel:1800774954">{listData?.phone}</Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* contact form start here */}

      <Box className="webContainer space-pb-80">
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box className="contact-form">
            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="get-touch-form">
                <h2>Get In Touch</h2>
                <Typography> Please fill out this form.</Typography>

                <Box className="contact-input-fields space-pt-40">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} className="pt-0 ">
                      <TextField
                        sx={{ mt: 2, mb: 2, width: "100%" }}
                        size="small"
                        className="form-input inputposition inputhealper"
                        required
                        id="outlined-required"
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              color: "red",
                              opacity: 1, // otherwise firefox shows a lighter color
                            },
                          },
                        }}
                        type="text"
                        name="name"
                        placeholder="Name"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        value={formik?.values?.name}
                        error={
                          formik?.touched?.name && Boolean(formik?.errors?.name)
                        }
                        helperText={
                          formik?.touched?.name && formik?.errors?.name
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className="pt-0 ">
                      <TextField
                        sx={{ mt: 2, mb: 2, width: "100%" }}
                        size="small"
                        className="form-input inputposition inputhealper"
                        required
                        id="outlined-required"
                        type="text"
                        name="email"
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              color: "red",
                              opacity: 1, // otherwise firefox shows a lighter color
                            },
                          },
                        }}
                        placeholder="Email"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        value={formik?.values?.email}
                        error={
                          formik?.touched?.email &&
                          Boolean(formik?.errors?.email)
                        }
                        helperText={
                          formik?.touched?.email && formik?.errors?.email
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} className="pt-0 ">
                      <TextField
                        sx={{ mt: 2, mb: 2, width: "100%" }}
                        size="small"
                        className="form-input inputposition inputhealper"
                        required
                        id="outlined-required"
                        type="text"
                        name="phone"
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              color: "red",
                              opacity: 1, // otherwise firefox shows a lighter color
                            },
                          },
                        }}
                        placeholder="Phone"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        value={formik?.values?.phone}
                        error={
                          formik?.touched?.phone &&
                          Boolean(formik?.errors?.phone)
                        }
                        helperText={
                          formik?.touched?.phone && formik?.errors?.phone
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className="pt-0 ">
                      <TextField
                        sx={{ mt: 2, mb: 2, width: "100%" }}
                        size="small"
                        className="form-input inputposition inputhealper"
                        required
                        id="outlined-required"
                        type="text"
                        name="userType"
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              color: "red",
                              opacity: 1, // otherwise firefox shows a lighter color
                            },
                          },
                        }}
                        placeholder="I am a"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        value={formik?.values?.userType}
                        error={
                          formik?.touched?.userType &&
                          Boolean(formik?.errors?.userType)
                        }
                        helperText={
                          formik?.touched?.userType && formik?.errors?.userType
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className="pt-0 ">
                      <TextField
                        sx={{ mt: 2, mb: 2, width: "100%" }}
                        size="small"
                        className="form-input inputposition inputhealper"
                        required
                        id="outlined-required"
                        type="text"
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              color: "red",
                              opacity: 1, // otherwise firefox shows a lighter color
                            },
                          },
                        }}
                        name="subject"
                        placeholder="Subject (e.g. Enquiry about vacancy)"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        value={formik?.values?.subject}
                        error={
                          formik?.touched?.subject &&
                          Boolean(formik?.errors?.subject)
                        }
                        helperText={
                          formik?.touched?.subject && formik?.errors?.subject
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className="pt-0 ">
                      <TextField
                        sx={{ width: "100%" }}
                        multiline
                        className="w-100"
                        rows={6}
                        maxRows={4}
                        required
                        id="outlined-required"
                        type="text"
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              color: "red",
                              opacity: 1, // otherwise firefox shows a lighter color
                            },
                          },
                        }}
                        name="message"
                        placeholder="Leave us a message..."
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        value={formik?.values?.message}
                        error={
                          formik?.touched?.message &&
                          Boolean(formik?.errors?.message)
                        }
                        helperText={
                          formik?.touched?.message && formik?.errors?.message
                        }
                      />
                    </Grid>
                  </Grid>
                  {/* <Box className="space-pt-40">
                    <Link className="home-section-button web-primary-btnLarge cta1">
                      Book A Demo
                    </Link>
                  </Box> */}
                  <Box className="space-pt-40 contact-submitbtn">
                    <button
                      type="submit"
                      className="home-section-button web-primary-btnLarge "
                      disabled={disableButton}
                    >
                      {disableButton === true ? "Processing..." : "Submit"}
                    </button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="space-pl-40">
                <Box className="w-100">
                  <img src={contactImg} className="w-100" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>

      {/* book demo section  */}

      <Box className="space-m-80">
        <Box className="webContainer">
          <BookDemo />
        </Box>
      </Box>

      <WebFooter />
    </Box>
  );
}
