import { lazy } from "react";

const AllCommanRoutes = [
  // {
  //   path: "/home",
  //   component: lazy(() => import("../pages/Accountant/home")),
  // },

  {
    path: "/list-main-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/mainTypeSymptoms/list")),
  },

  {
    path: "/pay-run",
    //component: lazy(() => import("../pages/superAdmin/PayRun/Pay-Run")),
    component: lazy(() => import("../components/pay-run/PayRun")),
  },
  {
    path: "/list-payrun",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/payRun/payRun")
    ),
  },
  {
    path: "/ato-settings",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/atoSetting")
    ),
  },
  {
    path: "/payments",
    component: lazy(() => import("../pages/serviceProvider/Settings/payment")),
  },
  {
    path: "/settings-xero-myob-list",
    component: lazy(() => import("../pages/superAdmin/xero-myob/list")),
  },
  {
    path: "/funds-management",
    component: lazy(() =>
      import("../Dashboard/FundManagement/Fund-Management")
    ),
  },
  {
    path: "/list-superannuation-setting",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/superAnnuation/list")
    ),
  },
  {
    path: "/add-superannuation-setting",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/superAnnuation/edit")
    ),
  },

  {
    path: "/edit-superannuation-setting",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/superAnnuation/edit")
    ),
  },
  {
    path: "/financial-setting-list",
    component: lazy(() =>
      import(
        "../pages/serviceProvider/Settings/financialSettings/financialSettings"
      )
    ),
  },
  {
    path: "/add-main-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/mainTypeSymptoms/edit")),
  },
  {
    path: "/edit-main-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/mainTypeSymptoms/edit")),
  },
  {
    path: "/list-sub-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/subTypeSymptoms/list")),
  },
  {
    path: "/add-sub-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/subTypeSymptoms/edit")),
  },
  {
    path: "/edit-sub-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/subTypeSymptoms/edit")),
  },

  {
    path: "/list-medical-history",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },

  {
    path: "/settings",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/setting.js")
    ),
  },

  {
    path: "/list-medication-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },

  {
    path: "/list-observation",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },

  {
    path: "/list-prn-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },

  {
    path: "/medical-history-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medical-History")
    ),
  },
  {
    path: "/add-medical-history-dev",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Add-Medical-History"
      )
    ),
  },

  {
    path: "/edit-medical-history-dev",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Add-Medical-History"
      )
    ),
  },

  {
    path: "/view-medical-history-dev",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/View-Medical-History"
      )
    ),
  },

  {
    path: "/add-medication-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Add-Medication")
    ),
  },
  {
    path: "/edit-medication-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Add-Medication")
    ),
  },
  {
    path: "/view-medication-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/View-Medication")
    ),
  },
  {
    path: "/medication-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medication-chart")
    ),
  },

  {
    path: "/observation-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Observation-chart")
    ),
  },
  {
    path: "/prn-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medication-chart")
    ),
  },

  {
    path: "/medical-history",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management/Medical-History")
    ),
  },
  {
    path: "/add-medical-history",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management/Add-Medical-History")
    ),
  },
  {
    path: "/add-medication",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management/Add-Medication")
    ),
  },
  {
    path: "/edit-medication",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management/Edit-Medication")
    ),
  },
  {
    path: "/medication-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management/Medication-chart")
    ),
  },
  {
    path: "/prn-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management/PrnChart")
    ),
  },
  {
    path: "/timesheetadmin",
    component: lazy(() => import("../Dashboard/Timesheet/TimesheetAdmin")),
  },
  {
    path: "/timesheetview",
    component: lazy(() => import("../Dashboard/Timesheet/TimesheetView")),
  },
  {
    path: "/list-hundred-points",
    component: lazy(() => import("../pages/superAdmin/hundred-points/list")),
  },
  {
    path: "/edit-hundred-points",
    component: lazy(() => import("../pages/superAdmin/hundred-points/edit")),
  },
  {
    path: "/add-hundred-points",
    component: lazy(() => import("../pages/superAdmin/hundred-points/edit")),
  },
  {
    path: "/list-support-group",
    component: lazy(() => import("../pages/superAdmin/support-group/list")),
  },
  {
    path: "/edit-support-group",
    component: lazy(() => import("../pages/superAdmin/support-group/edit")),
  },
  {
    path: "/add-support-group",
    component: lazy(() => import("../pages/superAdmin/support-group/edit")),
  },

  {
    path: "/list-packages",
    component: lazy(() => import("../pages/superAdmin/packages/list")),
  },
  {
    path: "/edit-packages",
    component: lazy(() => import("../pages/superAdmin/packages/edit")),
  },
  {
    path: "/add-packages",
    component: lazy(() => import("../pages/superAdmin/packages/edit")),
  },

  {
    path: "/list-induction-category",
    component: lazy(() => import("../pages/superAdmin/inductionCategory/list")),
  },
  {
    path: "/edit-induction-category",
    component: lazy(() => import("../pages/superAdmin/inductionCategory/edit")),
  },
  {
    path: "/add-induction-category",
    component: lazy(() => import("../pages/superAdmin/inductionCategory/edit")),
  },

  {
    path: "/list-induction-heading-title",
    component: lazy(() => import("../pages/superAdmin/inductionHeading/list")),
  },
  {
    path: "/add-induction-heading",
    component: lazy(() => import("../pages/superAdmin/inductionHeading/edit")),
  },
  {
    path: "/edit-induction-heading-title",
    component: lazy(() => import("../pages/superAdmin/inductionCategory/edit")),
  },
  {
    path: "/add-induction-heading-title",
    component: lazy(() => import("../pages/superAdmin/inductionCategory/edit")),
  },

  {
    path: "/list-menu-masters",
    component: lazy(() => import("../pages/superAdmin/menuMaster/list")),
  },
  {
    path: "/smtp-setup",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/smtpSetup.js")
    ),
  },
  {
    path: "/edit-menu-masters",
    component: lazy(() => import("../pages/superAdmin/menuMaster/edit")),
  },
  {
    path: "/add-menu-masters",
    component: lazy(() => import("../pages/superAdmin/menuMaster/edit")),
  },

  {
    path: "/list-agencies",
    component: lazy(() => import("../pages/superAdmin/agencies/list")),
  },
  {
    path: "/edit-agencies",
    component: lazy(() => import("../pages/superAdmin/agencies/edit")),
  },
  {
    path: "/add-agencies",
    component: lazy(() => import("../pages/superAdmin/agencies/edit")),
  },

  {
    path: "/list-shifttype",
    component: lazy(() => import("../pages/superAdmin/shift-type/list")),
  },
  {
    path: "/edit-shifttype",
    component: lazy(() => import("../pages/superAdmin/shift-type/edit")),
  },
  {
    path: "/add-shifttype",
    component: lazy(() => import("../pages/superAdmin/shift-type/edit")),
  },

  {
    path: "/list-zone",
    component: lazy(() => import("../pages/superAdmin/zone/list")),
  },
  {
    path: "/edit-zone",
    component: lazy(() => import("../pages/superAdmin/zone/edit")),
  },
  {
    path: "/add-zone",
    component: lazy(() => import("../pages/superAdmin/zone/edit")),
  },

  {
    path: "/list-skills",
    component: lazy(() => import("../pages/superAdmin/skill/list")),
  },
  {
    path: "/edit-skills",
    component: lazy(() => import("../pages/superAdmin/skill/edit")),
  },
  {
    path: "/add-skills",
    component: lazy(() => import("../pages/superAdmin/skill/edit")),
  },
  {
    path: "/edit-meals",
    component: lazy(() => import("../pages/superAdmin/meals/edit")),
  },
  {
    path: "/add-meals",
    component: lazy(() => import("../pages/superAdmin/meals/edit")),
  },

  {
    path: "/list-cancellation-reason",
    component: lazy(() =>
      import("../pages/superAdmin/cancellationReason/list")
    ),
  },
  {
    path: "/edit-cancellation-reason",
    component: lazy(() =>
      import("../pages/superAdmin/cancellationReason/edit")
    ),
  },
  {
    path: "/add-cancellation-reason",
    component: lazy(() =>
      import("../pages/superAdmin/cancellationReason/edit")
    ),
  },

  {
    path: "/list-levels",
    component: lazy(() => import("../pages/superAdmin/level/list")),
  },
  {
    path: "/edit-levels",
    component: lazy(() => import("../pages/superAdmin/level/edit")),
  },
  {
    path: "/add-levels",
    component: lazy(() => import("../pages/superAdmin/level/edit")),
  },

  {
    path: "/list-job-titles",
    component: lazy(() => import("../pages/superAdmin/job-title/list")),
  },
  {
    path: "/edit-job-titles",
    component: lazy(() => import("../pages/superAdmin/job-title/edit")),
  },
  {
    path: "/add-job-titles",
    component: lazy(() => import("../pages/superAdmin/job-title/edit")),
  },

  {
    path: "/list-job-types",
    component: lazy(() => import("../pages/superAdmin/job-type/list")),
  },
  {
    path: "/edit-job-types",
    component: lazy(() => import("../pages/superAdmin/job-type/edit")),
  },
  {
    path: "/add-job-types",
    component: lazy(() => import("../pages/superAdmin/job-type/edit")),
  },

  {
    path: "/list-quarters",
    component: lazy(() => import("../pages/superAdmin/quarter/list")),
  },
  {
    path: "/edit-quarters",
    component: lazy(() => import("../pages/superAdmin/quarter/edit")),
  },
  {
    path: "/add-quarters",
    component: lazy(() => import("../pages/superAdmin/quarter/edit")),
  },

  {
    path: "/client-list",
    component: lazy(() => import("../pages/superAdmin/client-list/list")),
  },
  {
    path: "/edit-client",
    component: lazy(() => import("../pages/superAdmin/client-list/edit")),
  },
  {
    path: "/add-client",
    component: lazy(() => import("../pages/superAdmin/client-list/edit")),
  },
  {
    path: "/view-client",
    component: lazy(() => import("../pages/superAdmin/client-list/view")),
  },
  {
    path: "/view-client-details-old",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/view-details")
    ),
  },

  {
    path: "/list-health-carer",
    component: lazy(() => import("../pages/superAdmin/health-carer/list")),
  },
  {
    path: "/add-health-carer",
    component: lazy(() => import("../pages/superAdmin/health-carer/edit")),
  },
  {
    path: "/edit-health-carer",
    component: lazy(() => import("../pages/superAdmin/health-carer/edit")),
  },
  {
    path: "/health-carer-details",
    component: lazy(() => import("../pages/superAdmin/health-carer/details")),
  },

  {
    path: "/view-health-carer-details",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/view-details-hc")
    ),
  },
  {
    path: "/view-health-carer-details-SN",
    component: lazy(() =>
      import("../components/emavailability/ViewDetailHC.js")
    ),
  },
  {
    path: "/health-carer-view",
    component: lazy(() => import("../pages/superAdmin/health-carer/view")),
  },
  {
    path: "/list-health-carer/interview-history",
    component: lazy(() =>
      import(
        "../pages/superAdmin/health-carer/scheduleInterviewHistory/History"
      )
    ),
  },
  {
    path: "/swap-appointments",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/swap-appointment")
    ),
  },

  {
    path: "/list-health-carer-offboarding",
    component: lazy(() =>
      import("../pages/superAdmin/off-boarding/health-carer/list")
    ),
  },
  {
    path: "/list-support-coordinator-offboarding",
    component: lazy(() =>
      import("../pages/superAdmin/off-boarding/support-coordinator/list")
    ),
  },
  {
    path: "/add-expenses",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/add-expense")
    ),
  },
  {
    path: "/onboard",
    component: lazy(() => import("../pages/superAdmin/Account-Setup")),
  },

  {
    path: "/off-boarding",
    component: lazy(() => import("../pages/superAdmin/off-boarding/MyAccount")),
  },
  {
    path: "/print-funds-management",
    component: lazy(() => import("../pages/superAdmin/FundManagement/print")),
  },
  {
    path: "/medical-history-print",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/print")
    ),
  },
  {
    path: "/on-board",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/on-boarding")
    ),
  },

  //onBoardingStepper

  {
    path: "/health-carer/onboard",
    component: lazy(() => import("../pages/healthCarer/Account-Setup")),
  },

  {
    path: "/support-coordinator/onboard",
    component: lazy(() => import("../pages/supportCoordinator/Account-Setup")),
  },

  {
    path: "/service-provider/onboard",
    component: lazy(() => import("../pages/serviceProvider/Account-Setup")),
  },

  {
    path: "/client/onboard",
    component: lazy(() => import("../pages/client/Account-Setup")),
  },

  {
    path: "/add-state",
    component: lazy(() => import("../pages/superAdmin/state/edit")),
  },
  {
    path: "/edit-state",
    component: lazy(() => import("../pages/superAdmin/state/edit")),
  },
  {
    path: "/list-state",
    component: lazy(() => import("../pages/superAdmin/state/list")),
  },

  {
    path: "/edit-meeting-type",
    component: lazy(() => import("../pages/superAdmin/meeting-type/edit")),
  },
  {
    path: "/add-meeting-type",
    component: lazy(() => import("../pages/superAdmin/meeting-type/edit")),
  },
  {
    path: "/list-meeting-type",
    component: lazy(() => import("../pages/superAdmin/meeting-type/list")),
  },

  {
    path: "/edit-task-evaluation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-task/edit")
    ),
  },
  {
    path: "/add-task-evaluation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-task/edit")
    ),
  },
  {
    path: "/list-task",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-task/list")
    ),
  },

  {
    path: "/view-tasks",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-task/view")
    ),
  },
  {
    path: "/add-note-evaluation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-note/edit")
    ),
  },
  {
    path: "/edit-note-evaluation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-note/edit")
    ),
  },

  {
    path: "/list-note",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-note/list")
    ),
  },
  {
    path: "/view-note",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-note/view")
    ),
  },
  // {
  //   path: "/add-conflict",
  //   component: lazy(() => import("../pages/superAdmin/conflict/edit")),
  // },
  // {
  //   path: "/edit-conflict",
  //   component: lazy(() => import("../pages/superAdmin/conflict/edit")),
  // },
  // {
  //   path: "/list-conflict",
  //   component: lazy(() => import("../pages/superAdmin/conflict/list")),
  // },
  {
    path: "/add-conflict",
    component: lazy(() => import("../pages/superAdmin/conflictClient/edit")),
  },
  {
    path: "/edit-conflict",
    component: lazy(() => import("../pages/superAdmin/conflictClient/edit")),
  },
  {
    path: "/list-conflict",
    component: lazy(() => import("../pages/superAdmin/conflictClient/list")),
  },

  {
    path: "/edit-category",
    component: lazy(() => import("../pages/superAdmin/client-category/edit")),
  },
  // commenting it because it is not needed
  // {
  //   path: "/add-category",
  //   component: lazy(() => import("../pages/superAdmin/client-category/edit")),
  // },

  {
    path: "/list-category",
    component: lazy(() => import("../pages/superAdmin/client-category/list")),
  },
  // {
  //   path: "/add-category",
  //   component: lazy(() => import("../pages/superAdmin/client-category/edit")),
  // },
  // {
  //   path: "/home",
  //   component: lazy(() => import("../pages/superAdmin/home")),
  // },

  // SA New Dashboard
  // {
  //   path: "/home",
  //   component: lazy(() => import("../pages/superAdmin/Dashboard/SA-Dashboard")),
  // },

  {
    path: "/pending-approval",
    component: lazy(() => import("../pages/superAdmin/pending-approval")),
  },
  {
    path: "/availibility",
    component: lazy(() =>
      import("../pages/superAdmin/availability/Availibility")
    ),
  },
  {
    path: "/list-support-coordinator",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/list")
    ),
  },

  {
    path: "/edit-support-coordinator",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/edit")
    ),
  },
  {
    path: "/add-support-coordinator",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/edit")
    ),
  },

  {
    path: "/support-coordinator-details",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/details")
    ),
  },
  {
    path: "/view-support-coordinator-details",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/view-details-sc")
    ),
  },
  {
    path: "/list-service-provider",
    component: lazy(() => import("../pages/superAdmin/service-provider/list")),
  },

  {
    path: "/edit-service-provider",
    component: lazy(() => import("../pages/superAdmin/service-provider/edit")),
  },
  {
    path: "/add-service-provider",
    component: lazy(() => import("../pages/superAdmin/service-provider/edit")),
  },
  {
    path: "/service-provider-details",
    component: lazy(() =>
      import("../pages/superAdmin/service-provider/details")
    ),
  },
  {
    path: "/schedule-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-meeting/meeting")
    ),
  },
  {
    path: "/schedule-interview",
    component: lazy(() =>
      import(
        "../pages/superAdmin/schedule-interview/health-carer/schedule-interview"
      )
    ),
  },
  {
    path: "/health-carer-on-call",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/on-call/list")
    ),
  },

  {
    path: "/add-risk-assessment",
    component: lazy(() => import("../pages/superAdmin/risk-assessment/edit")),
  },
  {
    path: "/edit-risk-assessment",
    component: lazy(() => import("../pages/superAdmin/risk-assessment/edit")),
  },

  {
    path: "/list-risk-assessment",
    component: lazy(() => import("../pages/superAdmin/risk-assessment/list")),
  },
  {
    path: "/list-user-type",
    component: lazy(() => import("../pages/superAdmin/user-type/list")),
  },
  {
    path: "/edit-user-type",
    component: lazy(() => import("../pages/superAdmin/user-type/edit")),
  },
  {
    path: "/add-user-type",
    component: lazy(() => import("../pages/superAdmin/user-type/edit")),
  },
  {
    path: "/list-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/list")),
  },

  {
    path: "/edit-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/edit")),
  },
  {
    path: "/add-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/edit")),
  },

  {
    path: "/list-severity",
    component: lazy(() => import("../pages/superAdmin/severity/list")),
  },
  {
    path: "/add-severity",
    component: lazy(() => import("../pages/superAdmin/severity/edit")),
  },
  {
    path: "/edit-severity",
    component: lazy(() => import("../pages/superAdmin/severity/edit")),
  },

  {
    path: "/list-appointment-type",
    component: lazy(() => import("../pages/superAdmin/Appointment-type/list")),
  },
  {
    path: "/edit-appointment-type",
    component: lazy(() => import("../pages/superAdmin/Appointment-type/edit")),
  },
  {
    path: "/add-appointment-type",
    component: lazy(() => import("../pages/superAdmin/Appointment-type/edit")),
  },

  {
    path: "/list-routines",
    component: lazy(() => import("../pages/superAdmin/routine/list")),
  },
  {
    path: "/add-routines",
    component: lazy(() => import("../pages/superAdmin/routine/edit")),
  },
  {
    path: "/edit-routines",
    component: lazy(() => import("../pages/superAdmin/routine/edit")),
  },

  {
    path: "/list-textspeechkit",
    component: lazy(() => import("../pages/superAdmin/textspeechkit/list")),
  },

  {
    path: "/add-textspeechkit",
    component: lazy(() => import("../pages/superAdmin/textspeechkit/edit")),
  },

  {
    path: "/add-speech-to-text",
    component: lazy(() =>
      import("../pages/superAdmin/textspeechkit/addSpeechToText")
    ),
  },
  {
    path: "/view-textspeechkit",
    component: lazy(() => import("../pages/superAdmin/textspeechkit/details")),
  },
  {
    path: "/add-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/add-appointment")
    ),
  },

  {
    path: "/edit-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/add-appointment")
    ),
  },

  {
    path: "/myaccount",
    component: lazy(() => import("../pages/superAdmin/form/MyAccount")),
  },

  {
    path: "/list-package-menu",
    component: lazy(() => import("../pages/superAdmin/packageMenu/list")),
  },

  {
    path: "/add-package-menu",
    component: lazy(() => import("../pages/superAdmin/packageMenu/edit")),
  },
  {
    path: "/edit-package-menu",
    component: lazy(() => import("../pages/superAdmin/packageMenu/edit")),
  },

  {
    path: "/import-ndis",
    component: lazy(() => import("../pages/superAdmin/ndis/NdisFileImport")),
  },

  {
    path: "/pay-rates",
    component: lazy(() => import("../pages/superAdmin/payRates/PayRates")),
  },

  {
    path: "/add-routine-icons",
    component: lazy(() => import("../pages/superAdmin/routine-icon")),
  },

  {
    path: "/edit-sidebar-menu",
    component: lazy(() => import("../pages/superAdmin/sidebar-menu/edit")),
  },
  {
    path: "/add-sidebar-menu",
    component: lazy(() => import("../pages/superAdmin/sidebar-menu/edit")),
  },
  {
    path: "/list-sidebar-menu",
    component: lazy(() => import("../pages/superAdmin/sidebar-menu/list")),
  },
  {
    path: "/list-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/list/AppointmentList")
    ),
  },

  {
    path: "/view-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/list/Appointment-view")
    ),
  },

  {
    path: "/swap-appointment",
    component: lazy(() =>
      import("../components/calendar-view/swap-appointment/swapappointment")
    ),
  },
  {
    path: "/add-routine",
    component: lazy(() =>
      import("../components/calendar-view/routine/Add-Routine")
    ),
  },

  {
    path: "/edit-task",
    component: lazy(() =>
      import("../components/calendar-view/task/AddRoutineTask")
    ),
  },
  {
    path: "/task-add",
    component: lazy(() =>
      import("../components/calendar-view/task/AddRoutineTask")
    ),
  },

  {
    path: "/timesheet-pdf",
    component: lazy(() => import("../components/pay-run/PayRunPdf")),
  },

  {
    path: "/appointmentView",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/list/Appointment-view")
    ),
  },

  {
    path: "/list-groups",
    component: lazy(() => import("../pages/superAdmin/groups/list")),
  },

  {
    path: "/edit-groups",
    component: lazy(() => import("../pages/superAdmin/groups/edit")),
  },
  {
    path: "/add-groups",
    component: lazy(() => import("../pages/superAdmin/groups/edit")),
  },

  {
    path: "/list-incident",
    component: lazy(() => import("../pages/superAdmin/incident/list")),
  },
  {
    path: "/edit-incident",
    component: lazy(() => import("../pages/superAdmin/incident/edit")),
  },
  {
    path: "/add-incident",
    component: lazy(() => import("../pages/superAdmin/incident/edit")),
  },
  {
    path: "/view-incident",
    component: lazy(() => import("../pages/superAdmin/incident/view")),
  },

  {
    path: "/add-agreement",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/appointment-agreement/edit")
    ),
  },
  {
    path: "/edit-agreement",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/appointment-agreement/edit")
    ),
  },
  {
    path: "/list-agreement",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/appointment-agreement/list")
    ),
  },
  {
    path: "/availability-calendar-view",
    component: lazy(() => import("../components/availability/Calendar")),
  },
  {
    path: "/weekly-availability",
    component: lazy(() =>
      import("../components/weekly-availability/weekly-availability")
    ),
  },

  {
    path: "/add-user-role",
    component: lazy(() => import("../pages/superAdmin/user-role/edit")),
  },
  {
    path: "/edit-user-role",
    component: lazy(() => import("../pages/superAdmin/user-role/edit")),
  },
  {
    path: "/list-user-role",
    component: lazy(() => import("../pages/superAdmin/user-role/list")),
  },

  {
    path: "/offer-letter",
    component: lazy(() =>
      import("../pages/superAdmin/contentManagement/offer-letter/edit")
    ),
  },

  {
    path: "/list-template-master",
    component: lazy(() => import("../pages/superAdmin/template-master/list")),
  },

  {
    path: "/edit-template-master",
    component: lazy(() => import("../pages/superAdmin/template-master/edit")),
  },
  {
    path: "/add-template-master",
    component: lazy(() => import("../pages/superAdmin/template-master/edit")),
  },

  {
    path: "/list-emergency-contact",
    component: lazy(() => import("../pages/superAdmin/emergency-contact/list")),
  },

  {
    path: "/edit-emergency-contact",
    component: lazy(() => import("../pages/superAdmin/emergency-contact/edit")),
  },

  {
    path: "/add-emergency-contacts",
    component: lazy(() => import("../pages/superAdmin/emergency-contact/edit")),
  },

  {
    path: "/edit-emergency-contact",
    component: lazy(() => import("../pages/superAdmin/emergency-contact/edit")),
  },

  {
    path: "/add-emergency-contact",
    component: lazy(() => import("../pages/superAdmin/emergency-contact/edit")),
  },
  {
    path: "/add-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/edit")),
  },

  {
    path: "/edit-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/edit")),
  },

  {
    path: "/list-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/list")),
  },

  {
    path: "/list-template-variable-master",
    component: lazy(() =>
      import("../pages/superAdmin/template-master/variable/list")
    ),
  },

  {
    path: "/edit-template-variable-master",
    component: lazy(() =>
      import("../pages/superAdmin/template-master/variable/edit")
    ),
  },
  {
    path: "/add-template-variable-master",
    component: lazy(() =>
      import("../pages/superAdmin/template-master/variable/edit")
    ),
  },

  {
    path: "/add-file",
    component: lazy(() => import("../pages/superAdmin/files/edit")),
  },
  {
    path: "/edit-file",
    component: lazy(() => import("../pages/superAdmin/files/edit")),
  },
  {
    path: "/list-file",
    component: lazy(() => import("../pages/superAdmin/files/list")),
  },

  {
    path: "/edit-folder",
    component: lazy(() => import("../pages/superAdmin/Folder/edit")),
  },
  {
    path: "/add-folder",
    component: lazy(() => import("../pages/superAdmin/Folder/edit")),
  },

  {
    path: "/list-folder",
    component: lazy(() => import("../pages/superAdmin/Folder/list")),
  },

  {
    path: "/view-folder",
    component: lazy(() => import("../pages/superAdmin/Folder/view")),
  },

  {
    path: "/list-funding-source",
    component: lazy(() => import("../pages/superAdmin/Funding-Source/list")),
  },

  {
    path: "/edit-funding-source",
    component: lazy(() => import("../pages/superAdmin/Funding-Source/edit")),
  },
  {
    path: "/add-funding-source",
    component: lazy(() => import("../pages/superAdmin/Funding-Source/edit")),
  },

  {
    path: "/list-meeting-client-old",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-meeting/client/list")
    ),
  },

  {
    path: "/list-view-schedule-meeting-old",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-meeting/client/view")
    ),
  },
  {
    path: "/list-meeting-client",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-meeting/client/list-tab")
    ),
  },

  {
    path: "/list-calendar",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-common-meeting/client/list-tab")
    ),
  },
  {
    path: "/schedule-other-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-other-meeting/meeting")
    ),
  },

  {
    path: "/list-view-other-schedule-meeting",
    component: lazy(() => import("../components/meeting-view-other/details")),
  },
  {
    path: "/list-view-schedule-meeting",
    component: lazy(() => import("../components/meeting-view/details")),
  },
  {
    path: "/super-admin/edit-other-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-other-meeting/meeting")
    ),
  },
  {
    path: "/list-interview-health-carer-old",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-interview/health-carer/list")
    ),
  },
  {
    path: "/list-interview-health-carer",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-interview/health-carer/list-tab")
    ),
  },

  {
    path: "/list-view-schedule-interview",
    component: lazy(() => import("../components/interview-view/details")),
  },

  {
    path: "/time-sheet",
    //component: lazy(() => import("../components/timesheet/Timesheet")),
    component: lazy(() => import("../components/timesheet/CommonTimeSheet")),
  },
  // {
  //   path: "/invoice",
  //   component: lazy(() => import("../components/invoice/Invoice")),
  // },
  {
    path: "/invoice",
    component: lazy(() => import("../components/invoice/CommonInvoice")),
  },

  {
    path: "/invoice-pdf",
    component: lazy(() => import("../components/invoice/InvoicePdf")),
  },

  {
    path: "/timesheet-view",
    component: lazy(() => import("../components/timesheet/TimesheetView")),
  },
  {
    path: "/view-time-sheet",
    component: lazy(() =>
      import("../pages/healthCarer/appointment/Timesheet/ViewTimeSheet")
    ),
  },
  {
    path: "/time-sheet-payslip",
    component: lazy(() => import("../components/timesheet/PayslipPdf")),
  },
  {
    path: "/time-sheet-payslip-individual",
    component: lazy(() =>
      import("../components/timesheet/PayslipPdfForIndividual")
    ),
  },
  {
    path: "/payslip",
    component: lazy(() => import("../components/payroll/payslips/payslips")),
  },
  {
    path: "/list-report",
    component: lazy(() => import("../pages/superAdmin/Reports/list")),
  },

  {
    path: "/add-report",
    component: lazy(() => import("../pages/superAdmin/Reports/edit")),
  },
  {
    path: "/edit-report",
    component: lazy(() => import("../pages/superAdmin/Reports/edit")),
  },
  {
    path: "/reports",
    component: lazy(() => import("../pages/superAdmin/Reports/report")),
  },
  {
    path: "/list-employee-birthdays",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/employeeBirthday")
    ),
  },
  {
    path: "/report-list/shift-swapping",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/timeAndAttendance/shiftswapping")
    ),
  },

  {
    path: "/list-employee-details",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/employeeDetails")
    ),
  },
  {
    path: "/list-employee-emergency-contacts",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/emergencyContact")
    ),
  },

  {
    path: "/list-unpaid-employee",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/unpaidEmployee")
    ),
  },
  {
    path: "/list-jobmaker-employee-notice",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/atoReporting/jobmakerEmployeeNotice")
    ),
  },

  {
    path: "/list-jobkeeper-employee-nominations",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Reports/atoReporting/jobkeeperEmployeeNomination"
      )
    ),
  },
  {
    path: "/list-tax-file-declaration",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/atoReporting/taxFileDeclaration")
    ),
  },
  {
    path: "/list-attendance",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/timeAndAttendance/attendanceReport")
    ),
  },
  {
    path: "/list-employee-satisfaction",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/employeeSatisfaction")
    ),
  },
  {
    path: "/list-single-touch-payroll",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/atoReporting/singleTouchPayroll")
    ),
  },
  {
    path: "/list-holiday",
    component: lazy(() => import("../pages/superAdmin/public-holiday/list")),
  },

  {
    path: "/add-holiday",
    component: lazy(() => import("../pages/superAdmin/public-holiday/edit")),
  },
  {
    path: "/edit-holiday",
    component: lazy(() => import("../pages/superAdmin/public-holiday/edit")),
  },

  {
    path: "/checksendmailpdf",
    component: lazy(() =>
      import(
        "../pages/superAdmin/contentManagement/offer-letter/sendAttachEmail"
      )
    ),
  },
  {
    path: "/list-progress-objective-masters",
    component: lazy(() => import("../pages/superAdmin/progressObjective/list")),
  },
  {
    path: "/add-progress-objective-masters",
    component: lazy(() => import("../pages/superAdmin/progressObjective/edit")),
  },
  {
    path: "/edit-progress-objective-masters",
    component: lazy(() => import("../pages/superAdmin/progressObjective/edit")),
  },

  {
    path: "/employee-leave-history",
    component: lazy(() => import("../pages/superAdmin/Reports/leave-history")),
  },
  {
    path: "/bulk-upload",
    component: lazy(() => import("../pages/superAdmin/ndis/BulkUpload")),
  },
  {
    path: "/xero-export",
    component: lazy(() => import("../components/xero-export/XeroExport")),
  },
  {
    path: "/myob-export",
    component: lazy(() => import("../components/myob-export/MyobExport")),
  },
  {
    path: "/list-employee-details-audit",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/employeeDetailsAudit")
    ),
  },

  {
    path: "/bulk-upload-client-onboard",
    component: lazy(() => import("../components/Bulk-Upload/Client/Import")),
  },

  {
    path: "/bulk-upload-serviceprovider-onboard",
    component: lazy(() =>
      import("../components/Bulk-Upload/ServiceProvider/Import")
    ),
  },

  {
    path: "/bulk-upload-supportcoordinator-onboard",
    component: lazy(() =>
      import("../components/Bulk-Upload/SupportCoordinator/Import")
    ),
  },

  {
    path: "/react-sample-template",
    component: lazy(() =>
      import("../pages/superAdmin/contentManagement/offer-letter/reactEditor")
    ),
  },
  {
    path: "/react-sample-template-value",
    component: lazy(() =>
      import(
        "../pages/superAdmin/contentManagement/offer-letter/reactEditorValue"
      )
    ),
  },
  {
    path: "/view-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/view")),
  },
  {
    path: "/list-employee-jokeeper-eligibility",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/jobKeeperEligibility")
    ),
  },

  {
    path: "/list-medicine-frequency",
    component: lazy(() => import("../pages/superAdmin/medicineFrequency/list")),
  },
  {
    path: "/edit-medicine-frequency",
    component: lazy(() => import("../pages/superAdmin/medicineFrequency/edit")),
  },
  {
    path: "/add-medicine-frequency",
    component: lazy(() => import("../pages/superAdmin/medicineFrequency/edit")),
  },

  {
    path: "/select-client",
    component: lazy(() => import("../components/goals/ClientSelect")),
  },

  {
    path: "/add-goal",
    component: lazy(() => import("../components/goals/edit")),
  },

  {
    path: "/edit-goal",
    component: lazy(() => import("../components/goals/edit")),
  },

  {
    path: "/list-goal-as-per-client",
    component: lazy(() => import("../components/goals/list")),
  },

  {
    path: "/add-goal-routine",
    component: lazy(() => import("../components/goals/addRoutineTask/edit")),
  },

  {
    path: "/view-task",
    component: lazy(() => import("../components/goals/view")),
  },
  {
    path: "/export-pdf",
    component: lazy(() => import("../components/goals/pdf")),
  },
  {
    path: "/export-pdf-with-image",
    component: lazy(() => import("../components/goals/pdfWithImage")),
  },

  {
    path: "/edit-goal-routine",
    component: lazy(() => import("../components/goals/addRoutineTask/edit")),
  },

  {
    path: "/access-role-permission",
    component: lazy(() =>
      import("../pages/superAdmin/access-role-permission/list")
    ),
  },

  {
    path: "/bulk-upload-staff",
    component: lazy(() => import("../components/Bulk-Upload/Staff/Import")),
  },

  {
    path: "/bulk-upload-service-provider-onboard",
    component: lazy(() =>
      import("../components/Bulk-Upload/ServiceProvider/Import")
    ),
  },
  {
    path: "/set-access-role",
    component: lazy(() => import("../pages/superAdmin/access-role/list")),
  },
  {
    path: "/add-access-role",
    component: lazy(() => import("../pages/superAdmin/access-role/add.js")),
  },

  {
    path: "/client-view",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/client-view")
    ),
  },

  {
    path: "/view-client-details",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/view-details-client")
    ),
  },

  {
    path: "/list-health-issue",
    component: lazy(() => import("../pages/superAdmin/healthIssue/list")),
  },
  {
    path: "/edit-health-issue",
    component: lazy(() => import("../pages/superAdmin/healthIssue/edit")),
  },
  {
    path: "/add-health-issue",
    component: lazy(() => import("../pages/superAdmin/healthIssue/edit")),
  },
  {
    path: "/list-information",
    component: lazy(() => import("../pages/superAdmin/information/list")),
  },
  {
    path: "/edit-information",
    component: lazy(() => import("../pages/superAdmin/information/edit")),
  },
  {
    path: "/add-information",
    component: lazy(() => import("../pages/superAdmin/information/edit")),
  },

  {
    path: "/bulk-upload-health-carer-onboard",
    component: lazy(() =>
      import("../components/Bulk-Upload/HealthCarer/Import")
    ),
  },

  {
    path: "/chart-goal",
    component: lazy(() => import("../components/goals/Chart")),
  },
  {
    path: "/bar-chart-goal",
    component: lazy(() => import("../components/goals/barChart")),
  },

  {
    path: "/list-dose-form",
    component: lazy(() => import("../pages/superAdmin/doseForm/list")),
  },
  {
    path: "/edit-dose-form",
    component: lazy(() => import("../pages/superAdmin/doseForm/edit")),
  },
  {
    path: "/add-dose-form",
    component: lazy(() => import("../pages/superAdmin/doseForm/edit")),
  },

  {
    path: "/list-observationName",
    component: lazy(() => import("../pages/superAdmin/observationName/list")),
  },
  {
    path: "/edit-observationName",
    component: lazy(() => import("../pages/superAdmin/observationName/edit")),
  },
  {
    path: "/add-observationName",
    component: lazy(() => import("../pages/superAdmin/observationName/edit")),
  },

  {
    path: "/list-taxations",
    component: lazy(() => import("../pages/superAdmin/taxation/list")),
  },
  {
    path: "/edit-taxations",
    component: lazy(() => import("../pages/superAdmin/taxation/edit")),
  },
  {
    path: "/add-taxations",
    component: lazy(() => import("../pages/superAdmin/taxation/edit")),
  },

  {
    path: "/support-coordinator-view",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/view")
    ),
  },

  {
    path: "/view-blood-observation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/Viewtab/Observation/Blood/view")
    ),
  },

  {
    path: "/view-bowel-observation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/Viewtab/Observation/Bowel/view")
    ),
  },

  {
    path: "/view-food-observation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/Viewtab/Observation/Food/view")
    ),
  },

  {
    path: "/service-provider-view",
    component: lazy(() => import("../pages/superAdmin/service-provider/view")),
  },

  {
    path: "/view-service-provider-details",
    component: lazy(() =>
      import("../pages/superAdmin/service-provider/view-details-sp")
    ),
  },

  {
    path: "/view-mestruation-observation",
    component: lazy(() =>
      import(
        "../pages/superAdmin/client-list/Viewtab/Observation/Mestruation/view"
      )
    ),
  },
  {
    path: "/view-personalcare-observation",
    component: lazy(() =>
      import(
        "../pages/superAdmin/client-list/Viewtab/Observation/PersonalCare/view"
      )
    ),
  },
  {
    path: "/view-seizure-observation",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Observation/Seizure/view"
      )
    ),
  },
  {
    path: "/view-weight-observation",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Observation/weight/view"
      )
    ),
  },

  {
    path: "/list-funds-management",
    component: lazy(() =>
      import("../pages/superAdmin/FundManagement/Fund-Management")
    ),
  },
  {
    path: "/view-funds-management",
    component: lazy(() =>
      import("../pages/superAdmin/FundManagement/View-Fund-Management")
    ),
  },

  {
    path: "/list-payschedule",
    component: lazy(() => import("../pages/superAdmin/paySchedule/list")),
  },

  {
    path: "/add-payschedule",
    component: lazy(() => import("../pages/superAdmin/paySchedule/edit")),
  },

  {
    path: "/edit-payschedule",
    component: lazy(() => import("../pages/superAdmin/paySchedule/edit")),
  },
  {
    path: "/edit-payrunstatus",
    component: lazy(() => import("../pages/superAdmin/payrunStatus/edit")),
  },
  {
    path: "/add-payrunstatus",
    component: lazy(() => import("../pages/superAdmin/payrunStatus/edit")),
  },
  {
    path: "/list-payrunstatus",
    component: lazy(() => import("../pages/superAdmin/payrunStatus/list")),
  },
  {
    path: "/subscription-plans-list",
    component: lazy(() => import("../pages/superAdmin/subscription-plan/list")),
  },

  {
    path: "/email",
    component: lazy(() => import("../components/email/list")),
  },

  {
    path: "/email/chatopenai",
    component: lazy(() => import("../components/email/chatAi")),
  },

  {
    path: "/view-email",
    component: lazy(() => import("../components/email/view")),
  },
  {
    path: "/edit-email",
    component: lazy(() => import("../components/email/newEmail/edit")),
  },
  {
    path: "/list-signed-document",
    component: lazy(() =>
      import("../components/SignedDocument/ListSignedDocument")
    ),
  },
  {
    path: "/view-signed-document",
    component: lazy(() =>
      import("../components/SignedDocument/ViewSignedDocument")
    ),
  },

  {
    path: "/list-super-annuation",
    component: lazy(() => import("../pages/superAdmin/superFunds/list")),
  },

  {
    path: "/add-super-annuation",
    component: lazy(() => import("../pages/superAdmin/superFunds/edit")),
  },

  {
    path: "/edit-super-annuation",
    component: lazy(() => import("../pages/superAdmin/superFunds/edit")),
  },

  {
    path: "/list-region",
    component: lazy(() => import("../pages/superAdmin/Region/list")),
  },

  {
    path: "/list-document",
    component: lazy(() =>
      import("../pages/superAdmin/Request-Document/Documentview")
    ),
  },
  {
    path: "/list-policy",
    component: lazy(() => import("../pages/superAdmin/policy/list")),
  },
  {
    path: "/edit-policy",
    component: lazy(() => import("../pages/superAdmin/policy/edit")),
  },
  {
    path: "/add-policy",
    component: lazy(() => import("../pages/superAdmin/policy/edit")),
  },
  {
    path: "/request-connection-list",
    component: lazy(() =>
      import("../pages/superAdmin/Request-Document/Connection-request")
    ),
  },
  {
    path: "/view-request",
    component: lazy(() =>
      import("../pages/superAdmin/Request-Document/View-request")
    ),
  },

  {
    path: "/list-availability-satus",
    component: lazy(() => import("../pages/superAdmin/availabilitySatus/list")),
  },
  {
    path: "/edit-availability-satus",
    component: lazy(() => import("../pages/superAdmin/availabilitySatus/edit")),
  },
  {
    path: "/add-availability-satus",
    component: lazy(() => import("../pages/superAdmin/availabilitySatus/edit")),
  },

  {
    path: "/support-duration-add",
    component: lazy(() => import("../pages/superAdmin/supportDuration/edit")),
  },
  {
    path: "/support-duration-edit",
    component: lazy(() => import("../pages/superAdmin/supportDuration/edit")),
  },
  {
    path: "/support-duration-list",
    component: lazy(() => import("../pages/superAdmin/supportDuration/list")),
  },

  {
    path: "/support-hours-add",
    component: lazy(() => import("../pages/superAdmin/support-Hours/edit")),
  },
  {
    path: "/support-hours-edit",
    component: lazy(() => import("../pages/superAdmin/support-Hours/edit")),
  },
  {
    path: "/support-hours-list",
    component: lazy(() => import("../pages/superAdmin/support-Hours/list")),
  },

  {
    path: "/list-service-item",
    component: lazy(() => import("../pages/superAdmin/service-item/list")),
  },
  {
    path: "/edit-service-item",
    component: lazy(() => import("../pages/superAdmin/service-item/edit")),
  },
  {
    path: "/add-service-item",
    component: lazy(() => import("../pages/superAdmin/service-item/edit")),
  },

  {
    path: "/list-service-task",
    component: lazy(() => import("../pages/superAdmin/service-task/list")),
  },
  {
    path: "/edit-service-task",
    component: lazy(() => import("../pages/superAdmin/service-task/edit")),
  },
  {
    path: "/add-service-task",
    component: lazy(() => import("../pages/superAdmin/service-task/edit")),
  },
  {
    path: "/view-service-task",
    component: lazy(() => import("../pages/superAdmin/service-task/view")),
  },
  {
    path: "/bsp",
    component: lazy(() => import("../pages/superAdmin/Bsp/Bsp")),
  },

  // {
  //   path: "/add-complaint",
  //   component: lazy(() => import("../pages/superAdmin/complaint/edit")),
  // },
  // {
  //   path: "/edit-complaint",
  //   component: lazy(() => import("../pages/superAdmin/complaint/edit")),
  // },
  // {
  //   path: "/list-complaint",
  //   component: lazy(() => import("../pages/superAdmin/complaint/list")),
  // },
  {
    path: "/list-year-to-date",
    component: lazy(() => import("../pages/superAdmin/YeatTODate/list")),
  },
  {
    path: "/list-bsp-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },
  {
    path: "/add-bsp-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/addBsp")
    ),
  },
  {
    path: "/list-bsp-question",
    component: lazy(() => import("../pages/superAdmin/Bsp/list")),
  },
  {
    path: "/add-bsp-question",
    component: lazy(() => import("../pages/superAdmin/Bsp/edit")),
  },
  {
    path: "/edit-bsp-question",
    component: lazy(() => import("../pages/superAdmin/Bsp/edit")),
  },

  {
    path: "/list-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/list")),
  },

  {
    path: "/view-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/view")),
  },

  // leave management new changes

  {
    path: "/list-leave-categories",
    component: lazy(() => import("../pages/superAdmin/leave-category/list")),
  },

  {
    path: "/add-leave-categories",
    component: lazy(() => import("../pages/superAdmin/leave-category/edit")),
  },

  {
    path: "/edit-leave-categories",
    component: lazy(() => import("../pages/superAdmin/leave-category/edit")),
  },

  {
    path: "/list-leave-management-old",
    component: lazy(() => import("../pages/superAdmin/leaveManagement/list")),
  },

  {
    path: "/list-leave-management",
    component: lazy(() =>
      import("../pages/superAdmin/leaveManagement/list-tab")
    ),
  },

  {
    path: "/add-leave",
    component: lazy(() => import("../pages/superAdmin/leaveManagement/edit")),
  },

  {
    path: "/edit-leave",
    component: lazy(() => import("../pages/superAdmin/leaveManagement/edit")),
  },

  {
    path: "/view-leave",
    component: lazy(() => import("../pages/superAdmin/leaveManagement/view")),
  },

  {
    path: "/list-set-leave",
    component: lazy(() => import("../pages/superAdmin/setLeave/list")),
  },

  {
    path: "/add-set-leave",
    component: lazy(() => import("../pages/superAdmin/setLeave/edit")),
  },

  {
    path: "/edit-set-leave",
    component: lazy(() => import("../pages/superAdmin/setLeave/edit")),
  },
  {
    path: "/chat-history",
    component: lazy(() => import("../pages/superAdmin/chathistory/list")),
  },

  {
    path: "/add-homefeature",
    component: lazy(() => import("../pages/superAdmin/HomeFeature/edit")),
  },
  {
    path: "/list-homefeature",
    component: lazy(() => import("../pages/superAdmin/HomeFeature/list")),
  },
  {
    path: "/edit-homefeature",
    component: lazy(() => import("../pages/superAdmin/HomeFeature/edit")),
  },

  {
    path: "/sda-location",
    component: lazy(() => import("../pages/superAdmin/SDALocation/list")),
  },
  {
    path: "/add-loction",
    component: lazy(() => import("../pages/superAdmin/SDALocation/edit")),
  },
  {
    path: "/edit-loction",
    component: lazy(() => import("../pages/superAdmin/SDALocation/edit")),
  },
  {
    path: "/sil-sda-location-add",
    component: lazy(() => import("../pages/superAdmin/sil-sda-location/edit")),
  },
  {
    path: "/sil-sda-location-edit",
    component: lazy(() => import("../pages/superAdmin/sil-sda-location/edit")),
  },
  {
    path: "/sil-sda-location-list",
    component: lazy(() => import("../pages/superAdmin/sil-sda-location/list")),
  },
  {
    path: "/add-sil-sda-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/sil-sda/silsda-add-appointment.js")
    ),
  },
  {
    path: "/new-sil-sda-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/sil-sda/new-sil-sda-appointment")
    ),
  },
  {
    path: "/list-asset",
    component: lazy(() => import("../pages/superAdmin/assetManagement/list")),
  },
  {
    path: "/add-asset",
    component: lazy(() => import("../pages/superAdmin/assetManagement/edit")),
  },
  {
    path: "/edit-asset",
    component: lazy(() => import("../pages/superAdmin/assetManagement/edit")),
  },
  {
    path: "/edit-compliance",
    component: lazy(() => import("../pages/superAdmin/compliance/edit")),
  },
  {
    path: "/add-compliance",
    component: lazy(() => import("../pages/superAdmin/compliance/edit")),
  },
  {
    path: "/list-compliance",
    component: lazy(() => import("../pages/superAdmin/compliance/list")),
  },

  {
    path: "/add-lms-student",
    component: lazy(() => import("../pages/superAdmin/lms-student/edit")),
  },
  {
    path: "/edit-lms-student",
    component: lazy(() => import("../pages/superAdmin/lms-student/edit")),
  },
  {
    path: "/manage-lms-student",
    component: lazy(() =>
      import("../pages/superAdmin/lms-student/openMoodle.js")
    ),
  },
  {
    path: "/list-client-rating",
    component: lazy(() => import("../pages/superAdmin/Ratings/client")),
  },
  {
    path: "/list-health-carer-rating",
    component: lazy(() => import("../pages/superAdmin/Ratings/healthCarer")),
  },

  {
    path: "/manage-training",
    component: lazy(() => import("../pages/superAdmin/LMS/training/list")),
  },

  {
    path: "/list-template",
    component: lazy(() =>
      import("../components/email/templateMaster/list-cards")
    ),
  },
  {
    path: "/edit-template",
    component: lazy(() => import("../components/email/templateMaster/edit")),
  },
  {
    path: "/add-template",
    component: lazy(() => import("../components/email/templateMaster/edit")),
  },
  {
    path: "/list-leads",
    component: lazy(() => import("../pages/superAdmin/demoRequest/list")),
  },
  {
    path: "/demo-meeting",
    component: lazy(() => import("../pages/superAdmin/demoRequest/meeting")),
  },
  {
    path: "/view-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/demoRequest/viewMeeting")
    ),
  },
  {
    path: "/edit-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/demoRequest/editMeeting.js")
    ),
  },
  {
    path: "/list-blogs",
    component: lazy(() => import("../pages/superAdmin/blog/list")),
  },
  {
    path: "/add-blogs",
    component: lazy(() => import("../pages/superAdmin/blog/edit")),
  },
  {
    path: "/edit-blogs",
    component: lazy(() => import("../pages/superAdmin/blog/edit")),
  },
  {
    path: "/list-guides",
    component: lazy(() => import("../pages/superAdmin/guide/list")),
  },
  {
    path: "/add-guides",
    component: lazy(() => import("../pages/superAdmin/guide/edit")),
  },
  {
    path: "/edit-guides",
    component: lazy(() => import("../pages/superAdmin/guide/edit")),
  },
  {
    path: "/add-blog-and-guide-category",
    component: lazy(() =>
      import("../pages/superAdmin/blogAndGuideCategory/edit")
    ),
  },
  {
    path: "/edit-blog-and-guide-category",
    component: lazy(() =>
      import("../pages/superAdmin/blogAndGuideCategory/edit")
    ),
  },
  {
    path: "/list-blog-and-guide-category",
    component: lazy(() =>
      import("../pages/superAdmin/blogAndGuideCategory/list")
    ),
  },
  {
    path: "/add-template-type",
    component: lazy(() => import("../pages/superAdmin/templateType/edit")),
  },
  {
    path: "/edit-template-type",
    component: lazy(() => import("../pages/superAdmin/templateType/edit")),
  },
  {
    path: "/list-template-type",
    component: lazy(() => import("../pages/superAdmin/templateType/list")),
  },
  {
    path: "/list-demo-time",
    component: lazy(() => import("../pages/superAdmin/demoTime/list")),
  },
  {
    path: "/edit-demo-time",
    component: lazy(() => import("../pages/superAdmin/demoTime/edit")),
  },
  {
    path: "/add-demo-time",
    component: lazy(() => import("../pages/superAdmin/demoTime/edit")),
  },

  {
    path: "/list-live-roaster-appointment-request",
    component: lazy(() =>
      import("../pages/superAdmin/requestLiveRoasterAppointment/list.js")
    ),
  },
  {
    path: "/user-email/edit-email",
    component: lazy(() =>
      import("../components/secondaryEmail/newEmaill/edit")
    ),
  },
  {
    path: "/list-shift-notes",
    component: lazy(() => import("../pages/superAdmin/shiftNotes/list")),
  },
  {
    path: "/shift-notes-print",
    component: lazy(() => import("../pages/superAdmin/shiftNotes/print.js")),
  },
  {
    path: "/pdf-shift-task-notes",
    component: lazy(() => import("../pages/superAdmin/shiftNotes/pdf")),
  },
  {
    path: "/list-service-task-notes",
    component: lazy(() => import("../pages/superAdmin/serviceTaskNotes/list")),
  },
  {
    path: "/add-left-menu",
    component: lazy(() => import("../pages/superAdmin/leftMenu/edit.js")),
  },
  {
    path: "/edit-left-menu",
    component: lazy(() => import("../pages/superAdmin/leftMenu/edit.js")),
  },
  {
    path: "/list-left-menu",
    component: lazy(() => import("../pages/superAdmin/leftMenu/list.js")),
  },
  // client Routes

  {
    path: "/home",
    // component: lazy(() => import("../pages/client/form/home")),
    component: lazy(() =>
      import("../pages/Admin/Dashboard/DashboardNavigation.js")
    ),
  },
  {
    path: "/list-package-menu",
    component: lazy(() => import("../components/packageMenu/list")),
  },
  {
    path: "/view-details",
    component: lazy(() => import("../pages/client/View-Details/View-Details")),
  },
  {
    path: "/risk-assesment",
    component: lazy(() =>
      import("../pages/Stakeholder/Riskassesment/Riskassesment")
    ),
  },
  {
    path: "/myaccount",
    component: lazy(() => import("../pages/client/form/MyAccount")),
  },
  {
    path: "/add-textspeechkit",
    component: lazy(() => import("../pages/superAdmin/textspeechkit/edit")),
  },
  {
    path: "/list-textspeechkit",
    component: lazy(() => import("../pages/superAdmin/textspeechkit/list")),
  },
  {
    path: "/view-textspeechkit",
    component: lazy(() => import("../pages/superAdmin/textspeechkit/details")),
  },
  {
    path: "/appointment-list",
    component: lazy(() => import("../pages/client/appointment/list")),
  },
  {
    path: "/list-appointments",
    component: lazy(() => import("../pages/client/appointment/list")),
  },
  {
    path: "/appointments-view",
    component: lazy(() =>
      import("../components/calendar-view/Appointment-View")
    ),
  },
  {
    path: "/meeting-list-old",
    component: lazy(() => import("../pages/client/meeting/list")),
  },
  {
    path: "/view-meeting-details-old",
    component: lazy(() => import("../pages/client/meeting/details")),
  },
  {
    path: "/meeting-list",
    component: lazy(() => import("../pages/client/meeting/list")),
  },
  {
    path: "/list-task-notes",
    component: lazy(() => import("../pages/client/TaskandNote/list")),
  },
  {
    path: "/list-delayed-appointment",
    component: lazy(() =>
      import("../components/calendar-view/Table/DelayedAppointment")
    ),
  },
  {
    path: "/view-meeting-details",
    component: lazy(() => import("../pages/client/meeting/details")),
  },
  //Below routes are incorrect for admin so commented
  // {
  //   path: "/add-emergency-contact",
  //   component: lazy(() =>
  //     import("../pages/healthCarer/Emergency-Contact/edit")
  //   ),
  // },
  // {
  //   path: "/edit-emergency-contact",
  //   component: lazy(() =>
  //     import("../pages/healthCarer/Emergency-Contact/edit")
  //   ),
  // },
  // {
  //   path: "/list-emergency-contact",
  //   component: lazy(() =>
  //     import("../pages/healthCarer/Emergency-Contact/list")
  //   ),
  // },
  {
    path: "/exploremarket",
    component: lazy(() =>
      import("../pages/client/Exploremarket/Exploremarket")
    ),
  },
  {
    path: "/viewexploremarket",
    component: lazy(() => import("../components/emavailability/ViewDetail")),
  },
  {
    path: "/list-community-access-provider",
    component: lazy(() =>
      import("../pages/superAdmin/community-accessprovider/list")
    ),
  },
  {
    path: "/list-complaint-or-feedback-management",
    component: lazy(() => import("../pages/superAdmin/complaint/list")),
  },
  {
    path: "/list-conflict-of-interest-register",
    component: lazy(() => import("../pages/superAdmin/conflict/list")),
  },
  {
    path: "/list-funding",
    component: lazy(() =>
      import("../pages/superAdmin/FundManagement/Fund-Management")
    ),
  },
  {
    path: "/list-goals",
    component: lazy(() => import("../components/goals/list")),
  },
  {
    path: "/list-invoices",
    component: lazy(() => import("../pages/client/in-progress")),
  },
  {
    path: "/list-notes",
    component: lazy(() => import("../pages/client/in-progress")),
  },
  {
    path: "/list-plan-manager",
    component: lazy(() => import("../pages/client/in-progress")),
  },
  {
    path: "/list-progress-notes",
    component: lazy(() => import("../pages/client/in-progress")),
  },
  {
    path: "/list-reports",
    component: lazy(() => import("../pages/client/in-progress")),
  },
  {
    path: "/list-routine-and-tasks",
    component: lazy(() =>
      import("../components/routine-task-dashboard/AppointmentDashboard")
    ),
  },
  {
    path: "/list-search-support",
    component: lazy(() => import("../pages/client/in-progress")),
  },
  {
    path: "/list-tasks",
    component: lazy(() => import("../pages/client/in-progress")),
  },
  {
    path: "/add-goal",
    component: lazy(() => import("../components/goals/edit")),
  },
  {
    path: "/edit-goal",
    component: lazy(() => import("../components/goals/edit")),
  },
  {
    path: "/view-task",
    component: lazy(() => import("../components/goals/view")),
  },
  {
    path: "/add-goal-routine",
    component: lazy(() => import("../components/goals/addRoutineTask/edit")),
  },
  {
    path: "/edit-goal-routine",
    component: lazy(() => import("../components/goals/addRoutineTask/edit")),
  },
  {
    path: "/request-connection-list",
    component: lazy(() =>
      import("../components/Explore-Market/Connection-request")
    ),
  },
  {
    path: "/availability-connection-list",
    component: lazy(() =>
      import("../components/Explore-Market/Availability-connection-request")
    ),
  },
  // {
  //   path: "/add-incident",
  //   component: lazy(() => import("../pages/healthCarer/incident/edit")),
  // },
  // {
  //   path: "/list-incident",
  //   component: lazy(() => import("../pages/healthCarer/incident/list")),
  // },
  // {
  //   path: "/edit-incident",
  //   component: lazy(() => import("../pages/healthCarer/incident/edit")),
  // },
  // {
  //   path: "/view-incident",
  //   component: lazy(() => import("../pages/healthCarer/incident/view")),
  // },
  {
    path: "/pdf-incident",
    component: lazy(() => import("../pages/healthCarer/incident/pdf")),
  },
  {
    path: "/closure-incident",
    component: lazy(() =>
      import("../pages/Supervisor/incident/incidentClosure")
    ),
  },
  {
    path: "/subscription-plans-list",
    component: lazy(() =>
      import("../pages/serviceProvider/subscription-plan/list")
    ),
  },
  {
    path: "/payment-gateway",
    component: lazy(() =>
      import("../pages/serviceProvider/subscription-plan/paymentGateway")
    ),
  },
  {
    path: "/explore-market-view-details",
    component: lazy(() =>
      import("../components/Explore-Market/View-Details/View-Details")
    ),
  },
  {
    path: "/explore-market-favorites",
    component: lazy(() => import("../pages/client/Exploremarket/Myfavorites")),
  },
  {
    path: "/view-request",
    component: lazy(() =>
      import("../pages/client/Exploremarket/View-Request/View-Details")
    ),
  },
  {
    path: "/sent-request-details",
    component: lazy(() =>
      import("../pages/client/Sent Request View/sentViewDetails")
    ),
  },
  {
    path: "/recieved-request-detail",
    component: lazy(() =>
      import("../pages/client/RecievedRequestView/RecievedRequestDetails")
    ),
  },
  {
    path: "/request-document-list",
    component: lazy(() =>
      import("../components/Explore-Market/Client-document-req")
    ),
  },
  {
    path: "/bar-chart-goal",
    component: lazy(() => import("../components/goals/barChart")),
  },
  {
    path: "/task-list",
    component: lazy(() => import("../pages/client/task/Tasklist")),
  },
  {
    path: "/task-add",
    component: lazy(() => import("../pages/client/task/edit")),
  },
  {
    path: "/view-task-list",
    component: lazy(() => import("../pages/client/task/viewTasklist")),
  },
  {
    path: "/task-edit",
    component: lazy(() => import("../pages/client/task/edit")),
  },
  {
    path: "/community-access-provider-add",
    component: lazy(() =>
      import("../pages/superAdmin/community-accessprovider/add")
    ),
  },
  {
    path: "/community-access-provider-list",
    component: lazy(() =>
      import("../pages/superAdmin/community-accessprovider/list")
    ),
  },
  {
    path: "/list-conflict",
    component: lazy(() => import("../pages/superAdmin/conflict/list")),
  },
  {
    path: "/add-conflict",
    component: lazy(() => import("../pages/superAdmin/conflict/edit")),
  },
  {
    path: "/edit-conflict",
    component: lazy(() => import("../pages/superAdmin/conflict/edit")),
  },
  // {
  //   path: "/list-complaint",
  //   component: lazy(() => import("../pages/superAdmin/complaint/list")),
  // },
  // {
  //   path: "/add-complaint",
  //   component: lazy(() => import("../pages/superAdmin/complaint/edit")),
  // },
  // {
  //   path: "/edit-complaint",
  //   component: lazy(() => import("../pages/superAdmin/complaint/edit")),
  // },
  {
    path: "/list-complaint",
    component: lazy(() => import("../pages/superAdmin/complaintClient/list")),
  },
  {
    path: "/add-complaint",
    component: lazy(() => import("../pages/superAdmin/complaintClient/edit")),
  },
  {
    path: "/edit-complaint",
    component: lazy(() => import("../pages/superAdmin/complaintClient/edit")),
  },
  {
    path: "/list-funds-management",
    component: lazy(() =>
      import("../pages/superAdmin/FundManagement/Fund-Management")
    ),
  },
  {
    path: "/medical-history-pdf",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/medicalHistoryPdf")
    ),
  },
  {
    path: "/list-medical-history",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },
  {
    path: "/list-medication-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },
  {
    path: "/list-prn-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },
  {
    path: "/medical-history-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medical-History")
    ),
  },
  {
    path: "/add-medical-history-dev",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Add-Medical-History"
      )
    ),
  },
  // {
  //   path: "/edit-medical-history-dev",
  //   component: lazy(() =>
  //     import(
  //       "../pages/superAdmin/Medication-management-dev/Add-Medical-History"
  //     )
  //   ),
  // },
  {
    path: "/view-medical-history-dev",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/View-Medical-History"
      )
    ),
  },
  {
    path: "/medication-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medication-chart")
    ),
  },
  {
    path: "/add-medication-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Add-Medication")
    ),
  },
  // {
  //   path: "/edit-medication-dev",
  //   component: lazy(() =>
  //     import("../pages/superAdmin/Medication-management-dev/Add-Medication")
  //   ),
  // },
  {
    path: "/view-medication-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/View-Medication")
    ),
  },
  {
    path: "/prn-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medication-chart")
    ),
  },
  {
    path: "/list-family-member",
    component: lazy(() =>
      import("../pages/superAdmin/family-member-client/list")
    ),
  },
  {
    path: "/view-family-member",
    component: lazy(() =>
      import("../pages/superAdmin/family-member-client/view")
    ),
  },
  {
    path: "/edit-family-member",
    component: lazy(() =>
      import("../pages/superAdmin/family-member-client/edit")
    ),
  },
  {
    path: "/attendance",
    component: lazy(() =>
      import("../pages/CommanComponant/Attendance/Attendance")
    ),
  },
  {
    path: "/shift-notes-list",
    component: lazy(() =>
      import("../pages/superAdmin/shift-notes/shiftNoteclientlist.js")
    ),
  },
  {
    path: "/view-shift-notes",
    component: lazy(() => import("../pages/superAdmin/shiftNotes/view.js")),
  },
  {
    path: "/edit-attendance",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Reports/timeAndAttendance/TimeCardTracking.js"
      )
    ),
  },

  {
    path: "/pay-run-attendance",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Reports/timeAndAttendance/payRunAttandance.js"
      )
    ),
  },

  {
    path: "/add-family-member",
    component: lazy(() =>
      import("../pages/superAdmin/family-member-client/edit")
    ),
  },

  {
    path: "/edit-family-member",
    component: lazy(() =>
      import("../pages/superAdmin/family-member-client/edit")
    ),
  },
  {
    path: "/profit-and-loss",
    component: lazy(() => import("../pages/superAdmin/Profit/Profit.js")),
  },

  {
    path: "/view-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/view.js")),
  },
  {
    path: "/pdf-pay-run",
    component: lazy(() => import("../pages/superAdmin/PayRun/Pdf")),
  },
];

export { AllCommanRoutes };
