import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import "../../assets/css/dashboard.css";
import "../../assets/css/Appointment.css";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import sleepOver from "../../assets/images/sleep-icon.png";
import HealthWeekPopover from "./HealthWeek/HealthWeekPopover";
import WeekCell from "./HealthWeek/WeekCell";
import FortnightlyCell from "./HealthWeek/FortnightlyCell";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAllLocation, getAllHcWeeklyAvailability } from "../../api";
import setupAxios from "../../api/axios";
import FilterStore from "./store/FilterStore";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { FieldsListLoading } from "../../pages/components/loading/FieldsListLoading";
import nodata from "../../assets/images/nodata.png";
import { Stack } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

export default function WeeklyTable({ data, latValue, lngValue, maxDistance }) {
  const isRenderFromViewPage = data?.isRenderFromViewPage;

  const navigate = useNavigate();

  const {
    resetFields,
    setField,
    searchTerm,
    slotType,
    selectedCity,
    selectedShift,
    isShowAllHc,
  } = FilterStore();

  const [isActive, setActive] = useState(false);
  let userData = JSON.parse(localStorage.getItem("userData"));
  let userId = userData?._id;
  const userTypeName = userData?.userTypeName;

  let userTypeUrl = "super-admin";
  if (userTypeName === "Service Provider") {
    userTypeUrl = "service-provider";
  } else if (userTypeName === "Support Coordinator") {
    userTypeUrl = "support-coordinator";
  } else if (userTypeName === "Admin") {
    userTypeUrl = "admin";
  }

  const addAppointment = (e, date, startTime, endTime, healthCarerId) => {
    // console.log("check healthCarerId", healthCarerId);
    // return false;

    e.preventDefault();
    const state = {
      healthCarerId: healthCarerId,
      date: date,
      startTime: startTime,
      endTime: endTime,
      back: "Add New",
    };
    navigate(`/${userTypeUrl}/add-appointment`, { state });
  };

  function isDateInPast(date) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00)

    const selectedDate = new Date(date);
    selectedDate.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00)

    return selectedDate < currentDate;
  }

  const employeeTimeFields = (
    startTime,
    endTime,
    startDate,
    healthCarerId,
    isAppoinmentPresent,
    isLeavePresent,
    shiftName
  ) => {
    return (
      <>
        <div
          className={`calender-client-cell employeeTime ${
            shiftName === "Sleepover" ||
            (startTime && endTime) || // Condition for startTime and endTime
            ((userData?.role === "superAdmin" ||
              userData?.role === "serviceProvider" ||
              userData?.role === "admin") &&
              !isDateInPast(startDate) &&
              !isLeavePresent &&
              !isAppoinmentPresent)
              ? "calender-view-primary"
              : ""
          }`}
        >
          <div>
            <Box className="employee-detail">
              {shiftName === "Sleepover" ? (
                <>
                  <img
                    src={sleepOver}
                    className="health-calender-profile-icon"
                  />
                  <span> Sleepover</span>
                </>
              ) : (
                <span>
                  {" "}
                  {startTime && endTime ? `${startTime} - ${endTime}` : ""}
                </span>
              )}
              {/* Shubham s Added condition for SP and Admin Aparna ma'am told that to add */}
              {(userData?.role === "superAdmin" ||
                userData?.role === "serviceProvider" ||
                userData?.role === "admin") &&
              !isDateInPast(startDate) ? (
                isLeavePresent === false ? (
                  isAppoinmentPresent === false ? (
                    <Box
                      className="calender-add-icon position-relative"
                      onClick={(e) =>
                        addAppointment(
                          e,
                          startDate,
                          startTime,
                          endTime,
                          healthCarerId
                        )
                      }
                    >
                      {/* <AddCircleIcon className="calender-add-icon-color" /> */}
                      <AddIcon sx={{ color: "#15a07d" }} fontSize="small" />

                      {/* employee-popover */}

                      <Box
                        className="table-icon-popover d-flex align-items-center 
                 justify-content-center"
                      >
                        {/* <AddCircleIcon className="calender-add-icon-color add-svg" />{" "} */}
                        <AddIcon sx={{ color: "#15a07d" }} fontSize="small" />
                        <Typography className="table-width-custom">
                          Add Appointment
                        </Typography>
                        <ArrowDropDownIcon className="calender-add-popover employee-down-svg calender-add-popover-icon" />
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : // "-"
              shiftName !== "Sleepover" ? (
                "-"
              ) : (
                ""
              )}
            </Box>
          </div>
        </div>
      </>
    );
  };

  const employeeTimeFieldsFortnightly = (
    startTime,
    endTime,
    startDate,
    healthCarerId,
    isAppoinmentPresent,
    isLeavePresent,
    shiftName
  ) => {
    return (
      <>
        <div className="calender-client-cell employeeTime calender-view-primary ">
          <div>
            <Box className="employee-detail">
              {shiftName === "Sleepover" ? (
                <>
                  <img
                    src={sleepOver}
                    className="health-calender-profile-icon"
                  />
                  <span> Sleepover</span>
                </>
              ) : (
                <span>
                  {" "}
                  {startTime} - {endTime}
                </span>
              )}
              {/* Shubham s Added condition for SP and Admin Aparna ma'am told that to add */}
              {(userData?.role === "superAdmin" ||
                userData?.role === "serviceProvider") &&
              !isDateInPast(startDate) ? (
                isLeavePresent === false ? (
                  isAppoinmentPresent === false ? (
                    <Box
                      className="calender-add-icon position-relative"
                      onClick={(e) =>
                        addAppointment(
                          e,
                          startDate,
                          startTime,
                          endTime,
                          healthCarerId
                        )
                      }
                    >
                      {/* <AddCircleIcon className="calender-add-icon-color" /> */}
                      <AddIcon sx={{ color: "#15a07d" }} fontSize="small" />

                      {/* employee-popover */}

                      <Box
                        className="table-icon-popover d-flex align-items-center 
                 justify-content-center"
                      >
                        {/* <AddCircleIcon className="calender-add-icon-color add-svg" />{" "} */}
                        <AddIcon sx={{ color: "#15a07d" }} fontSize="small" />

                        <Typography className="table-width-custom">
                          Add Appointment
                        </Typography>
                        <ArrowDropDownIcon className="calender-add-popover employee-down-svg calender-add-popover-icon" />
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Box>
          </div>
        </div>
      </>
    );
  };

  function convertToTitleCase(sentence) {
    return sentence.toLowerCase().replace(/(^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  const getLocation = async () => {
    await axios
      .get(getAllLocation)
      .then(async (res) => {
        const cityData = [];
        res.data.data.length > 0 &&
          res.data.data.map((val) => {
            if (
              val?.location !== undefined &&
              val?.location !== null &&
              val?.location !== ""
            )
              cityData.push(val?.location);
          });

        const uniqueCitiesSet = new Set(cityData);
        const uniqueCitiesArray = [...uniqueCitiesSet];
        const idLabelFormatArray = uniqueCitiesArray.map((city, index) => ({
          id: city,
          label: convertToTitleCase(city),
        }));

        setField("cityList", idLabelFormatArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [healthCarerWeeklyData, setHealthCarerWeeklyData] = useState([]);
  const [weeklyDate, setWeeklyDates] = useState([]);
  const [fortnightlyDates, setFortnightly] = useState([]);
  const [wlDate, setWlDate] = useState([]);
  const [flDate, setFlDate] = useState([]);
  const [startOfWeekdate, setstartOfWeekdate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const getAllHcWeeklyAl = async () => {
    setIsLoading(true);
    // setupAxios(axios);
    let newUserId = userId;
    let role = userData?.role;
    if (userTypeName === "Admin") {
      newUserId = userData?.createdBy;
    }
    if (isRenderFromViewPage === true) {
      role = data?.clientDetail?.userType;
      newUserId = data?.clientDetail?._id;
    }
    const { startDate, endDate } = await getDateRange("Week");
    await axios
      .get(
        `${getAllHcWeeklyAvailability}?start=${startDate}&isShowAllHc=${isShowAllHc}&role=${role}&userId=${newUserId}&end=${endDate}&shiftTypeId=${selectedShift}&searchTerm=${searchTerm}&city=${selectedCity}&isSoleTrader=${userData?.isSoleTrader}&createdByUserType=${userData?.createdByUserType}&createdBy=${userData?.createdBy}&lat=${latValue}&lng=${lngValue}&maxDistance=${maxDistance}&isRenderFromViewPage=${isRenderFromViewPage}`
      )
      .then(async (res) => {
        setHealthCarerWeeklyData(res?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getNextMondayDate = () => {
    const currentDate = new Date(startOfWeekdate);
    const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

    // Calculate the number of days until the next Monday
    const daysUntilMonday = currentDay === 0 ? 1 : 8 - currentDay;

    // Set the date to the next Monday
    const nextMondayDate = new Date(currentDate);
    nextMondayDate.setDate(currentDate.getDate() + daysUntilMonday);

    // Format the date as "YYYY-MM-DD"
    const year = nextMondayDate.getFullYear();
    const month = String(nextMondayDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const dayOfMonth = String(nextMondayDate.getDate()).padStart(2, "0");
    const formattedNextMonday = `${year}-${month}-${dayOfMonth}`;
    return formattedNextMonday;
  };

  const getFortnightlyDays = () => {
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const currentDate = new Date(getNextMondayDate());
    const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

    const dateArr = [];
    const formattedDates = daysOfWeek.map((day, index) => {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + index - currentDay + 1); // Adding 1 to correct the Sunday date
      const formattedDate = `${date.getDate()} ${day}`;

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const dayOfMonth = String(date.getDate()).padStart(2, "0");
      const formattedDate1 = `${year}-${month}-${dayOfMonth}`;

      dateArr.push(formattedDate1);

      return formattedDate;
    });

    setFlDate(dateArr);
    setFortnightly(formattedDates);
  };

  const getWeekDays = () => {
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const currentDate = new Date(startOfWeekdate);
    const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

    const dateArr = [];
    const formattedDates = daysOfWeek.map((day, index) => {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + index - currentDay + 1); // Adding 1 to correct the Sunday date
      const formattedDate = `${date.getDate()} ${day}`;

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const dayOfMonth = String(date.getDate()).padStart(2, "0");
      const formattedDate1 = `${year}-${month}-${dayOfMonth}`;

      dateArr.push(formattedDate1);

      return formattedDate;
    });

    setWlDate(dateArr);
    setWeeklyDates(formattedDates);
  };

  useEffect(() => {
    if (slotType === "Fortnightly") {
      getFortnightlyDays();
    }
    getWeekDays();
    getAllHcWeeklyAl();
  }, [
    searchTerm,
    selectedShift,
    selectedCity,
    isShowAllHc,
    slotType,
    startOfWeekdate,
    latValue,
    lngValue,
    maxDistance,
  ]);

  //get date range
  const getDateRange = async (rangeType) => {
    const currentDate = new Date(startOfWeekdate);
    let startDate, endDate;

    switch (rangeType) {
      case "Today":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        break;
      case "Week":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay() + 1
        );
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay() + 7
        );
        break;

      case "Fortnightly":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay() + 1
        );
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay() + 14
        );
        break;

      default:
        startDate = null;
        endDate = null;
    }

    return { startDate, endDate };
  };

  useEffect(() => {
    getLocation();
  }, []);

  const getInitialData = (row, day, startDate) => {
    return row?.Weekly.map((val, i) => {
      if (val?.week?.[day]?.length > 0) {
        return val?.week?.[day]?.map((shift, index) => {
          if (index === 0 && i === 0) {
            return (
              <Box key={index}>
                {employeeTimeFields(
                  shift?.startTime,
                  shift?.endTime,
                  startDate,
                  row?.healthCarerId,
                  shift?.isAppoinmentPresent,
                  shift?.isLeavePresent,
                  val?.shiftDetails?.name
                )}
              </Box>
            );
          }
          return null;
        });
      }
      return null;
    });
  };

  const getFortNightlyInitialData = (row, day, startDate) => {
    return row?.Fortnightly.map((val, i) => {
      if (val?.NextWeek?.[day]?.length > 0) {
        return val?.NextWeek?.[day]?.map((shift, index) => {
          if (index === 0 && i === 0) {
            return (
              <Box key={index}>
                {employeeTimeFieldsFortnightly(
                  shift?.startTime,
                  shift?.endTime,
                  startDate,
                  row?.healthCarerId,
                  shift?.isAppoinmentPresent,
                  shift?.isLeavePresent,
                  val?.shiftDetails?.name
                )}
              </Box>
            );
          }
          return null;
        });
      }
      return null;
    });
  };

  const backButtonClick = () => {
    const startDate = new Date(wlDate[0]);
    const oneWeekAgo = new Date(startDate);
    oneWeekAgo.setDate(startDate.getDate() - 7);

    const oneWeekAgoString = oneWeekAgo.toISOString().split("T")[0];
    setstartOfWeekdate(oneWeekAgoString);
  };
  const nextButtonClick = () => {
    const startDate = new Date(wlDate[0]);
    const oneWeekAgo = new Date(startDate);
    oneWeekAgo.setDate(startDate.getDate() + 7);

    const oneWeekAgoString = oneWeekAgo.toISOString().split("T")[0];
    setstartOfWeekdate(oneWeekAgoString);
    //console.log(wlDate[0], oneWeekAgoString);
  };

  const searchItems = (searchValue) => {
    setField("searchTerm", searchValue);
  };

  const [openPopoverIndex, setOpenPopoverIndex] = useState(null);

  const handleTogglePopover = (index) => {
    setOpenPopoverIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <Box id="health-week-table" className="ml-3">
        <TableContainer component={Paper} className="horizontal">
          <Table
            id="calender"
            aria-label="simple table"
            className="week-table-border border-none"
          >
            <TableHead>
              <TableRow>
                <TableCell className="employee-search-th week-table-border-right employee-th">
                  <Box className="employee-search">
                    <TextField
                      size="small"
                      placeholder="Search"
                      id="outlined-start-adornment"
                      className="search-appointment"
                      sx={{ width: "100%" }}
                      onChange={(e) => searchItems(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {" "}
                            <SearchIcon />{" "}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </TableCell>

                <TableCell
                  align="right"
                  className="employee-th employee-search-th"
                >
                  {weeklyDate.length > 0 && weeklyDate !== undefined
                    ? weeklyDate[0]
                    : ""}
                </TableCell>
                <TableCell
                  align="right"
                  className="employee-th employee-search-th"
                >
                  {weeklyDate.length > 0 && weeklyDate !== undefined
                    ? weeklyDate[1]
                    : ""}
                </TableCell>
                <TableCell
                  align="right"
                  className="employee-th employee-search-th"
                >
                  {weeklyDate.length > 0 && weeklyDate !== undefined
                    ? weeklyDate[2]
                    : ""}
                </TableCell>
                <TableCell
                  align="right"
                  className="employee-th employee-search-th"
                >
                  {weeklyDate.length > 0 && weeklyDate !== undefined
                    ? weeklyDate[3]
                    : ""}
                </TableCell>
                <TableCell
                  align="right"
                  className="employee-th employee-search-th"
                >
                  {weeklyDate.length > 0 && weeklyDate !== undefined
                    ? weeklyDate[4]
                    : ""}
                </TableCell>
                <TableCell
                  align="right"
                  className="employee-th employee-search-th"
                >
                  {weeklyDate.length > 0 && weeklyDate !== undefined
                    ? weeklyDate[5]
                    : ""}
                </TableCell>
                <TableCell
                  align="right"
                  className="employee-th employee-search-th"
                >
                  {weeklyDate.length > 0 && weeklyDate !== undefined
                    ? weeklyDate[6]
                    : ""}
                  {slotType !== "Fortnightly" ? (
                    <>
                      <ArrowCircleLeftIcon onClick={backButtonClick} />
                      <ArrowCircleRightIcon onClick={nextButtonClick} />
                    </>
                  ) : (
                    ""
                  )}
                </TableCell>

                {slotType === "Fortnightly" ? (
                  <>
                    <TableCell
                      align="right"
                      className="employee-th employee-search-th"
                    >
                      {fortnightlyDates.length > 0 &&
                      fortnightlyDates !== undefined
                        ? fortnightlyDates[0]
                        : ""}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="employee-th employee-search-th"
                    >
                      {fortnightlyDates.length > 0 &&
                      fortnightlyDates !== undefined
                        ? fortnightlyDates[1]
                        : ""}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="employee-th employee-search-th"
                    >
                      {fortnightlyDates.length > 0 &&
                      fortnightlyDates !== undefined
                        ? fortnightlyDates[2]
                        : ""}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="employee-th employee-search-th"
                    >
                      {fortnightlyDates.length > 0 &&
                      fortnightlyDates !== undefined
                        ? fortnightlyDates[3]
                        : ""}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="employee-th employee-search-th"
                    >
                      {fortnightlyDates.length > 0 &&
                      fortnightlyDates !== undefined
                        ? fortnightlyDates[4]
                        : ""}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="employee-th employee-search-th"
                    >
                      {fortnightlyDates.length > 0 &&
                      fortnightlyDates !== undefined
                        ? fortnightlyDates[5]
                        : ""}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="employee-th employee-search-th"
                    >
                      {fortnightlyDates.length > 0 &&
                      fortnightlyDates !== undefined
                        ? fortnightlyDates[6]
                        : ""}
                      <ArrowCircleLeftIcon onClick={backButtonClick} />
                      <ArrowCircleRightIcon onClick={nextButtonClick} />
                    </TableCell>{" "}
                  </>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>
            <TableBody className="view-details-table">
              {healthCarerWeeklyData.length > 0 ? (
                healthCarerWeeklyData.map((row, index) => (
                  <TableRow
                    key={row?.fullName}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      className="week-table-border-right search-cell"
                      component="th"
                      scope="row"
                      sx={{ width: 250 }}
                    >
                      <Box className="cursor-pointer">
                        {/* {row?.fullName.substring(0, 15)} */}
                        {row?.fullName}
                      </Box>
                      <HealthWeekPopover
                        data={row}
                        key={index}
                        isOpen={openPopoverIndex === index}
                        onToggle={() => handleTogglePopover(index)}
                      />
                    </TableCell>

                    <TableCell align="right" className="employee-td">
                      <Box className="employee-table-height">
                        {getInitialData(
                          row,
                          "Mon",
                          wlDate.length > 0 && wlDate !== undefined
                            ? wlDate[0]
                            : null
                        )}
                        <WeekCell
                          data={row?.Weekly}
                          day="Mon"
                          _id={row._id + "Mon" + index}
                          startDate={
                            wlDate.length > 0 && wlDate !== undefined
                              ? wlDate[0]
                              : null
                          }
                          healthCarerId={row?.healthCarerId}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="right" className="employee-td">
                      <Box className="employee-table-height">
                        {" "}
                        {getInitialData(
                          row,
                          "Tue",
                          wlDate.length > 0 && wlDate !== undefined
                            ? wlDate[1]
                            : null
                        )}
                        <WeekCell
                          healthCarerId={row?.healthCarerId}
                          data={row?.Weekly}
                          day="Tue"
                          _id={row._id + "Tue" + index}
                          startDate={
                            wlDate.length > 0 && wlDate !== undefined
                              ? wlDate[1]
                              : null
                          }
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="right" className="employee-td">
                      <Box className="employee-table-height">
                        {" "}
                        {getInitialData(
                          row,
                          "Wed",
                          wlDate.length > 0 && wlDate !== undefined
                            ? wlDate[2]
                            : null
                        )}
                        <WeekCell
                          healthCarerId={row?.healthCarerId}
                          data={row?.Weekly}
                          day="Wed"
                          _id={row._id + "Wed" + index}
                          startDate={
                            wlDate.length > 0 && wlDate !== undefined
                              ? wlDate[2]
                              : null
                          }
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="right" className="employee-td">
                      <Box className="employee-table-height">
                        {" "}
                        {getInitialData(
                          row,
                          "Thu",
                          wlDate.length > 0 && wlDate !== undefined
                            ? wlDate[3]
                            : null
                        )}
                        <WeekCell
                          healthCarerId={row?.healthCarerId}
                          data={row?.Weekly}
                          day="Thu"
                          _id={row._id + "Thu" + index}
                          startDate={
                            wlDate.length > 0 && wlDate !== undefined
                              ? wlDate[3]
                              : null
                          }
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="right" className="employee-td">
                      <Box className="employee-table-height">
                        {" "}
                        {getInitialData(
                          row,
                          "Fri",
                          wlDate.length > 0 && wlDate !== undefined
                            ? wlDate[4]
                            : null
                        )}
                        <WeekCell
                          healthCarerId={row?.healthCarerId}
                          data={row?.Weekly}
                          day="Fri"
                          _id={row._id + "Fri" + index}
                          startDate={
                            wlDate.length > 0 && wlDate !== undefined
                              ? wlDate[4]
                              : null
                          }
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="right" className="employee-td">
                      <Box className="employee-table-height">
                        {" "}
                        {getInitialData(
                          row,
                          "Sat",
                          wlDate.length > 0 && wlDate !== undefined
                            ? wlDate[5]
                            : null
                        )}
                        <WeekCell
                          healthCarerId={row?.healthCarerId}
                          data={row?.Weekly}
                          day="Sat"
                          _id={row._id + "Sat" + index}
                          startDate={
                            wlDate.length > 0 && wlDate !== undefined
                              ? wlDate[5]
                              : null
                          }
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="right" className="employee-td">
                      <Box className="employee-table-height">
                        {getInitialData(
                          row,
                          "Sun",
                          wlDate.length > 0 && wlDate !== undefined
                            ? wlDate[6]
                            : null
                        )}
                        <WeekCell
                          healthCarerId={row?.healthCarerId}
                          data={row?.Weekly}
                          day="Sun"
                          _id={row._id + "Sun" + index}
                          startDate={
                            wlDate.length > 0 && wlDate !== undefined
                              ? wlDate[6]
                              : null
                          }
                        />
                      </Box>
                    </TableCell>

                    {slotType === "Fortnightly" ? (
                      <>
                        <TableCell align="right" className="employee-td">
                          <Box className="employee-table-height">
                            {getFortNightlyInitialData(
                              row,
                              "Mon",
                              flDate.length > 0 && flDate !== undefined
                                ? flDate[0]
                                : null
                            )}
                            <FortnightlyCell
                              healthCarerId={row?.healthCarerId}
                              data={row?.Fortnightly}
                              day="Mon"
                              _id={row._id + "FlMon" + index}
                              startDate={
                                flDate.length > 0 && flDate !== undefined
                                  ? flDate[0]
                                  : null
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="right" className="employee-td">
                          <Box className="employee-table-height">
                            {" "}
                            {getFortNightlyInitialData(
                              row,
                              "Tue",
                              flDate.length > 0 && flDate !== undefined
                                ? flDate[1]
                                : null
                            )}
                            <FortnightlyCell
                              healthCarerId={row?.healthCarerId}
                              data={row?.Fortnightly}
                              day="Tue"
                              _id={row._id + "FlTue" + index}
                              startDate={
                                flDate.length > 0 && flDate !== undefined
                                  ? flDate[1]
                                  : null
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="right" className="employee-td">
                          <Box className="employee-table-height">
                            {" "}
                            {getFortNightlyInitialData(
                              row,
                              "Wed",
                              flDate.length > 0 && flDate !== undefined
                                ? flDate[2]
                                : null
                            )}
                            <FortnightlyCell
                              healthCarerId={row?.healthCarerId}
                              data={row?.Fortnightly}
                              day="Wed"
                              _id={row._id + "FlWed" + index}
                              startDate={
                                flDate.length > 0 && flDate !== undefined
                                  ? flDate[2]
                                  : null
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="right" className="employee-td">
                          <Box className="employee-table-height">
                            {" "}
                            {getFortNightlyInitialData(
                              row,
                              "Thu",
                              flDate.length > 0 && flDate !== undefined
                                ? flDate[3]
                                : null
                            )}
                            <FortnightlyCell
                              healthCarerId={row?.healthCarerId}
                              data={row?.Fortnightly}
                              day="Thu"
                              _id={row._id + "FlThu" + index}
                              startDate={
                                flDate.length > 0 && flDate !== undefined
                                  ? flDate[3]
                                  : null
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="right" className="employee-td">
                          <Box className="employee-table-height">
                            {" "}
                            {getFortNightlyInitialData(
                              row,
                              "Fri",
                              flDate.length > 0 && flDate !== undefined
                                ? flDate[4]
                                : null
                            )}
                            <FortnightlyCell
                              healthCarerId={row?.healthCarerId}
                              data={row?.Fortnightly}
                              day="Fri"
                              _id={row._id + "FlFri" + index}
                              startDate={
                                flDate.length > 0 && flDate !== undefined
                                  ? flDate[4]
                                  : null
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="right" className="employee-td">
                          <Box className="employee-table-height">
                            {" "}
                            {getFortNightlyInitialData(
                              row,
                              "Sat",
                              flDate.length > 0 && flDate !== undefined
                                ? flDate[5]
                                : null
                            )}
                            <FortnightlyCell
                              healthCarerId={row?.healthCarerId}
                              data={row?.Fortnightly}
                              day="Sat"
                              _id={row._id + "FLSat" + index}
                              startDate={
                                flDate.length > 0 && flDate !== undefined
                                  ? flDate[5]
                                  : null
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="right" className="employee-td">
                          <Box className="employee-table-height">
                            {getFortNightlyInitialData(
                              row,
                              "Sun",
                              flDate.length > 0 && flDate !== undefined
                                ? flDate[6]
                                : null
                            )}
                            <FortnightlyCell
                              healthCarerId={row?.healthCarerId}
                              data={row?.Fortnightly}
                              day="Sun"
                              _id={row._id + "FlSun" + index}
                              startDate={
                                flDate.length > 0 && flDate !== undefined
                                  ? flDate[6]
                                  : null
                              }
                            />
                          </Box>
                        </TableCell>
                      </>
                    ) : (
                      ""
                    )}
                  </TableRow>
                ))
              ) : (
                <>
                  {" "}
                  <TableCell colSpan={slotType === "week" ? 8 : 15}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      className="no-data-available"
                      spacing={2}
                    >
                      <img src={nodata} />

                      <Typography component="div">
                        {" "}
                        No availability is found.{" "}
                      </Typography>
                    </Stack>
                  </TableCell>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && <FieldsListLoading />}
      </Box>
    </>
  );
}
