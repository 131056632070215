import React from "react";
import ReactPlayer from 'react-player'
// import Participants from '../../../src/assets/images/Participants.png'
import tabVideoImg from '../../assets/images/tabvideo-img.png'

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const Homevideo = () => {

  const handleDuration = (duration) => {
    console.log('Video duration:', duration);
  };

  return (
    <div className="videoPlayer">
      <ReactPlayer
        url="https://youtu.be/8So9OCr_HEA?si=afIzcLdeidiGqiR7"
        width="100%"
        height="650px"
        playing
        // onDuration="0.1"
        onDuration={handleDuration}  // Passing a function instead of a string
        playIcon={<button className="video-player-button"><PlayArrowIcon /></button>}
        light={tabVideoImg}
      />
    </div>
  );
};

export default Homevideo;
