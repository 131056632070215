import React, { useState } from "react";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/Appointment.css";
import Grid from "@mui/material/Grid";
// import axios from "axios";
import axios from "../../../api/axios";
import RequestStatus from "./RequestStatus.js";
import TopFilter from "./TopFilter.js";
import CalenderData from "./CalenderData";
import ShiftTypeFilter from "../../Explore-Market/View-Details/ShiftTypeFilter";
import WeeklyTable from "../../availability/WeeklyTable";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useEffect, useRef } from "react";
import { Button } from "@mui/material";
import { FieldsListLoading } from "../../../pages/components/loading/FieldsListLoading";
import {
  getAllLocation,
  getAllCalendarAvailability,
  updateRequestDetails,
  getDetailsBySendTo,
  addSendRequest,
  getsendRequestDetails,
  getAllRecievedRequestBySendByHC,
  limit,
  resendNotification,
  setIndvHCinAppt,
  getAllCalendarAvailabilityShift,
} from "../../../api";
import FilterStore from "../../availability/store/FilterStore";
import SendIcon from "@mui/icons-material/Send";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Link } from "react-router-dom";

function createData(name, sun, mon, tue, wed, thu, fri, sat) {
  return { name, sun, mon, tue, wed, thu, fri, sat };
}
let userData = JSON.parse(localStorage.getItem("userData"));
let userId = userData?._id;

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function AvailabilityCalender(props) {
  const sendRequestId = props?.props?.sendRequestId;
  const clientId = props?.props?.clientId;

  const [events, setEventData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [sentRequestDetails, setSentRequestDetails] = useState([]);
  const [access, setAccess] = useState(false);
  const [requestSent, setRequestSent] = useState([]);

  const {
    resetFields,
    setField,
    selectedCity,
    ApType2,
    month,
    startDate1,
    endDate1,
    slotType,
    showhide,
    searchTerm,
    selectedShift,
    requestStatus,
    cityList,
  } = FilterStore();

  const ref = React.createRef();

  let exploreMarketData = JSON.parse(localStorage.getItem("exploreMarket"));
  let hcData = JSON.parse(localStorage.getItem("exploreMarket"));

  let hcId = hcData?.hcId;
  //let hcId = props?.props?.hcId;
  let role = "healthCarer";

  useEffect(() => {
    HcAvailabilityList();
  }, [
    requestStatus,
    endDate1,
    startDate1,
    selectedStatus,
    ApType2,
    selectedCity,
    searchTerm,
    selectedShift,
  ]);

  const HcAvailabilityList = async () => {
    if (
      startDate1 &&
      startDate1 !== null &&
      startDate1 !== undefined &&
      endDate1 &&
      endDate1 !== null &&
      endDate1 !== undefined
    ) {
      console.log("check121 startDate1", startDate1);

      setIsLoading(true);

      await axios
        .get(
          `${getAllCalendarAvailabilityShift}?start=${startDate1}&type=findSupport&requestStatus=${requestStatus}&role=${role}&userId=${clientId}&end=${endDate1}&shiftTypeId=${selectedShift}&searchTerm=${searchTerm}&city=${selectedCity}`
        )
        .then((res) => {
          //setEventData(res?.data?.data);
          setEventData([...res.data.data]);
          console.log(
            "check121 res startDate1" + startDate1 + "",
            res?.data?.data
          );

          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error in axios request:", error);
          setIsLoading(false);
        });
    }
  };

  const getLocation = () => {
    axios
      .get(getAllLocation)
      .then(async (res) => {
        const cityData = [];
        res.data.data.length > 0 &&
          res.data.data.map((val) => {
            if (
              val?.location !== undefined &&
              val?.location !== null &&
              val?.location !== ""
            )
              cityData.push(val?.location);
          });

        const uniqueCitiesSet = new Set(cityData);

        // Convert the Set back to an array
        const uniqueCitiesArray = [...uniqueCitiesSet];

        const idLabelFormatArray = uniqueCitiesArray.map((city, index) => ({
          id: city,
          label: city,
        }));

        setField("cityList", idLabelFormatArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    console.log("check121 month", month);
    const { startDate, endDate } = getDateRange("Month", month);
    if (startDate !== null && endDate !== null) {
      setField("startDate1", startDate);
      setField("endDate1", endDate);
    }
    if (slotType === "" || slotType === undefined) {
      setField("slotType", "Month");
      setField("showhide", "Month");
    }
  }, [slotType, month]);

  useEffect(() => {
    const currentDate = new Date();
    const monthName = currentDate.toLocaleString("en-US", { month: "long" });
    const monthIndex = getMonthIndexFromName(monthName);
    const startDate = new Date(currentDate.getFullYear(), monthIndex, 1);
    const endDate = new Date(currentDate.getFullYear(), monthIndex + 1, 0);
    setField("startDate1", startDate);
    setField("endDate1", endDate);
  }, []);

  const getCurrentMonth = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[new Date().getMonth()];
  };

  const getCurrentMonthIndex = () => {
    return new Date().getMonth(); // Returns the current month index (0 for January, 1 for February, etc.)
  };
  //get date range
  const getDateRange = (rangeType, monthName) => {
    const currentDate = new Date();
    let startDate, endDate;
    switch (rangeType) {
      case "Today":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        break;
      case "Week":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay() + 1
        );
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay() + 7
        );
        break;

      case "Fortnightly":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay() + 1
        );
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay() + 14
        );
        break;

      case "Month":
        const monthIndex = getMonthIndexFromName(monthName);
        startDate = new Date(currentDate.getFullYear(), monthIndex, 1);
        endDate = new Date(currentDate.getFullYear(), monthIndex + 1, 0);
        break;

      default:
        startDate = null;
        endDate = null;
    }

    return { startDate, endDate };
  };

  useEffect(() => {
    if (
      userData !== null &&
      userData !== undefined &&
      hcId !== undefined &&
      hcId !== null
    ) {
      getSendRequestDetailsFunction();
    }
  }, []);

  const getSendRequestDetailsFunction = async () => {
    try {
      if (
        userData !== null &&
        userData !== undefined &&
        hcId !== undefined &&
        hcId !== null
      ) {
        const res = await axios.get(
          `${getAllRecievedRequestBySendByHC}/${hcId}?searchTerm=${searchTerm}&page=1&items_per_page=${limit}`
        );
        let dataval = res?.data?.data?.docs;
        setSentRequestDetails(dataval);
        // documentAccessStatus
        setAccess(dataval?.documentRequest);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userData !== undefined && userData !== null) {
      getSendRequestDetails();
    }
  }, []);

  const getSendRequestDetails = async () => {
    try {
      if (userData !== null && userData !== undefined) {
        const res = await axios.get(
          `${getsendRequestDetails}/${userData?._id}`
        );
        const sendToList = res?.data?.data.map((item) => item.sendTo);
        setRequestSent(sendToList);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getMonthIndexFromName = (monthName) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames.findIndex(
      (name) => name.toLowerCase() === monthName.toLowerCase()
    );
  };

  const goToNextDay = async () => {
    let calendarApi = ref.current.getApi();
    calendarApi.next();
    setField(
      "startDate1",
      calendarApi.currentData.dateProfile.currentRange.start
    );
    setField("endDate1", calendarApi.currentData.dateProfile.currentRange.end);
  };

  const goToPreviousDay = async () => {
    let calendarApi = ref.current.getApi();
    calendarApi.prev();
    setField(
      "startDate1",
      calendarApi.currentData.dateProfile.currentRange.start
    );
    setField("endDate1", calendarApi.currentData.dateProfile.currentRange.end);
  };

  const goToDay = async () => {
    let calendarApi = ref.current.getApi();
    calendarApi.today();
    setField(
      "startDate1",
      calendarApi.currentData.dateProfile.currentRange.start
    );
    setField("endDate1", calendarApi.currentData.dateProfile.currentRange.end);
  };

  useEffect(() => {
    if (startDate1 && ref.current) {
      const calendarApi = ref.current.getApi();
      calendarApi.gotoDate(new Date(startDate1)); // Update to the new start date
    }
  }, [startDate1]);

  const searchItems = (searchValue) => {
    setField("searchTerm", searchValue);
  };

  const getSendRequestDetailFunction = async () => {
    try {
      if (
        userData !== null &&
        userData !== undefined &&
        hcId !== undefined &&
        hcId !== null
      ) {
        const res = await axios.get(
          `${getDetailsBySendTo}/${hcId}/${userData?._id}`
        );
        let dataval = res?.data?.data;
        setSentRequestDetails(dataval);
        // documentAccessStatus
        setAccess(dataval?.documentRequest);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    const {
      startTime,
      endTime,
      type,
      date,
      _id,
      slotId,
      clientId,
      requestStatus,
      shiftName,
      requestedIndHCId,
    } = event.extendedProps;

    const handleSendIconClick = () => {
      // Create an object with the data you want to send

      if (type == "findSupport") {
        if (userData?.isSoleTrader === true) {
          axios
            .put(
              `${setIndvHCinAppt}/${_id}/${userData?._id}/send/findSupport/${clientId}`,
              {
                date: date,
                startTime: startTime,
                endTime: endTime,
                shiftName: shiftName,
              }
            )
            .then((res) => {
              toast.success(res.data.message);
              HcAvailabilityList();
              getSendRequestDetailsFunction();
              // setTimeout(() => {
              //   navigate("/health-carer/appointment-connection-list");
              // }, 500);
            })
            .catch((error) => {
              toast.error("Something went wrong");
            });
        } else {
          toast.error("Only ABN Holders Can Send Request");
        }
      } else {
        const sendData = {
          availabilityData: {
            startTime: startTime,
            endTime: endTime,
            date: date,
            shiftType: shiftName,
            slotId: slotId,
            sendBy: userData?._id,
            sendTo: hcId,
          },
        };

        if (sendRequestId !== undefined && sendRequestId !== null) {
          axios
            .put(`${updateRequestDetails}/${sendRequestId}`, sendData)
            .then((res) => {
              if (res.data.success === true) {
                toast.success(res.data.message);
                HcAvailabilityList();
                getSendRequestDetailsFunction();
              } else {
                toast.error(res.data.message);
              }
            })
            .catch((error) => {
              toast.error("Something went wrong");
            });
        } else {
          let payload = {
            sendBy: userData?._id,
            sendTo: hcId,
            createdBy: userData?._id,
            updatedBy: userData?._id,
            availabilityData: sendData.availabilityData,
          };

          axios
            .post(`${addSendRequest}`, payload)
            .then((res) => {
              toast.success(res.data.message);
              getSendRequestDetailsFunction();
              HcAvailabilityList();
            })
            .catch((error) => {
              toast.error("Something went wrong");
            });
        }
      }
    };

    const refereshButton = () => {
      axios
        .get(
          `${resendNotification}?id=${clientId}&startTime=${startTime}&endTime=${endTime}&date=${date}&shiftName=${shiftName}`
        )
        .then((res) => {
          toast.success(res.data.message);
          HcAvailabilityList();
          getSendRequestDetailsFunction();
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    };
    const isVisible = (date, startTime) => {
      const currentDate = new Date();
      const targetDate = new Date(date);

      if (currentDate.toDateString() === targetDate.toDateString()) {
        if (startTime !== undefined) {
          const [targetHour, targetMinute] = startTime?.split(":")?.map(Number);
          const targetTime = new Date(
            targetDate.setHours(targetHour, targetMinute)
          );
          return currentDate <= targetTime;
        }
      }

      return currentDate <= targetDate;
    };

    let calendarCellClassName;
    if (requestStatus === "Created" && isVisible(date, startTime)) {
      calendarCellClassName = "view-details-status-pending-cl";
    } else if (requestStatus === "Appointed") {
      calendarCellClassName = "view-details-status-appointed-cl";
    } else if (requestStatus === "Cancelled") {
      calendarCellClassName = "view-details-status-cancelled-cl";
    } else if (requestStatus === "Accepted") {
      calendarCellClassName = "view-details-status-accepted-cl";
    } else if (requestStatus === "Rejected") {
      calendarCellClassName = "view-details-status-rejected-cl";
    } else if (requestStatus == "Pending") {
      calendarCellClassName = userId
        ? "view-details-calender-nofield-cl"
        : "view-details-status-pending-cl";
    } else {
      calendarCellClassName = "view-details-calender-nofield-cl";
    }

    return (
      <>
        <Box
          className={`view-details-calender-main position-relative view-details-calender-field-cl ${calendarCellClassName}`}
        >
          <Box>
            <CalenderData eventInfo={eventInfo} />
          </Box>
          {console.log("check requestStatus", requestStatus)}
          {console.log("check userData?.role", userData?.role)}
          {console.log("check date", date)}
          {console.log("check startTime", startTime)}
          {console.log("check requestedIndHCId", requestedIndHCId)}

          <Box className={`clientView-details-calender-iconField`}>
            {requestStatus === "Appointed" ? (
              <div style={{ marginLeft: "-44px" }} className="view-booked-text">
                Booked
              </div>
            ) : requestStatus == "Created" || requestStatus == "Pending" ? (
              isVisible(date, startTime) ? (
                userData?.role == "healthCarer" ? (
                  <div className="" onClick={handleSendIconClick}>
                    <SendIcon fontSize="small" />
                  </div>
                ) : (
                  <Link
                    to="/existing-user"
                    state={{
                      setIndvHCinAppt: setIndvHCinAppt,
                      _id: _id,
                      type: "send",
                      types: "findSupport",
                      clientId: clientId,
                      date: date,
                      startTime: startTime,
                      endTime: endTime,
                      shiftName: shiftName,
                    }}
                    className="explore-view-link"
                  >
                    <SendIcon fontSize="small" />
                  </Link>
                )
              ) : null // Hide the SendIcon if the condition is not met
            ) : calendarCellClassName == "view-details-status-accepted-cl" ? (
              <div className="" onClick={refereshButton}>
                <RefreshIcon />
              </div>
            ) : requestedIndHCId?.includes(userData?._id) ? (
              <div className="" onClick={refereshButton}>
                <RefreshIcon />
              </div>
            ) : (
              <Link
                to="/existing-user"
                state={{
                  setIndvHCinAppt: setIndvHCinAppt,
                  _id: _id,
                  type: "send",
                  types: "findSupport",
                  clientId: clientId,
                  date: date,
                  startTime: startTime,
                  endTime: endTime,
                  shiftName: shiftName,
                }}
                className="explore-view-link"
              >
                <SendIcon fontSize="small" />
              </Link>
            )}

            {/* sunny code is commented */}
            {/* {sentRequestDetails?.every((detail) => {
              console.log("detail?.availabilityData", detail?.availabilityData);
              const availabilityDate = detail?.availabilityData?.date;

              if (!availabilityDate) {
                // Handle the case where availabilityDate is not available
                return true;
              }

              const formattedDate = new Date(availabilityDate)
                .toISOString()
                .slice(0, 10);

              {
                console.log(
                  detail.availabilityData,
                  "detail.availabilityData.requestStatus"
                );
              }

              return (
                startTime !== detail.availabilityData.startTime ||
                endTime !== detail.availabilityData.endTime ||
                shiftName !== detail.availabilityData.shiftType ||
                date !== formattedDate // Use the formatted date for comparison
              );
            }) ? (
              <div className="" onClick={handleSendIconClick}>
                <SendIcon fontSize="small" />
              </div>
            ) : (
              <div className="view-booked-text">Booked</div>
            )} */}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      {/* <Grid
        container
        spacing={1}
        sx={{ mb: 2 }}
        className="client-view-detail-wrapper"
      >
        <Grid item xs={6} md={6} sm={6}>
          <RequestStatus />
        </Grid>
      </Grid> */}

      <Grid container>
        <Grid xs={12} sm={12} md={12}>
          <Grid
            container
            className="justify-content-between align-items-center"
          >
            <Grid xs={4} sm={4} md={4} className="justify-content-start">
              {/* <ShiftTypeFilter /> */}
            </Grid>
            <Grid xs={6} sm={6} md={6} className="justify-content-end">
              <TopFilter type="findSupport" setFields={setField} />
            </Grid>
            <Grid
              xs={2}
              sm={2}
              md={2}
              className="justify-content-end avil-filter-clear"
            >
              <Button
                variant="contained"
                className="res-iconbtn"
                onClick={() => {
                  setField("startDate1", null);
                  setField("endDate1", null);
                  setField("slotType", "Month");
                  setField("showhide", "Month");
                  setField("month", getCurrentMonth());
                  setField("activeMonthIndex", getCurrentMonthIndex());
                  setField("isReloadMonth", true);
                }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
          <div id="calender" style={{ marginTop: "20px" }}>
            <div className="demo-app-main">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                // headerToolbar={{
                //   left: "prev,next today",
                //   center: "title",
                //   right: "dayGridMonth,timeGridWeek,timeGridDay",
                // }}
                ref={ref}
                initialView="dayGridMonth"
                weekends={true}
                initialDate={
                  startDate1 === null ||
                  startDate1 === "" ||
                  startDate1 === undefined
                    ? new Date()
                    : new Date(startDate1)
                }
                events={events}
                dayMaxEvents={1}
                customButtons={{
                  prev: { click: () => goToPreviousDay() },
                  next: { click: () => goToNextDay() },
                  today: { text: "today", click: () => goToDay() },
                }}
                eventContent={renderEventContent} // custom render function
              />
            </div>

            {isLoading && <FieldsListLoading />}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
