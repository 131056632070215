import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import { Box, Typography } from "@mui/material";
import CnnLogo from "../../assets/images/cnn-logo.svg";
import NetGeo from "../../assets/images/netgeo-logo.svg";
import flowrenceLogo from "../../assets/images/florencebymills-logo.svg";
import AncestryLogo from "../../assets/images/ancestry-logo.svg";
import NdwLogo from "../../assets/images/ndwlogo.svg";
import IndusLogo from "../../assets/images/industrious-logo.svg";
import NikeLogo from "../../assets/images/nikelogo.png";
import NotionLogo from "../../assets/images/notion-logo.svg";
import Interlogo from "../../assets/images/inte-logo.svg";
import "./home-ui.css";

function ControlledCarousel() {
  return (
    <>
      <Box id="home-logo-slider">
        <Box>
          <Typography>
            Empowering Individuals and Boosting Business for Australia's Top
            Disability Care Providers
          </Typography>
        </Box>

        <Box className="overflow-hidden">
          <Box class="marquee">
            <Box class="marquee-items">
              <Box className="marquee-image">
                <img src={CnnLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={NetGeo} />
              </Box>
              <Box className="marquee-image">
                <img src={flowrenceLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={AncestryLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={NdwLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={IndusLogo} />
              </Box>
              <Box className="marquee-image marquee-logo">
                <img src={NikeLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={NotionLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={Interlogo} />
              </Box>
            </Box>
            <Box class="marquee-items">
              <Box className="marquee-image">
                <img src={CnnLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={NetGeo} />
              </Box>
              <Box className="marquee-image">
                <img src={flowrenceLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={AncestryLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={NdwLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={IndusLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={NikeLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={NotionLogo} />
              </Box>
              <Box className="marquee-image">
                <img src={Interlogo} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ControlledCarousel;
