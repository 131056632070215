let apiUrl = process.env.REACT_APP_BACKEND_API_URL;
let liveUrl = process.env.REACT_APP_LIVE_URL;

export const ValidateImageType = "image/*";
export const ValidateDocumentType = "image/*,.pdf";
export const ValidateDocUploadType = ".csv, .xlsx";
export const ValidateFileUploadType =
  ".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .csv";

export const REACT_APP_XERO_CLIENT_ID = process.env.REACT_APP_XERO_CLIENT_ID;
export const REACT_APP_XERO_CLIENT_SECRET =
  process.env.REACT_APP_XERO_CLIENT_SECRET;

export const REACT_APP_LIVE_URL = liveUrl;
export const BACKEND_API_URL = apiUrl;
export const limit = 10;
export const emailLimit = 6;
export const limitDashboard = 5;
export const Emlimit = 9; // I change this value because after 10 limit pagination not working currectly
export const FileUploadLimit = 20000000;
export const FileUploadLimitText = "20MB";
export const FileUploadLimitEmail = 10000000;
export const batchSizeCount = 2;
export const googleCountLimit = 1000;

export const HomecareToAWDL = "Assistance with daily life";

export const CHATGPT_API_KEY = process.env.REACT_APP_CHATGPT_API_KEY;

export const CHATGPT_API_RESPONSE_URL =
  "https://api.openai.com/v1/chat/completions";

//super admin ID
export const createdBy = "641062840423e7c1cfec8117";
export const SAcreatedBy = "641062840423e7c1cfec8117";
export const AustraliaId = "640073500ef0e619490b5d2f";
export const Export_createdBy = "641062840423e7c1cfec8117";
export const otherExperienceId = "65e826908ccc1c4a8a2fa142";
export const googleMapCountry = "au";
export const singleUpload = apiUrl + "image/single-image";
export const multipleUpload = apiUrl + "image/multiple-image";

export const PkUserTypeClient = "63f606ef8069398859cd403f";
export const PkUserTypeHealthCarer = "63f606c08069398859cd403d";
export const PkUserTypeSupportCoordinator = "63f606678069398859cd403c";
export const PkUserTypeServiceProvider = "63f606e38069398859cd403e";

export const registerUser = apiUrl + "user/signup";
export const getUserTypeList = apiUrl + "user/getusertypelists";
export const loginUser = apiUrl + "user/login";
export const forgotpassword = apiUrl + "user/forgotPassword";
export const verifyMailOtp = apiUrl + "user/verifymailOTP";
export const getState = apiUrl + "state/getStateByCountry";
export const getCountry = apiUrl + "country/get-all-country";
export const getSupportType = apiUrl + "supportType/list/get-all-SupportType";
export const getuserDetail = apiUrl + "user/getuserDetail";

export const getJobTitleList = apiUrl + "jobTitle/list/get-all-JobTitle";
export const getJobTypeList = apiUrl + "jobType/list/get-all-JobType";
export const getLevelList = apiUrl + "level/get-all-level";
export const getExperienceList = apiUrl + "experience/list/get-all-Experience";
export const getExperienceListRoleBase =
  apiUrl + "experience/role/rolebase-all-experience";
export const updateUser = apiUrl + "user/update";
export const clientDetailsUpdate = apiUrl + "clientDetails/update";
export const getRiskAssesmentAnswersDetails =
  apiUrl + "clientDetails/getRiskAssesmentAnswersDetails";
export const getCountryDetails = apiUrl + "country/details";
export const getStateDetails = apiUrl + "state/value";
export const getStateList = apiUrl + "state/get-all-states-vals";
export const exportStateDetails = apiUrl + "state/export/states";

export const getJobTitleDetails = apiUrl + "jobTitle";
export const getExperienceDetails = apiUrl + "experience";
export const getJobTypeDetails = apiUrl + "jobType";
export const JobTypeexport = apiUrl + "jobType/list/export";

export const getLevelDetails = apiUrl + "level";
export const userSignup = apiUrl + "user/userSignup";

export const passwordReset = apiUrl + "user/password/reset";
export const setPassword = apiUrl + "user/newpassword";
export const addUserBankDetails = apiUrl + "user/addUserBankDetails";
export const updateUserBankDetails = apiUrl + "user/updateUserBankDetails";
export const getBankDetailsByBankId = apiUrl + "user/getUserBankDetail";
export const deleteBankDetailsByBankId = apiUrl + "user/deleteUserBankDetails";
export const getAllUserDocuments = apiUrl + "user/getListDocumentsOfUser";

export const getClientDetailsByUserId =
  apiUrl + "clientDetails/get-client-details-by-userid";
export const getAllServiceList = apiUrl + "service/get-all-service-list";
export const getAllStakeHolderSpecialist =
  apiUrl + "stakeHolderspecialist/get-all-stake-holder-specialist";

export const getStakeHolderspecialistBasic =
  apiUrl + "stakeHolderspecialist/getStakeHolderspecialistBasic";

export const getStakeHolderListByClient =
  apiUrl + "stakeHolderspecialist/getStakeHolder/List";

export const listHundredpoint = apiUrl + "hundredPoint/get-list";
export const deleteHundredpoint = apiUrl + "hundredPoint/delete";
export const exportHundredpoint = apiUrl + "hundredPoint/list/export";

export const getServiceProviderDetailsByUserId =
  apiUrl + "serviceProvider/get-service-provider-details-by-userid";
export const getSupportCoordinatorDetailsByUserid =
  apiUrl + "supportCoordinator/getSupportCoordinatorDetailsByUserid";
export const addSkill = apiUrl + "skill/create";
export const listSkill = apiUrl + "skill/getlists";
export const editSkill = apiUrl + "skill/edit";
export const deleteSkill = apiUrl + "skill/delete";
export const getSkill = apiUrl + "skill/details";
export const listAllSkill = apiUrl + "skill/listAllSkill";
export const exportSkill = apiUrl + "skill/list/export";

export const addLevel = apiUrl + "level/create";
export const listLevel = apiUrl + "level/getAll";
export const deleteLevel = apiUrl + "level/delete";
export const editLevel = apiUrl + "level/update";
export const getLevel = apiUrl + "level";
export const Levelexport = apiUrl + "level/list/export";

export const addJobtitle = apiUrl + "jobTitle/create";
export const listJobtitle = apiUrl + "jobTitle/getList";
export const deleteJobtitle = apiUrl + "jobTitle/delete";
export const editJobtitle = apiUrl + "jobTitle/update";
export const getJobtitle = apiUrl + "jobTitle";
export const exportJobtitle = apiUrl + "jobTitle/get/export";

export const addJobtype = apiUrl + "jobType/create";
export const listJobtype = apiUrl + "jobType/getList";
export const deleteJobtype = apiUrl + "jobType/delete";
export const editJobtype = apiUrl + "jobType/update";
export const getJobtype = apiUrl + "jobType";

export const addQuarter = apiUrl + "quarters/create";
export const listQuarter = apiUrl + "quarters/get-all-quarters";
export const deleteQuarter = apiUrl + "quarters/delete";
export const editQuarter = apiUrl + "quarters/update";
export const getQuarter = apiUrl + "quarters";
export const QuarterExport = apiUrl + "quarters/list/export";
export const listQuarterAll = apiUrl + "quarters/list/allquarters/all";

export const addLeavetype = apiUrl + "leaveCategories/create";
export const listLeavetype = apiUrl + "leaveCategories/getlists";
export const leaveCategoryList = apiUrl + "leaveCategories/leaveCategory/Lists";
export const deleteLeavetype = apiUrl + "leaveCategories/delete";
export const editLeavetype = apiUrl + "leaveCategories/update";
export const getLeavetype = apiUrl + "leaveCategories";
export const getLeaveCategoryFilter =
  apiUrl + "leaveCategories/getLeaveCategoryFilter/all";

export const getAllHundredPointList = apiUrl + "hundredPoint/get-list-all";
export const getAllHundredPointPrimarySecondaryList =
  apiUrl + "hundredPoint/get-list-all/primary-secondary";
export const updateHundredpoint = apiUrl + "hundredPoint/update";
export const addHundredpoint = apiUrl + "hundredPoint/create";
export const getHundredpoint = apiUrl + "hundredPoint";

export const listSupportgroups = apiUrl + "supportGroup/get-list/all";
export const getAllSupportgroups =
  apiUrl + "supportGroup/list/get-all-supportGroup";
export const deleteSupportgroups = apiUrl + "supportGroup/delete";
export const updateSupportgroups = apiUrl + "supportGroup/update";
export const addSupportgroups = apiUrl + "supportGroup/create";
export const getSupportgroups = apiUrl + "supportGroup";
export const exportSupportgroups = apiUrl + "supportGroup/get/export-list";

export const listPackages = apiUrl + "package/get-all-package";
export const deletePackages = apiUrl + "package/delete";
export const updatePackages = apiUrl + "package/update";
export const addPackages = apiUrl + "package/create";
export const getPackages = apiUrl + "package";
export const exportPackages = apiUrl + "package/list/export";

export const listTooltip = apiUrl + "helperText/getlists";
export const deleteTooltip = apiUrl + "helperText/delete";
export const updateTooltip = apiUrl + "helperText/edit";
export const addTooltip = apiUrl + "helperText/create";
export const getTooltip = apiUrl + "helperText/details";
export const exportTooltip = apiUrl + "helperText/list/export";

export const addShiftType = apiUrl + "shiftType/create";
export const editShiftType = apiUrl + "shiftType/update";
export const listShiftType = apiUrl + "shiftType/get-all-shiftType";
export const deleteShifttype = apiUrl + "shiftType/delete";
export const detailsShifttype = apiUrl + "shiftType/details";
export const detailsShifttypeByName = apiUrl + "shiftType/detailsByName";

export const getAllShift =
  apiUrl + "shiftType/get-all-shiftType-without-paggination";
export const exportShifttype = apiUrl + "shiftType/list/export";

export const addAgencie = apiUrl + "agencie/create";
export const listAgencie = apiUrl + "agencie/getlists";
export const deleteAgencie = apiUrl + "agencie/delete";
export const updateAgencie = apiUrl + "agencie/update";
export const detailsAgencie = apiUrl + "agencie/details";
export const searchAgencie = apiUrl + "agencie/search";
export const AgencieList = apiUrl + "agencie/list";
export const ExportagencieList = apiUrl + "agencie/export";

export const GetClientDetailsIDbyUserId =
  apiUrl + "clientDetails/GetClientDetailsIDbyUserId";
export const listClient = apiUrl + "clientDetails/get-all/client-list";

export const listClientEvaluation =
  apiUrl + "clientDetails/client-evaluation/client-list";
export const deleteClient = apiUrl + "clientDetails/delete";
export const detailsClient = apiUrl + "clientDetails/details";
export const updateClientFundDetail =
  apiUrl + "clientDetails/updateClientFundDetail";

export const updateClientBidgetDetail =
  apiUrl + "clientDetails/updateClientBidgetDetail";

export const getClientStakeholderDetail =
  apiUrl + "clientDetails/getClientStakeholderDetail";

export const getClientStakeholderIdByEmail =
  apiUrl + "clientDetails/exe/getClientStakeholderIdByEmail";

export const listHealthCarer = apiUrl + "healthCarer/get-all-healthcarer/list";
export const listHealthCarerOffboarding =
  apiUrl + "healthCarer/get-all-terminated-healthcarer/list";
export const listHealthCarerPending =
  apiUrl + "healthCarer/get-all-pending-healthcarer/list";
export const activeInactiveHealthCarerAndUser =
  apiUrl + "healthCarer/activeInactiveHealthCarerAndUser";

export const HealthCarerList = apiUrl + "healthCarer/all/list";
export const HealthCarerPendingList = apiUrl + "healthCarer/export/list";

export const getAllTimeZone = apiUrl + "timeZone/list/get-all-TimeZone";
export const getScheduleInterviewDetails =
  apiUrl + "scheduleInterview/getScheduleInterviewDetails";
export const checkScheduleInterviewStatus =
  apiUrl + "scheduleInterview/checkScheduleInterviewStatus";
export const getScheduleInterviewDetailsOfUser =
  apiUrl + "scheduleInterview/getScheduleInterviewDetailsOfUser";
export const createScheduleInterview = apiUrl + "scheduleInterview/create";
export const updateScheduleInterview = apiUrl + "scheduleInterview/update";
export const deleteScheduleInterview = apiUrl + "scheduleInterview/delete";
export const getScheduleInterview = apiUrl + "scheduleInterview/getlists";
export const cancelScheduledInterview =
  apiUrl + "scheduleInterview/cancelScheduledInterview";
export const getScheduleInterviewCalendar =
  apiUrl + "scheduleInterview/getScheduleInterviewCalendar/lists";
export const getScheduleInterviewHCList =
  apiUrl + "scheduleInterview/getScheduleInterviewHCList/list";
export const updateInterviewStatus =
  apiUrl + "scheduleInterview/updateInterviewStatus";

export const getAllStateList = apiUrl + "state/get-all-states-vals";
export const getHealthCarerDetails = apiUrl + "user/healthCarerDetails";
export const profileUpdate = apiUrl + "user/profileUpdate";
export const partialAndFullAccess = apiUrl + "user/partialAndFullAccess";
export const getStakByClientUser = apiUrl + "user/getStakByClientUser";
export const getFullNameOfUserById = apiUrl + "user/getFullNameOfUserById";
export const getUserIdByEmail = apiUrl + "user/getUserIdByEmail";
export const deleteHealthCarerAndUser =
  apiUrl + "healthCarer/deleteHealthCarerAndUser";
export const swapHealthCarer = apiUrl + "user/healthCarerList";
export const addExpense = apiUrl + "expense/create";
export const healthCarerDetails = apiUrl + "healthCarer";
export const listCountry = apiUrl + "country/get-all-country/list";
export const deleteCountry = apiUrl + "country/delete";
export const addCountry = apiUrl + "country/create";
export const updateCountry = apiUrl + "country/update";
export const getCountrydetails = apiUrl + "country/details";

export const listZone = apiUrl + "zone/get-all-zone";
export const deleteZone = apiUrl + "zone/delete";
export const addZone = apiUrl + "zone/create";
export const updateZone = apiUrl + "zone/update";
export const getZone = apiUrl + "zone/details";
export const getZoneExport = apiUrl + "zone/list/export";
export const getZoneList = apiUrl + "zone/list/allZoneList";

export const listMettingtype = apiUrl + "meetingType/meeting-type/all";
export const AllMeetingTypeList = apiUrl + "meetingType/get-all-meeting-type";
export const deleteMettingtype = apiUrl + "meetingType/delete";
export const addMettingtype = apiUrl + "meetingType/create";
export const updateMettingtype = apiUrl + "meetingType/update";
export const getMettingtype = apiUrl + "meetingType";
export const GetMeetingLogo = apiUrl + "meetingType/GetMeetingLogo";

export const listState = apiUrl + "state/get/all-states";
export const deleteState = apiUrl + "state/delete";
export const addState = apiUrl + "state/create";
export const updateState = apiUrl + "state/update";
export const getStatelist = apiUrl + "state";
export const getStateDetailsUpdate = apiUrl + "state/getStateDetailsUpdate";

export const HcarerlistTask = apiUrl + "appointment/HcarerListAppointments";
export const listTask = apiUrl + "quickTask/get/all-states";
export const deleteTask = apiUrl + "quickTask/delete";
export const addTask = apiUrl + "quickTask/create";
export const updateTask = apiUrl + "quickTask/update";
// export const taskDetails = apiUrl + "quickTask/task/details";
export const getListTask = apiUrl + "quickTask/pagination/List";
export const getListTaskFm = apiUrl + "quickTask/FmQuickTask/list";
export const getliststackholder =
  apiUrl + "stakeHolderspecialist/get-all-stake-holder-specialist";
export const getClientlist = apiUrl + "user/client/list";
export const getClientlistForGoal = apiUrl + "user/client/listForGoal";
export const getclientByNdis = apiUrl + "user/clientByNdis/list";
export const getClientlistForIHC = apiUrl + "user/client/listForIHC";

export const getRateClient = apiUrl + "user/get/rate";

export const getClientOfStakeholder = apiUrl + "user/stakeholder/clientList";

export const activeInactiveClientAndUser =
  apiUrl + "clientDetails/activeInactiveClientAndUser";

export const listNote = apiUrl + "note/get/all-states";
export const deleteNote = apiUrl + "note/delete";
export const addNote = apiUrl + "note/create";
export const updateNote = apiUrl + "note/update";
export const getNotelist = apiUrl + "note/details";
export const Notelist = apiUrl + "note/list/note";
export const FmNoteList = apiUrl + "note/FmNoteList/all";

export const getStakeNoteDetails = apiUrl + "note/stake-note-details";

export const addIncident = apiUrl + "incident/create";
export const listIncident = apiUrl + "incident/get-all-incident";
export const listIncidentForSpecificUser =
  apiUrl + "incident/get-all-incident-user";
export const incidentListForSpecificUser = apiUrl + "severity/get/incidentUser";
export const listIncidentForSupervisor =
  apiUrl + "incident/get-all-incident-supervisor";
export const listIncidentForGM =
  apiUrl + "incident/get-all-incident-genManager";
export const deleteIncident = apiUrl + "incident/delete";
export const statusIncident = apiUrl + "incident/status";
export const editIncident = apiUrl + "incident/update";
export const getIncident = apiUrl + "incident";
export const getIncidentPdf = apiUrl + "incident/pdf";
export const filterIncident = apiUrl + "incident/get-incident-filter";
export const getHCList = apiUrl + "user/healthCarer/list";
export const getHCListBy_Jid_Jtl_Jl =
  apiUrl + "user/healthCarer/getHCListBy_Jid_Jtl_Jl";

export const getHCListAsPerClientAppointment =
  apiUrl + "user/healthCarerAsPerClientAppointment/list";
export const getClientListAsPerHCAppointment =
  apiUrl + "user/clientAsPerHealthCarerAppointment/list";

/**termination api */
export const terminationDataUpdate = apiUrl + "termination/update";
export const terminatedUserData = apiUrl + "termination";
export const sendTerminatedMail = apiUrl + "termination/sendemail";
// export const getTerminatedUserData = apiUrl + "user/userTerminationData";

//add meeting
export const downloadClientMeetingsPdf =
  apiUrl + "meeting/downloadClientMeetingsPdf";
export const cancelScheduledMeeting = apiUrl + "meeting/cancelScheduledMeeting";
export const updateMeetingDetails = apiUrl + "meeting/update";
export const deleteMeeting = apiUrl + "meeting/delete";
export const addMeeting = apiUrl + "meeting/create";
export const getAllMeetingList = apiUrl + "meeting/getlists";
export const getMeetingListIndividualClient =
  apiUrl + "meeting/getMeetingListIndividualClient/list";
export const getMeetingListAllClient =
  apiUrl + "meeting/getMeetingListAllClient/list";
export const getMeetingListAllClientCalendar =
  apiUrl + "meeting/getMeetingListAllClient/Calendar/list";
export const updateMeetingStatus = apiUrl + "meeting/updateStatus";
export const getMeetingEmailIds = apiUrl + "meeting/getMeetingEmailIds";
export const getMeetingDetailsIndividual =
  apiUrl + "meeting/getMeetingDetailsIndividual";
export const resendOtp = apiUrl + "user/otp/resend";
export const MobileuserDelete = apiUrl + "user/Mobile/userDelete";
export const verifyOtpUserDelete = apiUrl + "user/otp/verifyOtpUserDelete";
export const getRefreshTokan = apiUrl + "user/getRefreshTokan";

export const updateUserStatusVal = apiUrl + "user/updateUserStatus";
export const updateUserStatusValAndCancelInterview =
  apiUrl + "user/updateUserStatusValAndCancelInterview";

export const updateHealthCarer = apiUrl + "user/update-health-carer";
export const updateSupportCoordinator =
  apiUrl + "user/update-support-coordinator";

export const listSupportCoordinator =
  apiUrl + "supportCoordinator/get-all-supportcoordinator/list";
export const listSupportCoordinatorPending =
  apiUrl + "supportCoordinator/get-all-pending-supportcoordinator/list";
export const deleteSupportCoordinatorAndUser =
  apiUrl + "supportCoordinator/deleteSupportCoordinatorAndUser";
export const supportCoordinator = apiUrl + "supportCoordinator/list/all";

export const activeInactiveSupportCoordinatorAndUser =
  apiUrl + "supportCoordinator/activeInactiveSupportCoordinatorAndUser";

export const listServiceProvider =
  apiUrl + "serviceProvider/get-all-service-provider/list";
export const listServiceProviderPending =
  apiUrl + "serviceProvider/get-all-pending-service-provider/list";
export const deleteServiceProviderAndUser =
  apiUrl + "serviceProvider/deleteServiceProviderAndUser";

export const activeInactiveServiceProviderAndUser =
  apiUrl + "serviceProvider/activeInactiveServiceProviderAndUser";
// apiUrl + "serviceProvider/deleteSupportCoordinatorAndUser";
export const getlistServiceProvider = apiUrl + "serviceProvider/all/list";

/**induction category Apis */
export const AddCategory = apiUrl + "inductionCategory/create";
export const setAvailability = apiUrl + "setAvailability/create";
export const getCategoryList = apiUrl + "inductionCategory/getList";
export const deleteCategory = apiUrl + "inductionCategory/delete";
export const getIndividualCategoryDetails = apiUrl + "inductionCategory";
export const updateCategory = apiUrl + "inductionCategory/update";
export const getHeadingList = apiUrl + "inductionHeading/getList";
export const getListByCatagory = apiUrl + "inductionHeading/getListByCatagory";
export const addInductionTitle = apiUrl + "inductionTitle/create";
export const addInductionHeading = apiUrl + "inductionHeading/create";
export const editInductionHeading = apiUrl + "inductionHeading/update";
export const deleteInductionHeading = apiUrl + "inductionHeading/delete";
export const getInductionList = apiUrl + "inductionTitle/getList";
export const inductionIndividualTitleDetails = apiUrl + "inductionTitle";
export const updateInductionTitleDetails = apiUrl + "inductionTitle/update";
export const deleteInductionTitle = apiUrl + "inductionTitle/delete";
export const getTitleListByHeading =
  apiUrl + "inductionTitle/getTitleListByHeading";
export const taskDetails = apiUrl + "quickTask/task/details";
export const assignTaskDetails = apiUrl + "quickTask/assign-task-details";
export const clientListInvoice = apiUrl + "user/client/invoice-list";
export const clientListInvoiceServiceTask =
  apiUrl + "user/client/invoice-list-service-task";

export const TerminateUserAndInactive =
  apiUrl + "user/terminateUserAndInactive";
export const addConflict = apiUrl + "conflict/create";
export const listConflict = apiUrl + "conflict/get-all-conflict";
export const deleteConflict = apiUrl + "conflict/delete";
export const editConflict = apiUrl + "conflict/update";
export const getConflict = apiUrl + "conflict";
export const supportCoordinatorList = apiUrl + "user/supportCoordinator/list";
export const supportCoordinatorByEx_Jt_Jt_Jl_List =
  apiUrl + "user/supportCoordinator/supportCoordinatorByEx_Jt_Jt_Jl_List";

export const serviceProviderList = apiUrl + "user/serviceProvider/list";
export const serviceProvider_package =
  apiUrl + "user/serviceProvider/serviceProvider_package";

export const exportConflict = apiUrl + "conflict/export/conflict";
/**induction response */
// inductionResponse / create
export const getInductionResponse = apiUrl + "inductionResponse";
export const supportCoordinatorDetails =
  apiUrl + "user/supportCoordinatorDetails";

export const getAvailabilityByUserId =
  apiUrl + "setAvailability/get-availability-by-userid";

export const addRiskassesment = apiUrl + "riskAssessmentQuestion/create";
export const listRiskassesmentt =
  apiUrl + "riskAssessmentQuestion/list/get-question";
export const deleteRiskassesment = apiUrl + "riskAssessmentQuestion/delete";
export const editRiskassesment = apiUrl + "riskAssessmentQuestion/update";
export const getRiskassesment = apiUrl + "riskAssessmentQuestion";
export const detailsRiskassesment = apiUrl + "riskAssessmentQuestion";

export const addRiskquestion = apiUrl + "clientDetails/add";
export const scUpdate = apiUrl + "user/scUpdate";

export const clientDetails = apiUrl + "user/clientDetails";
export const clientProfileUpdate = apiUrl + "user/clientUpdate";
export const stakeHolderDetails = apiUrl + "user/stakeHolderDetails";
export const stakeHolderProfileUpdate = apiUrl + "user/stakeHolderUpdate";
export const InductionResponse = apiUrl + "inductionResponse/create";

export const listHeading = apiUrl + "/inductionHeading/getList";

export const inductionUserResponse = apiUrl + "inductionResponse";
export const getListInductionDetails = apiUrl + "inductionTitle/list";

export const UserInductionResponse = apiUrl + "inductionResponse/create";
export const inductiongetUserResponse = apiUrl + "inductionResponse";
export const addUserType = apiUrl + "userType/create";
export const listUserType = apiUrl + "userType/get-all-user-type";
export const updateUserType = apiUrl + "userType/update";
export const deleteUserType = apiUrl + "userType/delete";
export const getUserType = apiUrl + "userType/details";
export const listAllUserType = apiUrl + "userType/getAll/list";
export const listAllUserTypePriSec = apiUrl + "userType/pri-sec-usertype-list";
export const listAllUserTypePriSecDropdwon =
  apiUrl + "userType/pri-sec-usertype-list-for-dropdwon";
export const AllUserTypesListSpcl = apiUrl + "userType/getUsertype/spcl";

export const addSeverity = apiUrl + "severity/create";
export const listSeverity = apiUrl + "severity/get/pagination-list";
export const deleteSeverity = apiUrl + "severity/delete";
export const editSeverity = apiUrl + "severity/update";
export const getSeverity = apiUrl + "severity";
export const getSeverityList = apiUrl + "severity/list/get-all-severity";

// export const getHCList = apiUrl + "user/healthCarer/list";
export const activeInactiveSecondaryUser =
  apiUrl + "user/activeInactiveSecondaryUser";
export const addSecondaryUser = apiUrl + "user/addSecondaryUser";
export const updateSecondaryUser = apiUrl + "user/updateSecondaryUser";
export const getSecondaryUserDetailsById =
  apiUrl + "user/getSecondaryUserDetailsById/bring";
export const getSecondaryUserPagination =
  apiUrl + "user/getSecondaryUserPagination";
export const listSecondaryOffboarding =
  apiUrl + "user/get-all-terminated-secondaryUser/list";

export const listSecondaryOffboardingExport =
  apiUrl + "user/get-all-terminated-secondaryUser/export";
export const deleteSecondaryUser = apiUrl + "user/deleteSecondaryUser";

export const addUserByAdmin = apiUrl + "user/addUserByAdmin";
export const updateUserByAdmin = apiUrl + "user/updateUserByAdmin";

export const familyMemberDetails = apiUrl + "user/familyMemberDetails";
export const familyMemberProfileUpdate = apiUrl + "user/familyMemberUpdate";

export const updateAppoitment = apiUrl + "appointment/update";
export const AddAppointment = apiUrl + "appointment/create";
export const ListRegion = apiUrl + "region/get/all-list";
export const getRegionList = apiUrl + "region/get-all-list";
export const addRegion = apiUrl + "region/create";
export const editRegion = apiUrl + "region/update";
export const getRegionListById = apiUrl + "region";
export const deletRegion = apiUrl + "region/delete";
export const ListSupporttype = apiUrl + "supportType/get/list-all";
export const ListSupportgroup = apiUrl + "supportGroup/support-group/list";
export const Appointmenttype = apiUrl + "appointmentType/appointment-type/list";
export const getSilSdaAppointment = apiUrl + "appointment/silsda";
export const swapAppointment = apiUrl + "appointment/swapAppointment";
export const appointmentSwap = apiUrl + "appointment/appointment-swap";
export const detailsTask = apiUrl + "appointment/details-task";
export const getdetailsTask = apiUrl + "appointment/get-details-task";
export const getStatusAppointment = apiUrl + "appointment/getData";

export const detailsAppointment = apiUrl + "appointment";
export const detailsLastswap = apiUrl + "appointment/details/swaped";
export const addRoutine = apiUrl + "appointment/add-routine";
export const taskdetails = apiUrl + "appointment/task/details";
export const addtaskAginstroutine = apiUrl + "appointment/add-task";
export const detailsclientappointment =
  apiUrl + "appointment/details-HcarerListAppointments";

export const addAppoitmentType = apiUrl + "appointmentType/create";
export const listAppointmenttype = apiUrl + "appointmentType/get-list/all";
export const deleteAppoitmentType = apiUrl + "appointmentType/delete";
export const updateAppointmenttype = apiUrl + "appointmentType/update";
export const getAppointmentType = apiUrl + "appointmentType";
export const getAppointmentList = apiUrl + "appointmentType/healthCarer/list";

export const addHelpManagement = apiUrl + "helpManagement/create";
export const listHelpManagement = apiUrl + "helpManagement/get-list/all";
export const deleteHelpManagement = apiUrl + "helpManagement/delete";
export const updateHelpManagement = apiUrl + "helpManagement/update";
export const getHelpManagement = apiUrl + "helpManagement";

export const addEmergencyContact = apiUrl + "user/addEmergencyContact";
export const updateEmergencyContact = apiUrl + "user/updateEmergencyContact";
export const deleteEmergencyContact = apiUrl + "user/deleteEmergencyContact";
export const listEmergencyContact = apiUrl + "user/emergencyContactList";
export const listEmergencyContactForSa = apiUrl + "user/emergencyContactListSA";
export const listEmergencyContactFilter =
  apiUrl + "user/emergencyContact/list/filter";

export const exportEmergencyContact = apiUrl + "user/export/emergencyContact";
export const exportEmergencyContactall =
  apiUrl + "user/emergencyContact/export";

export const addEmergencyContactByEmployee =
  apiUrl + "user/addEmergencyContactByEmployee";

export const getEmergencyContactDetails =
  apiUrl + "user/getEmergencyContactDetails";

export const applyForLeave = apiUrl + "leaveEmployee/create";
export const getLeaveDetails = apiUrl + "leaveEmployee";
export const leaveLists = apiUrl + "leaveEmployee/getlists";
export const leaveListsFilter = apiUrl + "leaveEmployee/getlistsFilter";

export const getLeaveListAllUser =
  apiUrl + "leaveEmployee/getLeaveListAllUser/list";

export const getLeaveListAllUserCalendar =
  apiUrl + "leaveEmployee/getLeaveListAllUserCalendar/list";

export const deleteLeave = apiUrl + "leaveEmployee/delete";
export const ListingUserDetails =
  apiUrl + "leaveEmployee/details/leaveUserDetails";

export const UserLeaveList = apiUrl + "leaveEmployee/getIndividualAllLeaves";

export const leaveDetails = apiUrl + "leaveEmployee/employee";
export const updateLeaveStatus = apiUrl + "leaveEmployee/updateStatus";
export const DetailsUpdateLeave = apiUrl + "leaveEmployee/update";
export const UserAsPerUserType = apiUrl + "user/getUserAsUserType";
export const userList = apiUrl + "leaveEmployee/getUserTypeList";
export const emailUserList = apiUrl + "user/getList";
export const menuList = apiUrl + "leftMenu/left-menu-get-list/all";

export const IndividualLeaves = apiUrl + "leaveEmployee";
export const leaveApplyByAdmin = apiUrl + "leaveEmployee/addLeave";

export const addClientCategory = apiUrl + "category/create";
export const listClientCategory = apiUrl + "category/getAll";
export const deleteClientCategory = apiUrl + "category/delete";
export const editClientCategory = apiUrl + "category/update";
export const getClientCategory = apiUrl + "category";

export const exportsupportCoordinator =
  apiUrl + "supportCoordinator/export/list";
export const exportserviceProvider = apiUrl + "serviceProvider/export/list";
export const getClientProfileDetails =
  apiUrl + "clientDetails/get-client-profile-details";
export const getClientdetails = apiUrl + "clientDetails/client/details";
export const getCategory = apiUrl + "category/list/all";

export const getHealthCarerList = apiUrl + "healthCarer/get-list/list";

export const updateClientData = apiUrl + "user/update-client";

/** Apis of Menu Master */
export const createMenuMaster = apiUrl + "menu/create";
export const listAllMenuMaster = apiUrl + "menu/get-all-menu";
export const getIndividualMenuMaster = apiUrl + "menu";
export const updateMenuMasterDetails = apiUrl + "menu/update";
export const deleteMenuMasterDetails = apiUrl + "menu/delete";
export const exportMenulist = apiUrl + "menu/list/export";
export const addTextspeechkit = apiUrl + "textspeechkit/create";
export const getTextspeechkitDetails =
  apiUrl + "textspeechkit/getTextspeechkitDetails";
export const getTextspeechkit = apiUrl + "textspeechkit/get-all-textspeechkit";
export const deleteTextspeechkit = apiUrl + "textspeechkit/deleteTextspeechkit";

export const getAllSupportItem = apiUrl + "supportItem/list-all";
export const getAllSupportCategory = apiUrl + "supportCategory/list-all";
/**Apis of packageMenu */
export const createPackageMenu = apiUrl + "packageMenu/create";
export const listAllPackage = apiUrl + "package/packagelist";
export const listAllMenu = apiUrl + "menu/menuAllList";
export const listAllMenuData = apiUrl + "menu/role/get-all-menu-list";
export const listAllPackageMenu = apiUrl + "packageMenu/get-all-packageMenu";
export const listAllPackageMenuFilter =
  apiUrl + "packageMenu/get-all-packageMenu-filter";

export const getIndividualPackageMenu = apiUrl + "packageMenu";
export const updatePackageMenuDetails = apiUrl + "packageMenu/update";
export const serviceProviderDetailsUpdate =
  apiUrl + "serviceProvider/updateDetails";
export const deletePackageMenuDetails = apiUrl + "packageMenu/delete";
export const getAllSkillList = apiUrl + "skill/get-all-skill";
export const getAllUserList = apiUrl + "user/get-all-userdetails";
export const getAllTeamMemberList = apiUrl + "user/client/all-teammember";
export const getAllTeamMemberListAutoShift =
  apiUrl + "user/client/getAllTeamMemberListAutoShift";

export const getAllHcList = apiUrl + "user/hc/get-all-health-carer-list";

export const getListAppointment = apiUrl + "appointment/allListAppointments";
export const getListAppointmentClient =
  apiUrl + "appointment/appointmentListsClient";

export const silSdaAppoinment = apiUrl + "appointment/silsda/Appointment";

export const getDetailItemWise = apiUrl + "appointment/getitemid";

export const getIndividualAppointmentDetails =
  apiUrl + "appointment/appointment-details";

export const getCalendarAppointmentList =
  apiUrl + "appointment/get-calendar-appointments";

export const onCallStatus = apiUrl + "healthCarer/on-call-status";
export const onCallHealthCarerList =
  apiUrl + "healthCarer/get-all-oncall-healthcarer/list";

//NDIS Catalogue
export const importNdisCatalogue = apiUrl + "ndis-catlog/create";
export const getAllNdisCatlog = apiUrl + "ndis-catlog/getAll";
export const versionListDD = apiUrl + "ndis-catlog/version-list";
export const NDISBulkUpload = apiUrl + "ndis-catlog/bulk-upload";
export const XeroExportApi = apiUrl + "ndis-catlog/xero-upload";
export const getAppointmentDetailsList = apiUrl + "appointment/detail-list";
export const getClientAppointmentDetailsList =
  apiUrl + "appointment/client/detail-list";
export const clientProfileUpdateById = apiUrl + "user/client-profile-update";
export const updateRoutineTask = apiUrl + "appointment/routine/update-task";
export const swapTasks = apiUrl + "appointment/swap-task";

export const addRoutineIcon = apiUrl + "routineIcon/create";
export const listRoutineIcon = apiUrl + "routineIcon/getlists";
export const listAllRoutineIcon = apiUrl + "routineIcon/listAllRoutineIcon";
export const detailsRoutineIcon = apiUrl + "routineIcon/details";
export const editRoutineIcon = apiUrl + "routineIcon/edit";
export const deleteRoutineIcon = apiUrl + "routineIcon/delete";

export const addSidebarMenu = apiUrl + "sidebar/create";
export const listSidebarMenu = apiUrl + "sidebar/getlists";
export const getSidebarMenu = apiUrl + "sidebar/details";
export const editSidebarMenu = apiUrl + "sidebar/edit";
export const deleteSidebarMenu = apiUrl + "sidebar/delete";

export const addAppointmentRoutineTask =
  apiUrl + "appointment/appointment-add-routinetask";
export const supperAdminAppointmentList =
  apiUrl + "appointment/super-admin/appointment-list";
export const supperAdminAppointmentListExport =
  apiUrl + "appointment/super-admin/appointment-list-export";
export const silSdaLocationId = apiUrl + "appointment/super-admin/getLocation";

export const sendMailForStartAppointment =
  apiUrl + "appointment/appointmentsendMail";
export const ApDetails = apiUrl + "appointment/edit/appointment-details";
export const apDropdwonList = apiUrl + "appointment/appointment-dropdwon/list";

export const addAppointmentExpenses =
  apiUrl + "appointment/update-appointment-expense";

export const documentUpdate = apiUrl + "user/document-update";

export const deleteAppointment = apiUrl + "appointment/delete";
export const getUserListByUserTypeId =
  apiUrl + "leaveEmployee/getUserByUserTypeId";

export const exportGroup = apiUrl + "group/list/export";
export const addGroup = apiUrl + "group/create";
export const listGroup = apiUrl + "group/getlists";
export const editGroup = apiUrl + "group/edit";
export const deleteGroup = apiUrl + "group/delete";
export const getGroup = apiUrl + "group/details";
export const listExperiances = apiUrl + "experience/list/get-all-Experience";
export const allExperienceListByCreated =
  apiUrl + "experience/list/allExperienceListByCreated";

export const supervisorList = apiUrl + "user/supervisor/list";
export const generalManagerList = apiUrl + "user/generalManager/list";
export const stakeholderList = apiUrl + "user/stakeholder/list";
export const familyMemberList = apiUrl + "user/familyMember/list";
export const accountantList = apiUrl + "user/accountant/list";
export const planManagerList = apiUrl + "user/planManager/list";
export const superAdminList = apiUrl + "user/superAdmin/list";
export const itManagerList = apiUrl + "user/itManager/list";
export const sideBarListByUserType = apiUrl + "sidebar/getListByRole";
export const listSidebarMenuByRoleID = apiUrl + "sidebar/list/by-role-id";

export const getListOfCompletedAppointment =
  apiUrl + "appointment/healthCarerTimesheet";

export const getClientInvoiceList = apiUrl + "invoice/client-invoice-list";
export const getClientInvoiceListSil =
  apiUrl + "invoice/client-invoice-list-sil";
export const getClientInvoiceBulkUpload =
  apiUrl + "invoice/client-invoice-bulk-upload/export";
export const getPayslipDetails = apiUrl + "serviceTask/client-payslip-list";

export const getClientInvoiceListExport =
  apiUrl + "invoice/client-invoice-list/Export";

export const exportgetListOfCompletedAppointment =
  apiUrl + "appointment/exporthealthCarerTimesheet";

export const Addagreement = apiUrl + "agreement/create";
export const listAgreement = apiUrl + "agreement/get-list/all";
export const updateAgreement = apiUrl + "agreement/update";
export const detailsAgreement = apiUrl + "agreement/details";
export const deleteAgreement = apiUrl + "agreement/delete";
export const exportAgreement = apiUrl + "agreement/list/export";

export const addGoogleCount = apiUrl + "googleCount/add";
export const updateGoogleCount = apiUrl + "googleCount/update";
export const getIndividualCountDetails = apiUrl + "googleCount";

export const getHealthCarerAvailabilityApi =
  apiUrl + "appointment/health-carer/get-all-health-carer-availability-list";

//getHealthCarerAvailabilityApiClientTeam

export const getHealthCarerAvailabilityApiClientTeam =
  apiUrl + "appointment/clientTeam/get-all-available-hc";

export const getHealthCarerActiveAP =
  apiUrl + "appointment/get-current-active-appointment-hc";

//

export const allAgreementList = apiUrl + "agreement/get-agreement-list";

export const exportAppointmentData =
  apiUrl + "appointment/export/exportAppointmentData";
//

export const getHundredPointData = apiUrl + "user/getUserHundredPoints";

// /user/getClientSelectedCategory/
export const getClientSelectedCatgory =
  apiUrl + "user/getClientSelectedCategory";

// Smtp
export const smtpCreate = apiUrl + "user/smtp/create";
export const smtpGet = apiUrl + "user/smtp/detail";

export const AdduserRole = apiUrl + "userRole/create";
export const listUserrole = apiUrl + "userRole/getAll";
export const updateUserrole = apiUrl + "userRole/update";
export const detailsuserrole = apiUrl + "userRole";
export const deleteUserrole = apiUrl + "userRole/delete";
export const userroleList = apiUrl + "userRole/get/list-all";

export const swapHealthcarerList =
  apiUrl +
  "appointment/health-carer/swap-apt/get-all/swap-appointment-availability-list";

export const getRoutineAppointmentDetails =
  apiUrl + "appointment/appointment-routine-data";

export const addTemplatemasters = apiUrl + "templatemaster/create";
export const getTemplatemastersList = apiUrl + "templatemaster/getAll/list";
export const TemplatemastersListTypeWise =
  apiUrl + "templatemaster/template-master-list/typewise";
export const updateTemplatemasters = apiUrl + "templatemaster/update";
export const deleteTemplatemasters = apiUrl + "templatemaster/delete";
export const sendLetterAsAttachment =
  apiUrl + "templatemaster/sendLetterAsAttachment";
export const TemplatemastersDetails = apiUrl + "templatemaster/details";
export const TemplateAsPerTemplateType =
  apiUrl + "templatemaster/TemplateAsPerTemplateType";
export const AllTemplatemastersList =
  apiUrl + "templatemaster/get-all-template-master";

export const ReactTemplatemastersDetails =
  apiUrl + "templatemaster/react/details";
export const ReactupdateTemplatemasters =
  apiUrl + "templatemaster/react/update";
export const ReactaddTemplatemasters = apiUrl + "templatemaster/react/create";

export const employeePayRates = apiUrl + "employee-pay-rates/create";
export const crisisAccomodationEmployeePayRates =
  apiUrl + "employee-pay-rates/crisis-accomodation/create";

export const homeCarerEmployeePayRates =
  apiUrl + "employee-pay-rates/home-carer/create";

export const familyCarerEmployeePayRates =
  apiUrl + "employee-pay-rates/family-carer/create";

export const familyCarerList = apiUrl + "employee-pay-rates/family-carer-list";
export const homeCarerList = apiUrl + "employee-pay-rates/home-carer-list";
export const crisisCarerList =
  apiUrl + "employee-pay-rates/crisis-accomodation-list";

export const familyCarerListExport =
  apiUrl + "employee-pay-rates/family-carer-list-export";
export const homeCarerListExport =
  apiUrl + "employee-pay-rates/home-carer-list-export";
export const crisisCarerListExport =
  apiUrl + "employee-pay-rates/crisis-accomodation-list-export";

export const getAllCalendarAvailability =
  apiUrl + "setAvailability/get-all-calendar-availability";
export const getAllCalendarAvailabilityShift =
  apiUrl + "setAvailability/get-all-shift-availabiity";

export const getAllHcWeeklyAvailability =
  apiUrl + "setAvailability/get-all-hc-weekly-availability";

export const getAllemployeePayRates = apiUrl + "employee-pay-rates/getAll";
export const getAllemployeePayRatesExport =
  apiUrl + "employee-pay-rates/getAll-export";

export const getAllUnreadNotifications =
  apiUrl + "notification/unreadNotifications";
export const clearNotification = apiUrl + "notification/clear-notification";
export const addProgressnote = apiUrl + "progress-note/create";
export const listProgressnote = apiUrl + "progress-note/getlists/all";
export const detailsProgressnote = apiUrl + "progress-note/details";
export const updateProgressnote = apiUrl + "progress-note/update";
export const deleteProgressnote = apiUrl + "progress-note/delete";
export const getAllLocation = apiUrl + "setAvailability/get-all/location";
export const addTemplatevariable = apiUrl + "templatemaster/create/variable";
export const AllTemplatevariableList =
  apiUrl + "templatemaster/get-all-template-master/variable";
export const AllTemplatevariableListByCreatedBy =
  apiUrl +
  "templatemaster/get-all-template-master/AllTemplatevariableListByCreatedBy";
export const getTemplatevariableList =
  apiUrl + "templatemaster/getAll/list/variable";
export const TemplatevariableDetails =
  apiUrl + "templatemaster/details/variable";
export const updateTemplatevariable = apiUrl + "templatemaster/update/variable";
export const deleteTemplatevariable = apiUrl + "templatemaster/delete/variable";

export const updateNotification = apiUrl + "notification/updateNotifications";

export const addFile = apiUrl + "file/create";
export const listFile = apiUrl + "file/getlists/all";
export const detailsFile = apiUrl + "file/details";
export const updateFile = apiUrl + "file/update";
export const deleteFile = apiUrl + "file/delete";
export const getFileByfolderId = apiUrl + "file/getDetails";
export const OrglistFile = apiUrl + "file/org/getlists/all";

export const addFolder = apiUrl + "folder/create";
export const updateFolder = apiUrl + "folder/update";
export const detailsFolder = apiUrl + "folder/details";
export const listFolder = apiUrl + "folder/getlists";
export const deleteFolder = apiUrl + "folder/delete";
export const getFolderlist = apiUrl + "folder/list";
export const renameFolder = apiUrl + "folder/rename";

export const addfundingSource = apiUrl + "foundSource/create";
export const listfundingSource = apiUrl + "foundSource/getList";
export const allfundingSourceList = apiUrl + "foundSource/list/get-all";
export const deletefundingSource = apiUrl + "foundSource/delete";
export const editfundingSource = apiUrl + "foundSource/update";
export const getfundingSource = apiUrl + "foundSource";
export const exportfundingSource = apiUrl + "foundSource/get/export";
export const fundingSourceDetailsCreatedByandName =
  apiUrl + "foundSource/fundingSourceDetailsCreatedByandName";

export const getClientFundingSources =
  apiUrl + "foundSource/filter/getClientFundingSources";

export const addsetLeave = apiUrl + "setLeave/create";
export const getlistsetLeave = apiUrl + "setLeave/getList";
export const detailssetLeave = apiUrl + "setLeave";
export const updatesetLeave = apiUrl + "setLeave/update";
export const deletesetLeave = apiUrl + "setLeave/delete";
export const getSetLeaveFilter = apiUrl + "setLeave/getSetLeaveFilter/all";

export const sendApprovalRequest =
  apiUrl + "setTimesheet/send-aaproval-request";
export const getAllSuperAdminTimeSheetList =
  apiUrl + "setTimesheet/super-admin/get-all-super-admin-timesheet";
export const getAllSuperAdminTimeSheetViewList =
  apiUrl + "setTimesheet/get-all-timesheets-list-view";
export const approvedMultipleTimesheet =
  apiUrl + "setTimesheet/approved-timesheet";
export const rejectTimesheet = apiUrl + "setTimesheet/reject-timesheet";
export const updateSpecialRates = apiUrl + "setTimesheet/update-special-rates";
export const getAllexportSuperadminTimesheet =
  apiUrl + "setTimesheet/super-admin/get-all-super-admin-timesheet-for-export";

export const getAllSetLeaveByUserType =
  apiUrl + "setLeave/getDetailsByUserType";
export const getAllTimsheetUserList = apiUrl + "user/timesheet/user-list";
export const getAllScTimsheetUserList = apiUrl + "user/timesheet/sc/user-list";
export const listReports = apiUrl + "report/get-all-report";
export const listReportsPage = apiUrl + "report/get-all-report-page";
export const deleteReports = apiUrl + "report/delete";
export const updateReports = apiUrl + "report/update";
export const addReports = apiUrl + "report/create";
export const getReports = apiUrl + "report";
export const exportReports = apiUrl + "report/list/export";

export const addHoliday = apiUrl + "publicHoliday/create";
export const detailsHoliday = apiUrl + "publicHoliday/details";
export const getlistHoliday = apiUrl + "publicHoliday/getlist/holiday";
export const updateHoliday = apiUrl + "publicHoliday/update";
export const deleteHoliday = apiUrl + "publicHoliday/delete";
export const deleteSilApp = apiUrl + "appointment/delete/silapp";
export const getYears = apiUrl + "publicHoliday/years";
export const getpastYears = apiUrl + "publicHoliday/paststates";
export const getcurrentYears = apiUrl + "publicHoliday/get/currentstates";
export const getnextYears = apiUrl + "publicHoliday/nextstates";
export const getApilledLeavesSum = apiUrl + "leaveEmployee/sumLeavesApplied";

export const getProgressObjectiveLista =
  apiUrl + "progress-objective/getAllProgressObjective";

export const getEmployeeBirthday = apiUrl + "user/getList/employeeBirthday";

export const updateAppointmentStatus = apiUrl + "appointment/update/status";
export const rescheduledAppointment =
  apiUrl + "appointment/update/rescheduledAppointment";

export const createPdf = apiUrl + "sendAttachEmail/createPdf";
export const fetchPdf = apiUrl + "sendAttachEmail/fetchPdf";
export const sendPdf = apiUrl + "sendAttachEmail/sendPdf";
export const sendPdfWithNewPack = apiUrl + "sendAttachEmail/sendPdfWithNewPack";

export const allProgressObjectiveList =
  apiUrl + "progress-objective/getAllProgressObjective";
export const progressObjectiveDetails =
  apiUrl + "progress-objective/getIndividual";
export const addProgressObjective =
  apiUrl + "progress-objective/addProgressObjective";
export const updateProgressObjective =
  apiUrl + "progress-objective/updateProgressObjective";
export const deleteProgressObjective =
  apiUrl + "progress-objective/deleteProgressObjective";
export const getProgressObjectiveList =
  apiUrl + "progress-objective/getProgressObjectiveList";
export const getEmployeeDetailsRepot = apiUrl + "user/getList/employeeDetails";
export const getAllFilterUserList = apiUrl + "user/birthday/user-list";
export const getEmployeeBirthdayexport =
  apiUrl + "user/export/employeeBirthday";

export const leaveListshistory = apiUrl + "leaveEmployee/leave/history";

export const exportleaveListshistory =
  apiUrl + "leaveEmployee/export/leave/history";

export const getClientLisAsPerHC =
  apiUrl + "appointment/getClientList/healthCarerId";

export const addCancellationReason = apiUrl + "cancellationReason/create";
export const listCancellationReason = apiUrl + "cancellationReason/getlists";
export const editCancellationReason = apiUrl + "cancellationReason/edit";
export const deleteCancellationReason = apiUrl + "cancellationReason/delete";
export const getCancellationReason = apiUrl + "cancellationReason/details";

export const listAllCancellationReason =
  apiUrl + "cancellationReason/listAllCancellationReason";

export const getAllProgressNoteAsPerClient =
  apiUrl + "progress-note/getAllProgressNoteAsPerClient";
export const exportCancellationReason =
  apiUrl + "cancellationReason/list/export";

export const getAllUserByType = apiUrl + "user/getAllUserByType";
export const createHcOnBoardingTeam = apiUrl + "user/createOnBoardingTeam";
export const checkUserChild = apiUrl + "user/checkUserChild";
export const getTeamMemberDetailsById =
  apiUrl + "user/getTeamMemberDetailsById";
export const getAllTeamOnBoardingDetails =
  apiUrl + "user/getAllTeamOnBoardingDetails";
export const UserAsPerUserTypeForTemplate =
  apiUrl + "templatemaster/getUserAsUserType";
export const userTypeListForTemplate =
  apiUrl + "templatemaster/getUserTypeList";

//client onboard csv bulk import
export const importOnboardedClient = apiUrl + "importClientOnboard/create";
export const getAllOnboardedClient = apiUrl + "importClientOnboard/getAll";

export const getServiceProviderActivePackage =
  apiUrl + "sidebar/get-all/service-provider-active-menu";

export const getSidebarListByRole = apiUrl + "sidebar/get/role/list";

export const addSidebarMenus = apiUrl + "sidebar/create";

export const addSpSidebarMenu = apiUrl + "sidebar/add-sp-sidebar-menu";
export const updateSpSidebarMenu = apiUrl + "sidebar/update-sp-sidebar";

export const addMedicineFrequency = apiUrl + "medicineFrequency/create";
export const listMedicineFrequency = apiUrl + "medicineFrequency/getList";
export const deleteMedicineFrequency = apiUrl + "medicineFrequency/delete";
export const editMedicineFrequency = apiUrl + "medicineFrequency/update";
export const getMedicineFrequency = apiUrl + "medicineFrequency";
export const exportMedicineFrequency = apiUrl + "medicineFrequency/get/export";

export const listAllCancellationReasons =
  apiUrl + "cancellationReason/listAllCancellationReason";

export const addGoalByClientId = apiUrl + "goal/addGoalsByClientId";

export const updateGoalByClientId = apiUrl + "goal/update";

export const getClientIndividualClientGoalDetails =
  apiUrl + "goal/getIndividualGoalDetails";

export const getClientIndividualClientGoalDetailsExport =
  apiUrl + "goal/getIndividualGoalDetailsExport";
export const deleteClientGoal = apiUrl + "goal/delete";

export const getGoalDetailsById = apiUrl + "goal/getGoalDetailsById";

export const addTaskForGoals = apiUrl + "goal/addTaskForGoals";

export const getRoutineAndTask = apiUrl + "goal/getRoutineAndTask";

export const getGoalTask = apiUrl + "goal/getGoalTasks";

export const updateGoalTask = apiUrl + "goal/updateGoalTask";

export const goalDetailsById = apiUrl + "goal/goalDetailsById";

export const deleteGoalTask = apiUrl + "goal/deleteGoalTask";

export const accessRoleCreate = apiUrl + "access-role-permission/create";
export const accessRoleList = apiUrl + "access-role-permission/list";
export const MenuAccessList = apiUrl + "access-role-permission/MenuAccessList";

export const CheckAccessSetValue =
  apiUrl + "access-role-permission/CheckAccessSetValue";
export const checkPayrunSetting =
  apiUrl + "access-role-permission/checkPayrunSetting";
export const accessRoleListScSp = apiUrl + "access-role-permission/sc/sp/list";
export const importOnboardedStaff = apiUrl + "importClientOnboard/staff/create";
export const getAllOnboardedStaff = apiUrl + "importClientOnboard/staff/getAll";
export const getAllSpScPackageMenu = apiUrl + "sidebar/get/sp/sc/active-menu";
export const checkDataPresentOrNot = apiUrl + "user/check-data-present-or-not";

export const importOnboardedServiceProvider =
  apiUrl + "importClientOnboard/ServiceProvider/create";
export const getAllOnboardedServiceProvider =
  apiUrl + "importClientOnboard/ServiceProvider/getAll";

export const listHealthIssues = apiUrl + "healthIssue/get-all-healthIssue";
export const deleteHealthIssues = apiUrl + "healthIssue/delete";
export const updateHealthIssues = apiUrl + "healthIssue/update";
export const addHealthIssues = apiUrl + "healthIssue/create";
export const getHealthIssues = apiUrl + "healthIssue";
export const exportHealthIssues = apiUrl + "healthIssue/list/export";
export const getAllHealthIssueList =
  apiUrl + "healthIssue/get-all/health-issue/list";

export const addInformation = apiUrl + "information/create";
export const listInformation = apiUrl + "information/getlists";
export const editInformation = apiUrl + "information/edit";
export const deleteInformation = apiUrl + "information/delete";
export const getInformation = apiUrl + "information/details";
export const exportInformation = apiUrl + "information/list/export";

export const addObservation = apiUrl + "observation/addObservation";
export const importOnboardedHC =
  apiUrl + "importClientOnboard/HealthCarer/create";
export const getAllOnboardedHC =
  apiUrl + "importClientOnboard/HealthCarer/getAll";

export const cloneMasters = apiUrl + "access-role-permission/clone-document";

export const listObservation = apiUrl + "observation/getObservationList";

export const getIndividualObservation =
  apiUrl + "observation/getIndividualObservation";
export const deleteObservation = apiUrl + "observation/delete";

export const updateObservation = apiUrl + "observation/updateObservation";

export const listWeight = apiUrl + "observation/getWeightList";

export const detailsWeight = apiUrl + "observation/getIndividualWeight";

export const updateWeight = apiUrl + "observation/weightupdate";
export const listSeizure = apiUrl + "observation/getSeizureList";
export const detailsSeizure = apiUrl + "observation/getIndividualSeizure";

export const getAllGoalDataById = apiUrl + "goal/getAllGoalData";
export const addMedication = apiUrl + "medication/create";
export const getMedicationDetails = apiUrl + "medication";
export const getMedicationList = apiUrl + "medication/getList";
export const updateMedication = apiUrl + "medication/update";
export const deleteMedication = apiUrl + "medication/delete";
export const medicalHistoryAddButtonCheck =
  apiUrl + "medication/medicalHistoryAddButtonCheck";
export const getMedicationPdf = apiUrl + "medication/pdf";

export const addMainTypeSymptoms =
  apiUrl + "mainTypeSymptoms/addMainTypeSymptoms";
export const getMainTypeSymptomsList =
  apiUrl + "mainTypeSymptoms/getMainTypeSymptomsList";
export const mainTypeSymptomsDetails =
  apiUrl + "mainTypeSymptoms/getIndividual";
export const updateMainTypeSymptoms =
  apiUrl + "mainTypeSymptoms/updateMainTypeSymptoms";
export const deleteMainTypeSymptoms =
  apiUrl + "mainTypeSymptoms/deleteMainTypeSymptoms";
export const allMainTypeSymptomsList =
  apiUrl + "mainTypeSymptoms/allMainTypeSymptomsList";

export const addSubTypeSymptoms = apiUrl + "subTypeSymptoms/addSubTypeSymptoms";
export const getSubTypeSymptomsList =
  apiUrl + "subTypeSymptoms/getSubTypeSymptomsList";
export const subTypeSymptomsDetails = apiUrl + "subTypeSymptoms/getIndividual";
export const updateSubTypeSymptoms =
  apiUrl + "subTypeSymptoms/updateSubTypeSymptoms";
export const deleteSubTypeSymptoms =
  apiUrl + "subTypeSymptoms/deleteSubTypeSymptoms";
export const allSubTypeSymptomsList =
  apiUrl + "subTypeSymptoms/allSubTypeSymptomsList";

export const updatePackageDetails = apiUrl + "user/update/package/details";
export const scPackageUpdate = apiUrl + "supportCoordinator/package/update";

export const getHealthCarerPackageDetailsByUserId =
  apiUrl + "user/get/hc/package/details-by-userid";
export const getMedicationClient =
  apiUrl + "medication/client/getMedicationClient";

export const getMedicationClientByUser =
  apiUrl + "medication/client/getMedicationClientByUser";

export const getBowelList = apiUrl + "observation/getBowelList";
export const getIndividualBowel = apiUrl + "observation/getIndividualBowel";
export const deleteBowel = apiUrl + "observation/deleteBowel";
export const updateBowel = apiUrl + "observation/updateBowel";
export const getPersonalCareList = apiUrl + "observation/getPersonalCareList";
export const getIndividualPersonalCare =
  apiUrl + "observation/getIndividualPersonalCare";
export const updatePersonalCare = apiUrl + "observation/updatePersonalCare";
export const deletePersonalCare = apiUrl + "observation/deletePersonalCare";

export const addAtoSetting = apiUrl + "atoSetting/create";
export const editAtoSetting = apiUrl + "atoSetting/edit";
export const getAtoSetting = apiUrl + "atoSetting/details";

export const listAllHCExploreMarket = apiUrl + "user/listAllHCExploreMarket";
export const detailsHcExploreMarket = apiUrl + "healthCarer/getHCDetails";

export const getPublicRosterAppointment =
  apiUrl + "exploreMarket/getPublicRoster/appointment";

export const addToFavorites = apiUrl + "clientDetails/add-to-favorites";
export const removeToFavorites = apiUrl + "clientDetails/remove-from-favorites";
export const getClientSupportBudget =
  apiUrl + "clientDetails/getClientSupportBudget";

export const addSendRequest = apiUrl + "sendRequest/create";
export const resendNotification =
  apiUrl + "sendRequest/send/resend-notification";

export const getsendRequestDetails =
  apiUrl + "sendRequest/getsendRequestDetails";

export const cancelsendRequest = apiUrl + "sendRequest/cancelSendRequest";

export const getDetailsBySendTo = apiUrl + "sendRequest/getDetailsBySendTo";

export const addPayment = apiUrl + "payment/create";
export const editPayment = apiUrl + "payment/edit";
export const getPayment = apiUrl + "payment/details";
export const addPaymentGateway = apiUrl + "payment-gateway/create/payment";
export const makeapayment = apiUrl + "payment-gateway/create/makeapayment";

export const ShiftnoteList = apiUrl + "appointment/super-admin/shiftNote-list";

export const addMedicationChart = apiUrl + "medication/chart/create";
export const getMedicationDetailsChart = apiUrl + "medication/chart/details";
export const getMedicationListChart = apiUrl + "medication/chart/getList";
export const updateMedicationChart = apiUrl + "medication/chart/update";
export const deleteMedicationChart = apiUrl + "medication/chart/delete";
export const getAllMedicationDropdownClient =
  apiUrl + "medication/chart/getAllMedicationDropdownClient";
export const medicineChartDayUpdate =
  apiUrl + "medication/chart/medicineChartDayUpdate";
export const medicineChartWeekUpdate =
  apiUrl + "medication/chart/medicineChartWeekUpdate";
export const medicineChartMonthUpdate =
  apiUrl + "medication/chart/medicineChartMonthUpdate";
export const updatePRNMedicineValue =
  apiUrl + "medication/chart/updatePRNMedicineValue";
export const getCountofChart = apiUrl + "medication/chart/getCountofChart";

export const addDoseForm = apiUrl + "doseForm/create";
export const listDoseForm = apiUrl + "doseForm/getAll";
export const deleteDoseForm = apiUrl + "doseForm/delete";
export const editDoseForm = apiUrl + "doseForm/update";
export const getDoseForm = apiUrl + "doseForm";
export const DoseFormexport = apiUrl + "doseForm/list/export";
export const getDoseFormList = apiUrl + "doseForm/get-all-DoseForm";

export const updateRequestDetails = apiUrl + "sendRequest/update";

export const listAllClientExploreMarket =
  apiUrl + "user/listAllClientExploreMarket";

export const addClientToFavorites = apiUrl + "healthCarer/add-to-favorites";
export const removeClientToFavorites =
  apiUrl + "healthCarer/remove-from-favorites";
export const getHCDetails = apiUrl + "healthCarer/get-details-by-userid";

export const appointmentDetails =
  apiUrl + "appointment/getIndivisualAppointmentDetails";

export const addobservationMaster = apiUrl + "observationMaster/create";
export const listobservationMaster =
  apiUrl + "observationMaster/getObservationNameList";
export const detailsobservationMaster = apiUrl + "observationMaster/details";
export const editobservationMaster = apiUrl + "observationMaster/update";
export const deleteobservationMaster = apiUrl + "observationMaster/delete";
export const getallObservationName =
  apiUrl + "observationMaster/getallObservationName/List";
export const getAllDoseFormMedication = apiUrl + "doseForm/list/doseFormDD";

export const getClientDetailsDetails =
  apiUrl + "healthCarer/get-client-details-by-userid";

export const exportPdf = apiUrl + "goal/exportPdf";

export const getAllSendRequestBySendByHC =
  apiUrl + "sendRequest/getAllSendRequestBySendByHC";

export const getAllSendRequestBySendByHCAvailability =
  apiUrl + "sendRequest/getAllSendRequestBySendByHCAvailability";

export const getAllSendRequestBySendByClient =
  apiUrl + "sendRequest/getAllSendRequestBySendByClient";

export const getAllSendRequestBySendByClientAvailability =
  apiUrl + "sendRequest/getAllSendRequestBySendByClientAvailability";

export const getAllRecievedRequestBySendByHC =
  apiUrl + "sendRequest/getAllRecievedRequestBySendByHC";

//fundSupport
export const getFindSupportList = apiUrl + "sendRequest/findsupport";

export const addFindSupport = apiUrl + "sendRequest/findsupport/add";

export const getAllFindSupport = apiUrl + "sendRequest/findsupport/getall/list";

export const getAllSupportFindRequest =
  apiUrl + "sendRequest/findsupport/getall/request";

export const getAllRecievedRequestBySendByClientForFindSupport =
  apiUrl +
  "sendRequest/findsupport/getAll/RecievedRequestBySendByClientForFindSupport";

export const getAllRecivedContinueShiftNote =
  apiUrl + "sendRequest/getAllRecivedContinueShiftNote";

export const getAllRecievedRequestBySendByHCAvailability =
  apiUrl + "sendRequest/getAllRecievedRequestBySendByHCAvailability";

export const getAllAppointmentRequestBySendByHC =
  apiUrl + "sendRequest/getAllAppointmentRequestBySendByHC";

export const getAllRecievedRequestBySendByClient =
  apiUrl + "sendRequest/getAllRecievedRequestBySendByClient";

export const getAllRecievedRequestBySendByClientAvailability =
  apiUrl + "sendRequest/getAllRecievedRequestBySendByClientAvailability";

export const getAllAppointmentRequestBySendByClient =
  apiUrl + "sendRequest/getAllAppointmentRequestBySendByClient";
export const getMensurationListWithPagination =
  apiUrl + "observation/page/getMensurationListWithPagination";
export const getMensurationList = apiUrl + "observation/getMensurationList";
export const getIndividualMensuration =
  apiUrl + "observation/getIndividualMensuration";

export const deleteMenstruation = apiUrl + "observation/deleteMenstruation";
export const updateMenstruation = apiUrl + "observation/updateMenstruation";

export const listProgressnoteByclient = apiUrl + "progress-note/client/all";

export const addTaxation = apiUrl + "taxation/create";
export const listTaxation = apiUrl + "taxation/getlists";
export const editTaxation = apiUrl + "taxation/edit";
export const deleteTaxation = apiUrl + "taxation/delete";
export const getTaxation = apiUrl + "taxation/details";

export const getMedicationclientDetails =
  apiUrl + "observation/getMedicationclientDetails";
export const exportTaxation = apiUrl + "taxation/list/export";

export const addMeal = apiUrl + "meal/create";
export const listMeal = apiUrl + "meal/getlists";
export const editMeal = apiUrl + "meal/edit";
export const deleteMeal = apiUrl + "meal/delete";
export const getMeal = apiUrl + "meal/details";
export const exportMeal = apiUrl + "meal/list/export";

export const addpaySchedule = apiUrl + "paySchedule/create";
export const listpaySchedule = apiUrl + "paySchedule/getAll";
export const editpaySchedule = apiUrl + "paySchedule/update";
export const deletepaySchedule = apiUrl + "paySchedule/delete";
export const getpaySchedule = apiUrl + "paySchedule/details";
export const exportpaySchedule = apiUrl + "paySchedule/list/export";
export const payScheduleList = apiUrl + "paySchedule/all-list";

export const updateSendRequest = apiUrl + "sendRequest/updateSendRequestStatus";
export const updateAvailabilityStatus =
  apiUrl + "sendRequest/updateAvailabilityStatus";

export const listSubscriptionPlans =
  apiUrl + "serviceProvider/get-service-provider-details-list-by-userid";

export const payScheduledetails = apiUrl + "user/payscheduleDetails";
export const updatepayschedule = apiUrl + "user/updatepayschedule";
export const payRunCreate = apiUrl + "pay-run/create";
export const payRunCreateContractor = apiUrl + "pay-run-contractor/create";

export const payRunCreateISC = apiUrl + "pay-run/ISC/create";
export const payRunList = apiUrl + "pay-run/list";

export const payRunListContractor = apiUrl + "pay-run-contractor/list";
export const payRunListContractorExport = apiUrl + "pay-run-contractor/export";

export const payRunListContractorService =
  apiUrl + "pay-run-contractor/list/service";
export const payRunListContractorExportService =
  apiUrl + "pay-run-contractor/export/service";

export const payRunExport = apiUrl + "pay-run/export";

export const payslipPdf = apiUrl + "setTimesheet/payrateDetails";
export const payrateDetailsByIds = apiUrl + "setTimesheet/payrateDetailsByIds";

export const addpayrunStatus = apiUrl + "payrunStatus/create";
export const listpayrunStatus = apiUrl + "payrunStatus/getAll";
export const editpayrunStatus = apiUrl + "payrunStatus/update";
export const deletepayrunStatus = apiUrl + "payrunStatus/delete";
export const getpayrunStatus = apiUrl + "payrunStatus/getIndividual";
export const payrunStatusList = apiUrl + "payrunStatus/getpayrunStatusList";

export const getAllDocumentRequestRecievedBHC =
  apiUrl + "sendRequest/getAllDocumentsRequestRecievedByHC";

export const payrunStatusListAll = apiUrl + "payrunStatus/allpayrunStatusList";

export const getFavouriteDetails = apiUrl + "user/getFavouriteDetails";

export const createSignedDocument = apiUrl + "signedDocument/create";
export const getAllSignedDocument =
  apiUrl + "signedDocument/get-all-signedDocument";
export const getAllSignedDocumentWithPagination =
  apiUrl + "signedDocument/get-all-signedDocumentWithPagination";
export const getListAllSignedDocument =
  apiUrl + "signedDocument/list-signedDocument";
export const uploadSignedDocument = apiUrl + "signedDocument/uploadSignature";

export const downloadS3Objects = apiUrl + "image/downloadS3Objects";
export const getHCMedicationList =
  apiUrl + "healthCarer/getHCMedicationList/details";
export const exportClients = apiUrl + "clientDetails/export/client";
export const exportevaluationClients =
  apiUrl + "clientDetails/get-evaluation/export";

export const exportHealthcarer = apiUrl + "healthCarer/export-get/healthCarer";
export const exportevaluationHealthcarer =
  apiUrl + "healthCarer/get-export/healthCarer";

export const exportservicepro = apiUrl + "serviceProvider/get-export/list";

export const updateDetails = apiUrl + "sendRequest/updateDetails";
export const updateAppointmentDetails =
  apiUrl + "sendRequest/updateAppointmentDetails";

export const checkMeetingSuscription =
  apiUrl + "meeting/check/meeting/suscription";

export const subscriptionPlanHistoryAll =
  apiUrl + "user/getSubscriptionPlanHistory";

export const subscriptionPlanHistoryFilter =
  apiUrl + "user/getSubscriptionPlanHistoryFilter";

export const getFilterForUserManagement =
  apiUrl + "user/getFilterForUserManagement";

export const addSendEmail = apiUrl + "sendEmail/create";
export const getSendEmailList = apiUrl + "sendEmail/get-all-email";
export const getScheduledSendEmailList =
  apiUrl + "sendEmail/get-email/scheduled";
export const getSendEmailDetails = apiUrl + "sendEmail";
export const deleteSendEmail = apiUrl + "sendEmail/delete";
export const sendScheduledEmail = apiUrl + "sendEmail/sendScheduledEmail";
export const updateSendEmail = apiUrl + "sendEmail/update";
export const allWorkingUsersForMail =
  apiUrl + "sendEmail/allWorkingUsersForMail/sendReq";

export const addSuperFunds = apiUrl + "super-annuation/create";
export const getSuperFundsList = apiUrl + "super-annuation/get-all-annuation";
export const getSuperFundsDetails = apiUrl + "super-annuation";
export const deleteSuperFunds = apiUrl + "super-annuation/delete";
export const updateSuperFunds = apiUrl + "super-annuation/update";
export const exportSuperFunds = apiUrl + "super-annuation/list/export";

export const getStartedAppoitmentDetails =
  apiUrl + "appointment/getStartedAppointmentDetails";
export const deleteContinueShiftNote =
  apiUrl + "appointment/updateContinueShiftNote";

export const listGroupName = apiUrl + "group/listAllGroup";

export const ClientDetailsData =
  apiUrl + "clientDetails/client-details-Data-by-userId";

export const getAvailabilityObjectDetails =
  apiUrl + "sendRequest/getAvailabilityObjectDetails";
export const getAllUsers = apiUrl + "user/getAllUsers";

export const getStateDetailsClient =
  apiUrl + "clientDetails/getStateDetailsClient";

export const getPackageMenuByUserId = apiUrl + "user/getPackageMenuByUserId";

export const exportIncidents = apiUrl + "incident/incident/export";

export const favoriteclients = apiUrl + "user/client-get-fav";

export const detailsByid = apiUrl + "user/fav-get-details-fav";

export const getAllSendRequest = apiUrl + "sendRequest/getAllSendRequest";

export const addSuperAnnuationSetting =
  apiUrl + "superannuation-setting/create";
export const getSuperAnnuationSettingList =
  apiUrl + "superannuation-setting/get-all-superannuationsetting";
export const getSuperAnnuationSettingDetails =
  apiUrl + "superannuation-setting";
export const deleteSuperAnnuationSetting =
  apiUrl + "superannuation-setting/delete";
export const updateSuperAnnuationSetting =
  apiUrl + "superannuation-setting/update";
export const exportSuperAnnuationSetting =
  apiUrl + "superannuation-setting/list/export";

export const getRequestDetails = apiUrl + "sendRequest/getRequestDetails";

export const addPolicy = apiUrl + "policy/create";
export const listPolicy = apiUrl + "policy/getlists";
export const detailsPolicy = apiUrl + "policy/details";
export const editPolicy = apiUrl + "policy/edit";
export const deletePolicy = apiUrl + "policy/delete";
export const getPolicyexport = apiUrl + "policy/getPolicyexport";
export const uploadPdf = apiUrl + "image/upload-pdf";

export const getClientAsPerHealthCarerId =
  apiUrl + "goal/getClientAsPerHealthCarerId";
export const getClientAsPerHealthCarerIdHCInvoice =
  apiUrl + "goal/getClientAsPerHealthCarerIdHCInvoice";
export const exportEmployeeLeave = apiUrl + "leaveEmployee/exportEmployeeLeave";
export const exportSecondaryUser = apiUrl + "user/exportSecondaryUser";
export const exportUserTypesList = apiUrl + "userType/exportUserTypesList";
export const exportAllLeavesDetails =
  apiUrl + "leaveEmployee/exportAllLeavesDetails";
export const exportincident = apiUrl + "incident/exportincident";

// export const clientAppointmentLists = apiUrl + "appointment/clientAppointmentLists";

export const importOnboardedSC =
  apiUrl + "importClientOnboard/SupportCoordinator/create";
export const getAllOnboardedSC =
  apiUrl + "importClientOnboard/SupportCoordinator/getAll";

export const addAvailablityStatus = apiUrl + "availabilityStatus/create";
export const listAvailablityStatus = apiUrl + "availabilityStatus/getAll";
export const deleteAvailablityStatus = apiUrl + "availabilityStatus/delete";
export const editAvailablityStatus = apiUrl + "availabilityStatus/update";
export const getAvailablityStatus = apiUrl + "availabilityStatus";
export const clientAppointmentLists =
  apiUrl + "appointment/clientAppointmentLists";

export const getClientDetailsAppt =
  apiUrl + "exploreMarket/getClientDetails/UserId";
export const addGuestUser = apiUrl + "exploreMarket/addGuestUser";
export const updateGuestUser = apiUrl + "exploreMarket/updateGuestUser";
export const getGuestUserDetailsById =
  apiUrl + "exploreMarket/getGuestUserDetailsById";

export const getClientSupportBudgetManaged =
  apiUrl + "clientDetails/getSupportBudgetClient/managed";

export const getMonthwiseClientSupportBudgetIndSc =
  apiUrl + "clientDetails/IndSc/getMonthwiseClientSupportBudget";

export const getClientSupportBudgetManagedMonthly =
  apiUrl + "clientDetails/monthwiseGetSupportBudget";

export const getClientSupportBudgetManagedCategory =
  apiUrl + "clientDetails/categorywiseGetSupportBudget";

export const addSupportDuration = apiUrl + "supportDuration/create";
export const listSupportDuration = apiUrl + "supportDuration/getsupprtDuration";
export const detailSupportDuration = apiUrl + "supportDuration/details";
export const updateSupportDuration = apiUrl + "supportDuration/update";
export const deleteSupportDuration = apiUrl + "supportDuration/delete";

export const addSupportHours = apiUrl + "supportHours/create";
export const listSupportHours = apiUrl + "supportHours/getsupprtHours";
export const detailSupportHours = apiUrl + "supportHours/details";
export const updateSupportHours = apiUrl + "supportHours/update";
export const deleteSupportHours = apiUrl + "supportHours/delete";

export const getEmployeeAppliedLeaveDetails =
  apiUrl + "setLeave/getEmployeeAppliedLeaveNumber";

export const exportHealthCarerOffboarding =
  apiUrl + "healthCarer/offboarding/export";

export const getHealthCarerLeaves =
  apiUrl + "healthCarer/getHealthCarerLeaves/get";

export const getHealthCarerAlerts =
  apiUrl + "healthCarer/getHealthCarerAlerts/get";

export const getHealthCarerMedicationAlert =
  apiUrl + "healthCarer/getHealthCarerMedication/Alert";

export const getHealthCarerRatings =
  apiUrl + "healthCarer/getHealthCarerRatings/get";

export const addTeamtask = apiUrl + "teamTask/create";
export const countTeamtask = apiUrl + "teamTask/count";
export const viewTeamtasklist = apiUrl + "teamTask/task-list/all";
export const detailsTeamtasklist = apiUrl + "teamTask/details";
export const updateTeamtasklist = apiUrl + "teamTask/team-taskupdate";
export const deleteTeamtasklist = apiUrl + "teamTask/delete";

export const allTeamOnBoardingDetails =
  apiUrl + "user/allTeamOnBoardingDetails";
export const getHealthCarerPayrunAppointment =
  apiUrl + "healthCarer/getHealthCarerPayrunAppointment/get";

export const updatedetailsuser = apiUrl + "user/updatedetailsuser";

export const addServiceItem = apiUrl + "serviceItem/create";

export const searchSupportItem = apiUrl + "serviceItem/supportitem/search";
export const listServiceItem = apiUrl + "serviceItem/getlists";
export const editServiceItem = apiUrl + "serviceItem/edit";
export const deleteServiceItem = apiUrl + "serviceItem/delete";
export const getServiceItem = apiUrl + "serviceItem/details";
export const listAllServiceItem = apiUrl + "serviceItem/listAllServiceItem";
export const exportServiceItem = apiUrl + "serviceItem/list/export";
export const getAllServiceItemList =
  apiUrl + "serviceItem/get-all-service-item";
export const getAllServiceItemListCost =
  apiUrl + "serviceItem/withCost/get-all-service-item";
export const listAllUnitItem = apiUrl + "serviceItem/listAllUnitItem";

export const addServiceTask = apiUrl + "serviceTask/create";
export const listServiceTask = apiUrl + "serviceTask/getlists";
export const listServiceTaskFilter = apiUrl + "serviceTask/getlistsWithFilter";
export const listServiceTaskAllFilter =
  apiUrl + "serviceTask/getServiceTaskListFilter/all";
export const getServiceTaskUnitType =
  apiUrl + "serviceTask/getServiceTask/UnitType";
export const editServiceTask = apiUrl + "serviceTask/edit";
export const deleteServiceTask = apiUrl + "serviceTask/delete";
export const getServiceTask = apiUrl + "serviceTask/details";
export const listAllServiceTask = apiUrl + "serviceTask/listAllServiceTask";
export const exportServiceTask = apiUrl + "serviceTask/list/export";
export const editServiceTaskNote = apiUrl + "serviceTask/edit/notes";
export const getAllUserByCreatedBy =
  apiUrl + "supportCoordinator/getAllUserByCreatedBy/all";

export const listScClient = apiUrl + "clientDetails/sc-export/getScClientList";

export const exportNewreqSC =
  apiUrl + "supportCoordinator/newrequest/export-all-export";

export const AllexportSC = apiUrl + "supportCoordinator/sc/all-get-export";

export const listallHealthCarer = apiUrl + "healthCarer/hc/get-all-export";

export const AddcommunityAccessProvider =
  apiUrl + "communityAccessProvider/create";
export const ListcommunityAccessProvider =
  apiUrl + "communityAccessProvider/list-all";

export const listYearToDate = apiUrl + "yeartoDate/getyearTodateList";

export const getListByUserId = apiUrl + "user/individual/userList";

export const addComplaints = apiUrl + "complaint/create";
export const listComplaint = apiUrl + "complaint/get-all-complaints/pagination";
export const deleteComplaint = apiUrl + "complaint/delete";
export const editComplaint = apiUrl + "complaint/update";
export const getComplaint = apiUrl + "complaint";
export const exportComplaint = apiUrl + "complaint/export/complaint";

export const listpastYears = apiUrl + "publicHoliday/past-list-all";
export const listcurrentYears = apiUrl + "publicHoliday/current-list-all";
export const listnextYears = apiUrl + "publicHoliday/next-list-all";

export const listSuperFundsList = apiUrl + "super-annuation/all/get-list";

export const AlllistTaxation = apiUrl + "taxation/all/tax-list";

export const addBspquestion = apiUrl + "bspQuestion/create";
export const listBspquestion = apiUrl + "bspQuestion";
export const deleteBspquestion = apiUrl + "bspQuestion/delete";
export const detailsBspquestion = apiUrl + "bspQuestion";
export const AlllistBspquestion = apiUrl + "bspQuestion/all-list/get-question";
export const updateBspquestion = apiUrl + "bspQuestion/update";
export const getClientRiskAssesment =
  apiUrl + "clientDetails/getClientRiskAssesment";

export const getGeneralManagerClient =
  apiUrl + "clientDetails/all/getGeneralManagerClient";

export const bspquestionAdd = apiUrl + "clientDetails/bsp-add";
export const getBpsquestionDetails =
  apiUrl + "clientDetails/getBspAnswersDetails";

export const addPlanManager = apiUrl + "planManager/create";
export const listPlanManager = apiUrl + "planManager/list/getlists";
export const editPlanManager = apiUrl + "planManager/update";
export const deletePlanManager = apiUrl + "planManager/delete";
export const getPlanManagerDetails = apiUrl + "planManager/details";
export const exportPlanManager = apiUrl + "planManager/list/export";

export const swapReportlist = apiUrl + "appointment/get-all/swap-list";
export const swapReportlistExport =
  apiUrl + "appointment/get-all/swap-list-export";

export const updateClientDetailInsertFM =
  apiUrl + "clientDetails/updateClientDetailInsertFM";

export const getClientDetailRecordByUserId =
  apiUrl + "clientDetails/getClientDetailRecordByUserId";

export const getExpallLeavehistory =
  apiUrl + "leaveEmployee/get-list/ExpallLeavehistory";
export const getListShiftAppointment =
  apiUrl + "appointment/fetch/getListShiftAppointment";

export const userTypeListForLeave =
  apiUrl + "leaveEmployee/userTypeListForLeave/all";

export const getFamilyMemberBlock =
  apiUrl + "clientDetails/getFamilyMemberBlock";

export const updateFamilyMemberBlock =
  apiUrl + "clientDetails/updateFamilyMemberBlock/client";

export const allexportIncident = apiUrl + "incident/incident/allexportIncident";
export const deleteWeight = apiUrl + "observation/deleteWeight";
export const deleteSeizure = apiUrl + "observation/deleteSeizure";

export const GetClientIdListFromStakeholder =
  apiUrl + "stakeHolderspecialist/GetClientIdListFromStakeholder/Stake";

export const GetClientIdListFromFamilyMember =
  apiUrl + "stakeHolderspecialist/GetClientIdListFromFamilyMember/FamilyMember";

export const addHomeFeature = apiUrl + "HomeFeature/create";
export const getHomeFeaturelist = apiUrl + "HomeFeature/getHomeFeature";
export const deleteHomeFeature = apiUrl + "HomeFeature/delete";
export const detailsHomeFeaturelist = apiUrl + "HomeFeature";
export const updateHomeFeaturelist = apiUrl + "HomeFeature/update";
export const HomeFeaturesForLoop =
  apiUrl + "HomeFeature/HomeFeaturesForLoop/group";

export const taskListall = apiUrl + "quickTask/all/taskListall";
export const TaskCount = apiUrl + "quickTask/TaskCount/Dashboard";

export const Addassets = apiUrl + "assets/create";

//sil-sda-location
export const addSilSdaLocation = apiUrl + "silsdaLocation/addSilSdaLocation";
export const paginationSilSdaLocation =
  apiUrl + "silsdaLocation/paginationSilSdaLocation";
export const listSilSdaLocation = apiUrl + "silsdaLocation/listSilSdaLocation";
export const getListSilSdaLocationByUser =
  apiUrl + "silsdaLocation/getListSilSdaLocationByUser";
export const detailSilSdaLocation =
  apiUrl + "silsdaLocation/detailSilSdaLocation";
export const updateSilSdaLocation =
  apiUrl + "silsdaLocation/updateSilSdaLocation";
export const deleteSilSdaLocation =
  apiUrl + "silsdaLocation/deleteSilSdaLocation";
export const exportSilSdaLocation =
  apiUrl + "silsdaLocation/exportSilSdaLocation";
export const SPCompanyDetailsAddress =
  apiUrl + "silsdaLocation/SPCompanyDetailsAddress";

export const Listassets = apiUrl + "assets/get/getAssetList";
export const getAssetListExport = apiUrl + "assets/get/getAssetListExport";
export const Updateassets = apiUrl + "assets/update";
export const Detailsassets = apiUrl + "assets";
export const Deleteassets = apiUrl + "assets/delete";

export const addtaxDetails = apiUrl + "user/createtaxDetails";

export const allSilSdaLocationlist =
  apiUrl + "silsdaLocation/allSilSdaLocationlist";

export const allHomeFeaturepagination =
  apiUrl + "HomeFeature/get/allHomeFeaturepagination";

export const listSilSdaLocationRoleWise =
  apiUrl + "clientDetails/listSilSdaLocationRoleWise/list";
export const getSignedDocumentById = apiUrl + "signedDocument";
export const setSdaLocationToClient =
  apiUrl + "clientDetails/setSdaLocationToClient/here";
export const removeSdaLocationToClient =
  apiUrl + "clientDetails/setSdaLocationToClient/remove";
export const getAllergiesAndRestrictivePractices =
  apiUrl + "clientDetails/getAllergiesAndRestrictivePractices/OfClient";

export const getClientDetailssilsda =
  apiUrl + "clientDetails/getClientDetailssilsda";
export const GetPublicHolidayByStateId =
  apiUrl + "publicHoliday/GetPublicHolidayByStateId";
export const uploadHtmlPdf = apiUrl + "sendAttachEmail/upload-html-pdf";

export const detailsAssetListid = apiUrl + "assets/list/detailsAssetListid";

export const addcompliance = apiUrl + "compliance/create";
export const getcomplianceList = apiUrl + "compliance/get/getcomplianceList";
export const complianceDetails = apiUrl + "compliance";
export const complianceUpdate = apiUrl + "compliance/update";
export const compliancedelete = apiUrl + "compliance/delete";

export const clientDocumentUpdate = apiUrl + "clientDetails/document-update";
export const getClientDocuments =
  apiUrl + "clientDetails/getListDocumentsOfClient";

//moodle-lms
export const addLmsMoodleStudnts = apiUrl + "moodleLMS/addLmsMoodleStudnts";
export const listLmsMoodleStudnts = apiUrl + "moodleLMS/listLmsMoodleStudnts";
export const paginationLmsMoodleStudnts =
  apiUrl + "moodleLMS/paginationLmsMoodleStudnts";
export const detailLmsMoodleStudnts =
  apiUrl + "moodleLMS/detailLmsMoodleStudnts";
export const updateLmsMoodleStudnts =
  apiUrl + "moodleLMS/updateLmsMoodleStudnts";
export const deleteLmsMoodleStudnts =
  apiUrl + "moodleLMS/deleteLmsMoodleStudnts";

export const clientRatingList = apiUrl + "appointment/ratingsOfClient/getlists";
export const hcRatingList =
  apiUrl + "appointment/ratingsOfHealthCarer/getlists";
export const GetMoodleCourses = apiUrl + "moodleLMS/GetMoodleCourses";
export const GetEnrolledStudentByCourseId =
  apiUrl + "moodleLMS/GetEnrolledStudentByCourseId";
export const GetMoodleLessonByCourseId =
  apiUrl + "moodleLMS/GetMoodleLessonByCourseId";
export const GetStudentDetails = apiUrl + "moodleLMS/GetStudentDetails";
export const GetStudentCourses = apiUrl + "moodleLMS/GetStudentCourses";
export const GetAdminToken = apiUrl + "moodleLMS/GetAdminToken";
export const CourseAPI = apiUrl + "moodleLMS/CourseAPI";
export const CourseFetchVideo = apiUrl + "moodleLMS/CourseFetchVideo";

export const getasperrolePackageMenu =
  apiUrl + "packageMenu/all-list-getlistPackageMenu";
export const getUserRolePackageMenu =
  apiUrl + "packageMenu/user-list-getlistPackageMenu";
//email-template
export const addEmailTemplate = apiUrl + "mailTemplate/addEmailTemplate";
export const listEmailTemplate = apiUrl + "mailTemplate/listEmailTemplate";
export const EmailTemplateCreated =
  apiUrl + "mailTemplate/UserWiseEmailTemplate";
export const paginationEmailTemplate =
  apiUrl + "mailTemplate/paginationEmailTemplate";
export const detailEmailTemplate = apiUrl + "mailTemplate/detailEmailTemplate";
export const updateEmailTemplate = apiUrl + "mailTemplate/updateEmailTemplate";
export const deleteEmailTemplate = apiUrl + "mailTemplate/deleteEmailTemplate";

export const listContactForm = apiUrl + "contactForm/getlists";
export const listGuideDownload = apiUrl + "guideDownload/getlists";
export const listDemoRequest = apiUrl + "demoRequest/getlists";

//upload digital signature
export const uploadDigitalSignature = apiUrl + "image/upload-signature";
export const tooltip = apiUrl + "helperText/list/isActive";
export const getDigitalSignature =
  apiUrl + "signedDocument/getDigitalSignature";

export const listBlog = apiUrl + "blog/getlists";
export const addBlog = apiUrl + "blog/create";
export const editBlog = apiUrl + "blog/edit";
export const deleteBlog = apiUrl + "blog/delete";
export const getBlog = apiUrl + "blog/details";
export const listBlogForDetails = apiUrl + "blog/getBlogListForDetails";
export const listGuideForDetails = apiUrl + "guide/getGuideListForDetails";
export const listGuide = apiUrl + "guide/getlists";
export const addGuide = apiUrl + "guide/create";
export const editGuide = apiUrl + "guide/edit";
export const deleteGuide = apiUrl + "guide/delete";
export const getGuide = apiUrl + "guide/details";

export const addBlogAndGuideCategory = apiUrl + "blogAndGuideCategory/create";
export const listBlogAndGuideCategory = apiUrl + "blogAndGuideCategory/getList";
export const deleteBlogAndGuideCategory =
  apiUrl + "blogAndGuideCategory/delete";
export const editBlogAndGuideCategory = apiUrl + "blogAndGuideCategory/update";
export const getBlogAndGuideCategory = apiUrl + "blogAndGuideCategory";
export const blogAndGuideCategoryExport =
  apiUrl + "blogAndGuideCategory/list/export";
export const getBlogAndGuideCategoryList =
  apiUrl + "blogAndGuideCategory/list/get-all-category";

export const addtemplateType = apiUrl + "templateType/create";
export const listtemplateType = apiUrl + "templateType/get-all-template";
export const detailstemplateType = apiUrl + "templateType";
export const updatetemplateType = apiUrl + "templateType/update";
export const templateTypeListall = apiUrl + "templateType/templateTypeListall";
export const deletetemplateType = apiUrl + "templateType/delete";
export const addGuideDownload = apiUrl + "guideDownload/create";
export const addDemoRequest = apiUrl + "demoRequest/create";
export const addContactForm = apiUrl + "contactForm/create";
export const getDetailsPublicRosterAppointment =
  apiUrl + "exploreMarket/getAppointmentDetails/PublicRoster";
export const loginIndividualHC = apiUrl + "exploreMarket/loginIndividualHC";
export const loginClientAndSP = apiUrl + "exploreMarket/loginClientAndSP";
//Chat-History-By-Admin
export const supperAdminChatHistoryList =
  apiUrl + "chat/super-admin/chatHistory-list";
export const getChatDeta = apiUrl + "chat/super-admin/getChatData";

export const getIndvHCinAppt = apiUrl + "healthCarer/getIndvHCinAppt";
export const setIndvHCinAppt = apiUrl + "healthCarer/setIndvHCinAppt";
export const setFavAppointment = apiUrl + "healthCarer/setFavAppointment";
export const getClientRating = apiUrl + "healthCarer/getClientRating";
export const getHCLikedAppointment =
  apiUrl + "healthCarer/getHCLikedAppointment";

export const getAllTemplateType = apiUrl + "templateType/getAll";
export const getAllEmployeesList = apiUrl + "user/getAllEmployeesList";
export const addDemoTime = apiUrl + "demoTime/create";
export const editDemoTime = apiUrl + "demoTime/update";
export const listDemoTime = apiUrl + "demoTime/getlists";
export const deleteDemoTime = apiUrl + "demoTime/delete";
export const detailsDemoTime = apiUrl + "demoTime/details";
export const exportDemoTime = apiUrl + "demoTime/list/export";
export const getAllUserTypesFilter = apiUrl + "user/getAllUserTypesFilter";

export const getSendEmailListForUser =
  apiUrl + "sendEmailForUser/get-all-email";

export const getScheduledSendEmailListForUser =
  apiUrl + "sendEmailForUser/get-email/scheduled";

export const allWorkingUsersForMailForUser =
  apiUrl + "sendEmailForUser/allWorkingUsersForMail/sendReq";
export const deletecommunityAccessProvider =
  apiUrl + "communityAccessProvider/delete";

export const getHealthCarerPublicRosterAppointment =
  apiUrl + "exploreMarket/HcId/getHealthCarerPublicRosterAppointment";

export const getRequestedPublicRosterAppointment =
  apiUrl + "exploreMarket/requested/getRequestedPublicRosterAppointment";

export const allAppointmentListsLiveRoaster =
  apiUrl + "exploreMarket/LVRoaster/allAppointmentListsLiveRoaster";

export const setIndvHCinApptStatus =
  apiUrl + "healthCarer/appoint/setIndvHCinApptStatus";

export const editDemoRequest = apiUrl + "demoRequest/edit";
export const getDemoRequest = apiUrl + "demoRequest/details";
export const exportDemoRequest = apiUrl + "demoRequest/list/export";
export const editDemoScheduleMeeting =
  apiUrl + "demoRequest/update-schedule-meeting";
export const allexportServiceItemList =
  apiUrl + "serviceItem/allexportServiceItemList";
export const getSignUrl = apiUrl + "image/get-pre-sign-url";
export const addInterestedIn = apiUrl + "interestRegister/create";
export const listInterestedIn = apiUrl + "interestRegister/getlists";
export const demoUserSignup = apiUrl + "user/demoUserSignup";
export const demostatusUpdate = apiUrl + "demoRequest/status-update";
export const getDemoRequestFilter = apiUrl + "demoRequest/getDemoRequestFilter";

export const exportAppointmentByidHealt =
  apiUrl + "appointment/get-all/exportAppointmentByidHealth";
export const getexportEmpDetailsReport =
  apiUrl + "user/getexportEmpDetailsReport";
export const getListAllSignedDocumentByUserId =
  apiUrl + "signedDocument/list-signedDocument-by-userId";
export const uploadReceiverSignature =
  apiUrl + "signedDocument/uploadReceiverSignature";

export const IndividualUserSignup =
  apiUrl + "individualUser/IndividualUserSignup";
export const refundPlan = apiUrl + "user/refundPlan";
export const cancelPlan = apiUrl + "user/cancelPlan";
export const addPaymentGatewayForSa =
  apiUrl + "payment-gateway/create/payment/sa";
export const LastrecordObservation =
  apiUrl + "observation/getLastrecordObservation";
export const filtergetServiceTaskList =
  apiUrl + "serviceTask/filter/getServiceTaskList";
//xero API
export const authorize = apiUrl + "xero/authorize";
export const pushToXeroTimeSheetList = apiUrl + "xero/getAllTimeSheet";
export const pushToXeroTimeSheetContractorList =
  apiUrl + "xeroContractor/getAllTimeSheet";

export const manageXeroTimesheetFlowVk =
  apiUrl + "xero/manageXeroTimesheetFlowVk";
export const manageXeroTimesheetFlowVkContractor =
  apiUrl + "xeroContractor/manageXeroTimesheetFlowVk";

export const manageXeroTimesheetFlowVkServiceContractor =
  apiUrl + "xeroServiceContractor/manageXeroTimesheetFlowVk";

export const xeroAccessToken = apiUrl + "xero/xeroAccessToken";
export const callbackXero = apiUrl + "xero/callback";

//SA Dashboard SoleTrader
export const getSubscriptionListDashboard =
  apiUrl + "dashboard/getSubscriptionList";
export const getPayrollDashboard = apiUrl + "dashboard/payroll";
export const totalEarnings = apiUrl + "dashboard/totalEarnings";
export const getInvoicesDashboard = apiUrl + "dashboard/invoices";
export const getuserCountDashboard = apiUrl + "dashboard/userCount";
export const getdemoCountDashboard = apiUrl + "dashboard/demoCount";
export const getincidentCountDashboard = apiUrl + "dashboard/incidentCount";
export const getpublicHolidaysDashboard = apiUrl + "dashboard/publicHolidays";
export const getinvoicesMonthWiseDashboard =
  apiUrl + "dashboard/invoicesMonthWise";

export const invoicesMonthWiseSp =
  apiUrl + "dashboard/forSp/invoicesMonthWiseSp";
export const getdashboardSettings = apiUrl + "dashboard/getdashboardSettings";
export const setdashboardSettingsapi =
  apiUrl + "dashboard/setdashboardSettings";

export const getfoodFloodlist = apiUrl + "observation/getfoodFloodlist";
export const getSubscriptionListSoleTraderDashboard =
  apiUrl + "dashboard/getSubscriptionList/SoleTrader";
export const shiftNoteListSa =
  apiUrl + "appointment/super-admin/shift-note-list";

export const getobservationDetails =
  apiUrl + "observation/getobservationDetails";
export const updatefoodFluidObservation =
  apiUrl + "observation/updatefoodFluidObservation";
export const getServiceNotesList = apiUrl + "serviceTask/serviceTask/notesList";

export const addsubLeftMenu = apiUrl + "subLeftMenu/addSub-Left-Menu";
export const updatesubLeftMenu = apiUrl + "subLeftMenu/update";
export const deletesubLeftMenu = apiUrl + "subLeftMenu/delete";
export const detailssubLeftMenu = apiUrl + "subLeftMenu/getIndividual";
export const AlllistsubLeftMenu = apiUrl + "subLeftMenu/allSubLeftMenuList";
export const subLeftMenuList = apiUrl + "subLeftMenu/getSubLeftMenuList";

export const addleftMenu = apiUrl + "leftMenu/create";
export const updateleftMenu = apiUrl + "leftMenu/update";
export const deleteleftMenu = apiUrl + "leftMenu/delete";
export const detailsleftMenu = apiUrl + "leftMenu";
export const listleftMenu = apiUrl + "leftMenu/list/left-menu";
export const AlllistleftMenu = apiUrl + "leftMenu/left-menu-get-list/all";
export const leftMenuList = apiUrl + "leftMenu/list-menu/list";
export const getAllSupportItemForSupportCoordination =
  apiUrl + "supportItem/list-all-for-supportcoordination";

// added for service task include supportcoordination scenario
export const STsupportTypeId = "652f9b180c11ebd1a6ad4e9f";
export const STsupportCategoryId = "652fa2b30c11ebd1a6bb5139";

export const getNdisCost = apiUrl + "ndis-catlog/getDataForST";
export const addWebsiteManagement = apiUrl + "websiteManagement/create";
export const listWebsiteManagement = apiUrl + "websiteManagement/getlists";
export const listAddressWebsiteManagement =
  apiUrl + "websiteManagement/getAddresslist";
export const editWebsiteManagement = apiUrl + "websiteManagement/edit";
export const deleteWebsiteManagement = apiUrl + "websiteManagement/delete";
export const getWebsiteManagement = apiUrl + "websiteManagement/details";
export const getWebsiteManagementListDetails =
  apiUrl + "websiteManagement/getlists/details";

export const getClientTeamDetails = apiUrl + "serviceTask/getClientTeamDetails";

export const getAllServiceTaskTimesheetList =
  apiUrl + "serviceTask/getTimesheetList";

export const approvedMultipleSTTimesheet =
  apiUrl + "serviceTask/approved-timesheet";
export const rejectSTTimesheet = apiUrl + "serviceTask/reject-timesheet";
export const sendServiceTaskTimeSheetApprovalRequest =
  apiUrl + "serviceTask/send-timesheet-approval-req";
export const getClientForNormalSC = apiUrl + "serviceTask/getClientForNormalSC";
export const getClientServiceTaskList =
  apiUrl + "serviceTask/client-invoice-list";
export const getSupportCoordinatorList =
  apiUrl + "serviceTask/getSupportCoordinatorList";

export const getClientServiceTaskListExport =
  apiUrl + "serviceTask/client-invoice-list/export";

export const invoicesCount = apiUrl + "serviceTask/dash/invoicesCount";
export const ClientDashboardHoursInvoice =
  apiUrl + "clientDetails/check/ClientDashboardHoursInvoice";
export const HourEarningChart = apiUrl + "serviceTask/HourEarningChart";

export const getAllTimesheetWithoutPagination =
  apiUrl + "serviceTask/getAllTimesheetWithoutPagination";

export const getIscCreatedBy = apiUrl + "serviceTask/getIscCreatedBy";
// Service Coordinator Payroll

export const DetailsExperience = apiUrl + "experience";
export const AddExperience = apiUrl + "experience/create";
export const editExperience = apiUrl + "experience/update";
export const deleteExperience = apiUrl + "experience/delete";
export const getJobDetails = apiUrl + "supportCoordinator/getJobDetails";
export const getAllHCTimeSheetList =
  apiUrl + "supportCoordinator/getAllHCTimeSheetList";

export const OpengetAllClientFundChart =
  apiUrl + "clientDetails/OpengetAllClientFundChart/few";
export const listSupportCoordinatorOffboarding =
  apiUrl + "supportCoordinator/get-all-terminated-supportCoordinator/list";
export const exportSupportCoordinatorOffboarding =
  apiUrl + "supportCoordinator/offboarding/export";
export const getAlltimesheetpdf = apiUrl + "setTimesheet/getAlltimesheetpdf";

export const UpdateJobDetails =
  apiUrl + "supportCoordinator/updateSCJobDetails";
export const updateSCJobDetailsPayRate =
  apiUrl + "supportCoordinator/updateSCJobDetailsPayRate";
export const getPayRateDetails =
  apiUrl + "supportCoordinator/getPayRateDetails";
export const updatePayRate = apiUrl + "supportCoordinator/updatePayRate";

export const getPayrateHistory = apiUrl + "payRateHistory/getlists";
export const listShiftNoteasperclient =
  apiUrl + "appointment/listShiftNoteasperclient";

export const exportShiftNoteasperclient =
  apiUrl + "appointment/export/ShiftNoteasperclient";

export const SCListForSTPaySlip = apiUrl + "serviceTask/getSCListForSTPayslips";
export const HCListForAppointmentPaySlip =
  apiUrl + "setTimeSheet/getHCListForAppointmentPaySlip";

export const getServiceTaskPaySlipsList =
  apiUrl + "serviceTask/service-task-payslips";

export const checkSCBasteRate = apiUrl + "serviceTask/checkSCBasteRate";

export const getServiceTaskPaySlipsListExport =
  apiUrl + "serviceTask/service-task-payslips-export";
export const ServiceNotesListAll = apiUrl + "serviceTask/notesList/serviceTask";

export const seizureupdate = apiUrl + "observation/seizureupdate";
export const emailSendToReference = apiUrl + "user/emailSendToReference";
export const approveReference = apiUrl + "user/approveReference";

export const updateavtar = apiUrl + "user/updateavtar";

export const addAvtarIcon = apiUrl + "avtarIcon/create";
export const getavtarIconList = apiUrl + "avtarIcon/getavtarIconList";
export const getavtarIconListAll = apiUrl + "avtarIcon/getavtarIconListAll";
export const getAvtarIconIconDetails = apiUrl + "avtarIcon/details";
export const editAvtarIcon = apiUrl + "avtarIcon/edit";
export const deleteavtarIcon = apiUrl + "avtarIcon/delete";
export const getSecondaryUserPaginationForHr =
  apiUrl + "user/getSecondaryUserPaginationForHr";

export const UpdateJobDetailsHC = apiUrl + "healthCarer/updateHCJobDetails";
export const getJobDetailsHC = apiUrl + "healthCarer/getHCJobDetails";
export const getJobDurrations = apiUrl + "healthCarer/list/getJobDurrations";

export const getPayRateDetailsHC = apiUrl + "healthCarer/getHCPayRateDetails";
export const getClientInvoiceBulkUploadExport =
  apiUrl + "serviceTask/client-invoice-bulk-upload/export";
export const deletefoodFlood = apiUrl + "observation/deletefoodFlood";
export const exportcompliance = apiUrl + "compliance/exportcompliance";
export const getDocumentAndComplianceById =
  apiUrl + "compliance/getDocumentAndComplianceById";

export const exportProgressnoteList =
  apiUrl + "progress-note/exportProgressnoteList";

export const getServiceTaskPaySlipsListByUserId =
  apiUrl + "serviceTask/service-task-payslips-byUserId";

export const getServiceTaskPaySlipsListExportByUserId =
  apiUrl + "serviceTask/service-task-payslips-export-byUserId";
export const SetAttendance = apiUrl + "attendance/setAttendance";
export const UpdateTimeTracking = apiUrl + "attendance/UpdateTimeTracking";
export const UpdateTimeTrackingBAdmin =
  apiUrl + "attendance/UpdateTimeTrackingBAdmin";

export const AttendanceList = apiUrl + "attendance";

export const indvidualtaskDetails = apiUrl + "appointment/indvidualtaskDetails";

export const getEmployeeAttendanceReport =
  apiUrl + "user/getReportList/employeeAttendance";
export const addAttendance = apiUrl + "user/addAttendance";
export const exportEmployeeAttendanceReport =
  apiUrl + "user/exportReportList/employeeAttendance";
export const getclintIdBytasks = apiUrl + "appointment/getclintIdBytasks";

export const approvedAllTimesheetRecords =
  apiUrl + "setTimesheet/approved-multiple-timesheet";

export const generateInvoiceForIHC =
  apiUrl + "setTimesheet/generate-invoice-for-ihc";
export const getAdminAttendanceReport =
  apiUrl + "user/getReportList/adminAttendance";
export const EmployeeListForAttendance =
  apiUrl + "user/getReportList/EmployeeListForAttendance";

export const exportAdminAttendanceReport =
  apiUrl + "user/exportReportList/adminAttendance";
export const tasksImageUpload = apiUrl + "image/upload-image";

export const addLibraryModule = apiUrl + "libraryModule/addLibraryModule";
export const getLibraryModuleList =
  apiUrl + "libraryModule/getLibraryModuleList";
export const libraryModuleDetails = apiUrl + "libraryModule/getIndividual";
export const updateLibraryModule = apiUrl + "libraryModule/updateLibraryModule";
export const deleteLibraryModule = apiUrl + "libraryModule/deleteLibraryModule";
export const allLibraryModuleList =
  apiUrl + "libraryModule/allLibraryModuleList";
export const listAllHCExploreMarketInside =
  apiUrl + "exploreMarket/listAllHCExploreMarketInside";
export const exportLibraryModuleList =
  apiUrl + "libraryModule/exportLibraryModuleList";
export const addLibraryLink = apiUrl + "libraryLink/addLibraryLink";
export const getLibraryLinkList = apiUrl + "libraryLink/getLibraryLinkList";
export const libraryLinkDetails = apiUrl + "libraryLink/getIndividual";
export const updateLibraryLink = apiUrl + "libraryLink/updateLibraryLink";
export const deleteLibraryLink = apiUrl + "libraryLink/deleteLibraryLink";
export const exportLibraryLinkList =
  apiUrl + "libraryLink/exportLibraryLinkList";

export const addexpenceType = apiUrl + "expenceType/create";
export const listexpenceType = apiUrl + "expenceType/getList";
export const deleteexpenceType = apiUrl + "expenceType/delete";
export const editexpenceType = apiUrl + "expenceType/update";
export const getexpenceType = apiUrl + "expenceType";
export const generateHtmlPdfForInvoice =
  apiUrl + "setTimesheet/upload-html-pdf ";

export const getInvoiceDataByAppointmentId =
  apiUrl + "setTimesheet/invoice/getInvoiceDataByAppointmentId";
export const approveMultipleAppointments =
  apiUrl + "appointment/approveMultipleAppointments";
export const getInvoiceDataByServiceTaskId =
  apiUrl + "serviceTask/getInvoiceData";
export const generateHtmlPdfForServiceTaskInvoice =
  apiUrl + "serviceTask/generatePdfForServiceTask";
export const getpalnManagerDetails =
  apiUrl + "clientDetails/get-plan-manager-details-by-userid";

export const getProgressNoteclientId =
  apiUrl + "progress-note/getProgressNoteclientId";
export const getTeamDetails = apiUrl + "user/getTeamDetails";
export const sendEmailInvoice = apiUrl + "invoice/send-invoice-email";
export const getListTeamDetails = apiUrl + "user/getListTeamDetails";

export const getProfitLossReportDashboard =
  apiUrl + "supportCoordinator/getProfitLossReport/Dashboard";
export const getProfitLossData =
  apiUrl + "supportCoordinator/getProfitLossData/datewise";
export const getIscPackageStartEndDate =
  apiUrl + "supportCoordinator/getIscPackageStartEndDate/return";
export const sendEmailServiceTaskInvoice =
  apiUrl + "serviceTask/send-invoice-email";

export const subscriptionMonthWise =
  apiUrl + "supportCoordinator/subscriptionMonthWise/Chart";

export const invoicesMonthWise =
  apiUrl + "supportCoordinator/invoicesMonthWise/Chart";

export const payrollMonthWise =
  apiUrl + "supportCoordinator/payrollMonthWise/Chart";

export const profitAndLossMonthWise =
  apiUrl + "supportCoordinator/profitAndLossMonthWise/Chart";

export const hcShiftNotes = apiUrl + "healthCarer/hcShiftNotes";
export const sendEmailServiceTaskPayslip =
  apiUrl + "serviceTask/send-payslip-email";
export const agreementRenew = apiUrl + "user/client-agreementRenew";
export const agreementCancel = apiUrl + "user/client-agreement-cancel";
export const getCombinedCalendarEvents =
  apiUrl + "meeting/getCombinedCalendarEvents";

export const detailsProgressnoteByAppointmentId =
  apiUrl + "progress-note/detailsByAppointmentId";

export const getServicetaskHours =
  apiUrl + "serviceTask/getServicetaskHours/billNonbill";

export const getInvoiceBulkUploadExport =
  apiUrl + "report/invoice-bulk-upload/export";

export const getAllHelpManagement = apiUrl + "helpManagement/get-list/help";
export const helpManagementContent =
  apiUrl + "helpManagement/details/helpManagementContent";
export const getAllHelpManagementPopup =
  apiUrl + "helpManagement/popup/help-management";

export const getAllUserTypeForLeave =
  apiUrl + "leaveEmployee/getUserAsUserType";

export const getAllUserTypeForLeaveService =
  apiUrl + "leaveEmployee/getUserAsUserTypeService";

export const updateMeetingDetailsOther = apiUrl + "otherStaffMeeting/update";
export const addMeetingOther = apiUrl + "otherStaffMeeting/create";
export const getMeetingDetailsIndividualOther =
  apiUrl + "otherStaffMeeting/getMeetingDetailsIndividual";
export const deleteMeetingOther = apiUrl + "otherStaffMeeting/delete";
export const getMeetingListAllClientOther =
  apiUrl + "otherStaffMeeting/getMeetingListAllClient/list";
export const updateMeetingStatusOther =
  apiUrl + "otherStaffMeeting/updateStatus";
export const cancelScheduledMeetingOther =
  apiUrl + "otherStaffMeeting/cancelScheduledMeeting";
export const getMeetingEmailIdsOther =
  apiUrl + "otherStaffMeeting/getMeetingEmailIds";
export const getMeetingListAllClientCalendarOther =
  apiUrl + "otherStaffMeeting/getMeetingListAllClient/Calendar/list";
export const listexpenceTypeWithoutPagination =
  apiUrl + "expenceType/getListWithourPagination";
export const notesStatusUpdate = apiUrl + "appointment/updateNoteStatus";
export const getAllScDoucmentsBackUp = apiUrl + "dataBackUp/getAllScDocuments";
export const EmployeeDetailsReportexport =
  apiUrl + "user/EmployeeDetailsReportexport";

export const addHelpCenterApp = apiUrl + "helpCenterMobileApp/create";
export const listHelpCenterApp = apiUrl + "helpCenterMobileApp/get-list/all";
export const deleteHelpCenterApp = apiUrl + "helpCenterMobileApp/delete";
export const updateHelpCenterApp = apiUrl + "helpCenterMobileApp/update";
export const getHelpCenterApp = apiUrl + "helpCenterMobileApp";

export const getCheckAvailability =
  apiUrl + "setAvailability/check-availability-by-userid";

export const getlistTeam = apiUrl + "clientDetails/connecting/getlistTeam";
export const getlistTeamHc = apiUrl + "healthCarer/connecting/getlistTeamHc";

export const getIncidentCount = apiUrl + "incident/count/getIncidentCount";

export const getMedicationPdfByClientId = apiUrl + "medication/pdfByClientId";

export const onCallAppointmentTypeId = "663b1b4589f7948fc21a13a7";
export const payRunCreateHC = apiUrl + "pay-run/HC/create";

export const updateContinueShiftNoteCompleted =
  apiUrl + "appointment/updateContinueShiftNoteCompleted";

export const getHealthCarerRatingsDashboard =
  apiUrl + "healthCarer/dash/getHealthCarerRatingsDashboard";

export const getIncidentAppt = apiUrl + "incident/list/getIncidentAppt";

export const deletePayrunData = apiUrl + "pay-run/deletePayrunData";
export const deletePayrunContractorData =
  apiUrl + "pay-run-contractor/deletePayrunData";
export const deletePayrunServiceData =
  apiUrl + "pay-run-contractor/deletePayrunDataService";
export const addPayRunParent = apiUrl + "pay-run/parent/create";
export const addPayRunServiceTaskParent =
  apiUrl + "pay-run-contractor/parent/service-task";

export const addPayRunParentContractor =
  apiUrl + "pay-run-contractor/parent/create";

export const PayrunPeriodDD = apiUrl + "pay-run/PayrunPeriodDD";

export const AddAttendancePatrunPeriodDD =
  apiUrl + "pay-run/AddAttendancePayrunPeriodDD";
export const getAttendancePayrunPeriodDD =
  apiUrl + "pay-run/getAttendancePayrunPeriodDD";

export const PayrunPeriodContDD = apiUrl + "pay-run-contractor/PayrunPeriodDD";
export const PayrunPeriodContDDService =
  apiUrl + "pay-run-contractor/PayrunPeriodDDService";
export const getPayrunDataByDate = apiUrl + "pay-run/getPayrunDataByDate";
export const getGeneratedPayrunDataByDate =
  apiUrl + "pay-run/generated/getGeneratedPayrunDataByDate";
export const getGeneratedPayrunDataByDateContractor =
  apiUrl + "pay-run-contractor/generated/getGeneratedPayrunDataByDate";

export const getGeneratedPayrunDataByDateContractorService =
  apiUrl + "pay-run-contractor/generated/getGeneratedPayrunDataByDateService";
export const getGeneratedPayrunData =
  apiUrl + "pay-run/generated/getGeneratedPayrunData";
export const getGeneratedAllPayrun = apiUrl + "pay-run/generated/AllPayRun";

export const generatePaySlipDetails =
  apiUrl + "generatePaySlip/generatePaySlipDetails";

export const generatePaySlipContractorDetails =
  apiUrl + "generatePaySlipContractor/generatePaySlipDetails";

export const generatePaySlipServiceDetails =
  apiUrl + "generatePaySlipServiceContractor/generatePaySlipDetails";

export const generatePaySlipDetailsContractor =
  apiUrl + "generatePaySlipContractor/generatePaySlipDetails";

export const generatePaySlipDetailsServiceContractor =
  apiUrl + "generatePaySlipServiceContractor/generatePaySlipDetails";

export const getLeaveObject = apiUrl + "generatePaySlip/leave/getLeaveObject";
export const generatePayslips = apiUrl + "generatePaySlip/generatePayslips/all";
export const generatePayslipsContractor =
  apiUrl + "generatePaySlipContractor/generatePayslips/all";

export const generatePayslipsServiceContractor =
  apiUrl + "generatePaySlipServiceContractor/generatePayslips/all";

export const updateEditableboxesRecord =
  apiUrl + "generatePaySlip/updateEditableboxesRecord/update";

export const getActiveSuperFund =
  apiUrl + "super-annuation/getActiveSuperFund/details";
export const shareGeneratedPayslip =
  apiUrl + "generatePaySlip/shareGeneratedPayslip";

export const shareGeneratedServcePayslip =
  apiUrl + "generatePaySlipServiceContractor/shareGeneratedPayslip";

export const getClientlistForServiceTask = apiUrl + "serviceTask/clientList";
export const getAllServiceTaskTimesheetListUpdated =
  apiUrl + "serviceTask/getTimesheetListUpdated";

export const ClientSupportType =
  apiUrl + "clientDetails/dynamic/ClientSupportType";

export const getParentLogoByTypeAndId =
  apiUrl + "dashboard/getParentLogoByTypeAndId";

export const createUserSubscription = apiUrl + "subscription/createCustomer";
export const getSubscriptionDetails =
  apiUrl + "subscription/getSubscriptionDetails";

export const getAppointmentPaySlipsList =
  apiUrl + "setTimesheet/get-all-appointment-payslips";
//export const AustraliaId = process.env.AUSTRALIA_ID;

export const sendEmailAppointmentPayslip =
  apiUrl + "setTimesheet/send-payslip-email";

export const getAppointmentPaySlipsListExport =
  apiUrl + "setTimesheet/appointment-payslips-export";

export const getHealthCarerLeaveDetails =
  apiUrl + "setTimesheet/getHealthCarerLeaveDetails";

export const getListTeamForConflitct =
  apiUrl + "conflict/connecting/getlistTeam";

export const silSdaAppointmentCreate =
  apiUrl + "silsdaLocation/silSdaAppointmentCreate";
export const getSilSdaSummary = apiUrl + "silsdaLocation/getSilSdaSummary";
export const GetHcIdsByDate = apiUrl + "silsdaLocation/GetHcIdsByDate";
export const silSdaAppointmentUpdate =
  apiUrl + "silsdaLocation/silSdaAppointmentUpdate";

export const pushXeroClientAppointmentInvoice =
  apiUrl + "xero/appointmentInvoice/pushToXero";

export const addDueDateInvoice = apiUrl + "invoiceSetting/addDueDate";

export const getDueDateByUserId = apiUrl + "invoiceSetting/getDueDateByUserId";

export const getCommonCalanderList =
  apiUrl + "meeting/getCommonCalanderList/list";

export const getCommonCalander = apiUrl + "meeting/getCommonCalander/Calendar";

export const listPlanManagerPagination =
  apiUrl + "clientDetails/listPlanManagerPagination/get";

export const getClientAsPerHealthCarerIdVk =
  apiUrl + "goal/list/getVkClientAsPerHC";

export const checkStringByUserId = apiUrl + "user/checkStringByUserId";
export const getUserForSeverity = apiUrl + "user/getUserForSeverity";
export const getJobDetailsForSV = apiUrl + "user/getJobDetails";

export const getClientDefaultAddressByAppointmentId =
  apiUrl + "appointment/getClientDefaultAddressByAppointmentId";

export const getClientSupportBudgetIndSc =
  apiUrl + "clientDetails/IndSc/getClientSupportBudget";

export const getClientSupportBudgetSp =
  apiUrl + "clientDetails/sp/getClientSupportBudget";

export const pushXeroClientServiceTaskInvoice =
  apiUrl + "xero/serviceTaskInvoice/pushToXero";
