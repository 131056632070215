import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Banner from "../../assets/images/Banner.jpg";
import Banner2 from "../../assets/images/Banner2.jpg";
import Banner3 from "../../assets/images/Banner3.jpg";
import logo from "../../assets/images/hubshift-logo.png";
// import ArrowLeft from "../../assets/images/arr";
// import './Signup.css';

import { Box, Typography } from "@mui/material";

const options = {
  items: 1,
  loop: true,
  margin: 10,
  autoplay: false,
  nav: true,
  //   dots: true,

  responsive: {
    0: {
      items: 1,
      nav: false,
    },
    600: {
      items: 1,
      nav: true,
    },
    1000: {
      items: 2.5,
      nav: true,
    },
  },
};

const HomeClientSlider = () => {
  return (
    <>
      <Box className="webContainer">
        <Box className="client-slider-headingWrapper">
          <Box className="space-pb-60">
            <h1 >What Our Clients Say About Us</h1>
          </Box>
          <Box className="client-slide-arrows">
           
          </Box>
        </Box>
        <OwlCarousel
          className="owl-theme"
          {...options}
         
        >
          <Box className="item">
            <Box className="client-homeSlide-content">
              <Box className="text-box">
                <Typography>
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Cursus nibh mauris, nec turpis orci lectus maecenas.
                  Suspendisse sed magna eget nibh in turpis. Consequat duis diam
                  lacus arcu. Faucibus venenatis felis id augue sit cursus
                  pellentesque enim arcu. Elementum felis magna pretium in
                  tincidunt. Suspendisse sed magna eget nibh in turpis.
                  Consequat duis diam lacus arcu.”
                </Typography>
                
              </Box>
              <Box className="client-slide-names space-pt-40">
                     <Typography className="d-flex align-items-center">Leo, <span className="ml-2"> <i> NDW </i></span></Typography>     
                </Box>   
            </Box>
          </Box>

          <Box className="item">
            <Box className="client-homeSlide-content">
              <Box className="text-box">
                <Typography>
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Cursus nibh mauris, nec turpis orci lectus maecenas.
                  Suspendisse sed magna eget nibh in turpis. Consequat duis diam
                  lacus arcu. Faucibus venenatis felis id augue sit cursus
                  pellentesque enim arcu.”
                </Typography>
                 
              </Box>
              <Box className="client-slide-names space-pt-40">
                     <Typography className="d-flex align-items-center">Leo, <span className="ml-2" > <i> NDW </i></span></Typography>     
                </Box>
            </Box>
          </Box>

          <Box className="item">
            <Box className="client-homeSlide-content">
              <Box className="text-box">
                <Typography>
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Cursus nibh mauris, nec turpis orci lectus maecenas.
                  Suspendisse sed magna eget nibh in turpis. Consequat duis diam
                  lacus arcu. Faucibus venenatis felis id augue sit cursus
                  pellentesque enim arcu.”
                </Typography>
               
              </Box>
              <Box className="client-slide-names space-pt-40">
                     <Typography className="d-flex align-items-center">Leo, <span className="ml-2"><i>NDW</i></span></Typography>     
                </Box>  
            </Box>
          </Box>
        </OwlCarousel>
      </Box>
    </>
  );
};

export default HomeClientSlider;
